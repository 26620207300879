import { CRON_DAYS } from './../utils'; 
import CheckboxNew from './../../base/checkboxNew';
import CheckboxListDropdown from './../../base/checkboxListDropdown';

const DAYS_SCHEDULE = [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ]
const POINT_BINDING_LENGHT = 12;
const POINTS_MAX_NUMBER = 10;

const EQUIPMENT_CLASSES = {
    '0': 'разделочная доска',
    '1': 'разделочный нож',
}

const COLORS = [
    { id: 0, name: 'синий' },
    { id: 1, name: 'коричневый' },
    { id: 2, name: 'зелёный' },
    { id: 3, name: 'иной цвет' },
    { id: 4, name: 'красный' },
    { id: 5, name: 'белый' },
    { id: 6, name: 'желтый' },
]

const EQUIPMENT_COLORS = {
    '0': 'синий',
    '1': 'коричневый',
    '2': 'зелёный',
    '3': 'иной цвет',
    '4': 'красный',
    '5': 'белый',
    '6': 'желтый',
}

export default {
    name: 'NeuralSetting',
    components: {
        CheckboxNew,
        CheckboxListDropdown,
    },
    props: {
        activeCam: {
            type: Object
        },
        object: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: 'queue_detection'
        },
        task: {
            type: Object,
            default: () => ({}),
        }
    },
    data: () => ({
        steps: [
            { id: '1', caption: '3 сек.', value: '/3', seconds: true, step: 3 },
            { id: '2', caption: '5 сек.', value: '/5', seconds: true, step: 5 },
            { id: '13', caption: '10 сек.', value: '/10', seconds: true, step: 10 },
            { id: '3', caption: '15 сек.', value: '/15', seconds: true, step: 15 },
            { id: '4', caption: '30 сек.', value: '/30', seconds: true, step: 30 },
            { id: '5', caption: '1 мин.', value: '/1', minutes: true, step: 60 },
            { id: '6', caption: '2 мин.', value: '/2', minutes: true, step: 120 },
            { id: '7', caption: '5 мин.', value: '/5', minutes: true, step: 300 },
            { id: '8', caption: '10 мин.', value: '/10', minutes: true, step: 600 },
            { id: '9', caption: '15 мин.', value: '/15', minutes: true, step: 900 },
            { id: '10', caption: '30 мин.', value: '/30', minutes: true, step: 1800 },
            { id: '11', caption: '1 час', value: '/1', hours: true, step: 3600 },
            { id: '12', caption: '2 часа', value: '/2', hours: true, step: 7200 }
        ],
        steps2: [
            { id: '3', caption: '15 сек.', value: '/15', seconds: true, step: 15 },
            { id: '4', caption: '30 сек.', value: '/30', seconds: true, step: 30 },
            { id: '5', caption: '1 мин.', value: '/1', minutes: true, step: 60 },
            { id: '6', caption: '2 мин.', value: '/2', minutes: true, step: 120 },
            { id: '7', caption: '5 мин.', value: '/5', minutes: true, step: 300 },
            { id: '8', caption: '10 мин.', value: '/10', minutes: true, step: 600 },
            { id: '9', caption: '15 мин.', value: '/15', minutes: true, step: 900 },
            { id: '10', caption: '30 мин.', value: '/30', minutes: true, step: 1800 },
            { id: '11', caption: '1 час', value: '/1', hours: true, step: 3600 },
            { id: '12', caption: '2 часа', value: '/2', hours: true, step: 7200 }
        ],
        countSteps: [
            { id: '1', caption: '15 секунд', value: 15 },
            { id: '2', caption: '30 секунд', value: 30 },
            { id: '3', caption: '1 минуту', value: 60 },
            { id: '4', caption: '2 минуты', value: 120 }
        ],
        intervalId: null,
        positionId: 0,
        area: {
            x0: 0,
            x1: 0,
            y0: 0,
            y1: 0
        },
        equipmentColors: [],
        showMessage: true,
        showBorder: false,
        showResult: false,
        checkResult: {},
        editMode: false,
        mousePressed: false,
        loading: false,
        border: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            startX: 0,
            startY: 0,
            width: 0,
            height: 0,
            realyStartX: 0,
            realyStartY: 0
        },
        devTypes: [],
        devs: [],
        users: [],
        naturalWidth: 640,
        naturalHeight: 360,
        users2: [],
        selected: {
            dev: null,
            dev2: null,
            devType: "0",
            user: null,
            user2: null,
            count: 1,
            step: null,
            position: "0",
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,
            extraCount: false,
            countStep: null,
            renewal: false,
            double_check: false,
            color0: 0,
            color1: 0,
        },
        days: {
            'mon': false,
            'tue': false,
            'wed': false,
            'thu': false,
            'fri': false,
            'sat': false,
            'sun': false
        },
        extraTimes: [],
        times: [
            { value: '00:00', caption: '0:00' },
            { value: '01:00', caption: '1:00' },
            { value: '02:00', caption: '2:00' },
            { value: '03:00', caption: '3:00' },
            { value: '04:00', caption: '4:00' },
            { value: '05:00', caption: '5:00' },
            { value: '06:00', caption: '6:00' },
            { value: '07:00', caption: '7:00' },
            { value: '08:00', caption: '8:00' },
            { value: '09:00', caption: '9:00' },
            { value: '10:00' },
            { value: '11:00' },
            { value: '12:00' },
            { value: '13:00' },
            { value: '14:00' },
            { value: '15:00' },
            { value: '16:00' },
            { value: '17:00' },
            { value: '18:00' },
            { value: '19:00' },
            { value: '20:00' },
            { value: '21:00' },
            { value: '22:00' },
            { value: '23:00' },
            { value: '24:00' },
        ],
        
        outTimes: [
            { value: '00:00', caption: '0:00 - 1:00' },
            { value: '00:30', caption: '0:30 - 1:30' },
            { value: '01:00', caption: '1:00 - 2:00' },
            { value: '01:30', caption: '1:30 - 2:30' },
            { value: '02:00', caption: '2:00 - 3:00' },
            { value: '02:30', caption: '2:30 - 3:30' },
            { value: '03:00', caption: '3:00 - 4:00' },
            { value: '03:30', caption: '3:30 - 4:30' },
            { value: '04:00', caption: '4:00 - 5:00' },
            { value: '04:30', caption: '4:30 - 5:30' },
            { value: '05:00', caption: '5:00 - 6:00' },
            { value: '05:30', caption: '5:30 - 6:30' },
            { value: '06:00', caption: '6:00 - 7:00' },
            { value: '06:30', caption: '6:30 - 7:30' },
            { value: '07:00', caption: '7:00 - 8:00' },
            { value: '07:30', caption: '7:30 - 8:30' },
            { value: '08:00', caption: '8:00 - 9:00' },
            { value: '08:30', caption: '8:30 - 9:30' },
            { value: '09:00', caption: '9:00 - 10:00' },
            { value: '09:30', caption: '9:30 - 10:30' },
            { value: '10:00', caption: '10:00 - 11:00' },
            { value: '10:30', caption: '10:30 - 11:30' },
            { value: '11:00', caption: '11:00 - 12:00' },
            { value: '11:30', caption: '11:30 - 12:30' },
            { value: '12:00', caption: '12:00 - 13:00' },
            { value: '12:30', caption: '12:30 - 13:30' },
            { value: '13:00', caption: '13:00 - 14:00' },
            { value: '13:30', caption: '13:30 - 14:30' },
            { value: '14:00', caption: '14:00 - 15:00' },
            { value: '14:30', caption: '14:30 - 15:30' },
            { value: '15:00', caption: '15:00 - 16:00' },
            { value: '15:30', caption: '15:30 - 16:30' },
            { value: '16:00', caption: '16:00 - 17:00' },
            { value: '16:30', caption: '16:30 - 17:30' },
            { value: '17:00', caption: '17:00 - 18:00' },
            { value: '17:30', caption: '17:30 - 18:30' },
            { value: '18:00', caption: '18:00 - 19:00' },
            { value: '18:30', caption: '18:30 - 19:30' },
            { value: '19:00', caption: '19:00 - 20:00' },
            { value: '19:30', caption: '19:30 - 20:30' },
            { value: '20:00', caption: '20:00 - 21:00' },
            { value: '20:30', caption: '20:30 - 21:30' },
            { value: '21:00', caption: '21:00 - 22:00' },
            { value: '21:30', caption: '21:30 - 22:30' },
            { value: '22:00', caption: '22:00 - 23:00' },
            { value: '22:30', caption: '22:30 - 23:30' },
            { value: '23:00', caption: '23:00 - 00:00' },
        ],
        loadingUsers: false,
        loadingUsers2: false,
        loadingPositions: false,
        positions: [],
        needGetSize: true,
        canvas: null,
        ctx: null,
        points: [],
        points2: [],
        editMode2: false,
        showMessage2: false,
        thisLast: false,
        thisLast2: false,
        src: '/',
        fullscreen: false,
        lastWidth: 1,
        needSetSize: true,

        selectedColors0: [],
        selectedColors1: [],

        knifeSelected: false,
        descSelected: false,
        isCooking: false,
        isVideo: false,
        smallWorkshop: false,
        uniqueHumanCount: false,
    }),
    watch: {
        fullscreen() {
            this.needSetSize = true;
        },
    },
    mounted() {
        if (this.activeCam && !this.task.id) {
            window.myAjax('POST', '/preview/start', {
                camera_id: this.activeCam.id
            }, () => {})
        }
        this.equipmentColors = COLORS;
        this.imageLoadHandler = this.imageLoadHandler.bind(this);
        this.imageErrorHandler = this.imageErrorHandler.bind(this);
        this.startCheckingFile = this.startCheckingFile.bind(this);
        const hasChild = {};
        this.devTypes = [];
        this.devs = [];
        if (this.activeCam) {
            for (let i = 0; i < window.myDevs.length; i++) {
                const dev = window.myDevs[i];
                if (
                    dev.parent_id != null &&
                    dev.priority_id != null &&
                    this.devsToObject(dev.id, this.activeCam.object_id)
                ) {
                    this.devs.push(dev);
                    hasChild[dev.parent_id] = dev;
                }
            }
        }
        const par = [];
        for (let i = 0; i < window.myDevs.length; i++) {
            const dev = window.myDevs[i];
            if (hasChild[dev.id]) {
                par.push(this.getRootDev(hasChild[dev.id].id));
            }
        }
        
        this.devTypes = [...new Set(par)];
        
        if (this.activeCam) {
            this.src = this.activeCam.imgSrc + "&time=" + new Date();
        }

        if (!this.activeCam) {
            if (this.type === 'smoker_detection' || this.type === 'face_recognition') {
                this.devs = [];
                this.devType = [];
                this.users = window.myUsers.filter( user => !!user.profile);
                this.selected.user = (this.users[0] || {}).id;
                
                this.users2 = window.myUsers.filter( user => !!user.profile);
                this.selected.user2 = (this.users2[0] || {}).id;

                for (let i = 0; i < window.myDevs.length; i++) {
                    const dev = window.myDevs[i];
                    if (
                        dev.parent_id != null &&
                        dev.priority_id != null
                    ) {
                        this.devs.push(dev);
                        hasChild[dev.parent_id] = dev;
                    }
                }

                for (let i = 0; i < window.myDevs.length; i++) {
                    const dev = window.myDevs[i];
                    if (hasChild[dev.id]) {
                        par.push(this.getRootDev(hasChild[dev.id].id));
                    }
                }

                this.devTypes = [...new Set(par)];
            }
        }
        if (this.task.id) {
            this.src = this.task.src;
        } else {
            this.intervalId = setInterval(this.updateImage, 1000);
        }
        this.selected.dev = (this.devs[0] || {}).id;
        this.selected.dev2 = (this.devs[0] || {}).id;
        this.selected.step = (this.type === 'first_in_detection' || this.type === 'last_out_detection') ? this.steps[0] : this.steps[5];
        this.selected.countStep = this.countSteps[0];

        if (this.type === 'equipment_detection') {
            this.knifeSelected = true;
            this.descSelected = true;
        }
        if (this.activeCam) {
            this.newobject = this.getObject(this.activeCam.object_id);
        } else {
            if ((this.object || {}).id) {
                this.newobject = this.object;
            }
        }
        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset() + 10);
        this.selected.startDate = now.toISOString().slice(0,10);
        now.setMinutes(now.getMinutes() + 60);
        this.selected.endDate = now.toISOString().slice(0,10);

        const key = Object.keys(CRON_DAYS).find(day => 
            CRON_DAYS[day] === now.getDay()
        );
        this.resetDays();
        this.setDay(key);

        if (this.type === 'first_in_detection') {
            this.selected.startTime = '08:00';
            this.selected.endTime = '09:00';
        } else if (this.type === 'last_out_detection') {
            this.selected.startTime = '18:00';
            this.selected.endTime = '19:00';
        } else {
            this.selected.startTime = '08:00';
            this.selected.endTime = '21:00';
        }

        let image = this.$refs['image'];
        if (image) {
            image.addEventListener('load', this.imageLoadHandler);
            image.addEventListener('error', this.imageErrorHandler);
        }
        this.canvas = this.$refs['canvas'];
        if (this.canvas) {
            this.ctx = this.canvas.getContext('2d');
        }
        let container = this.$refs['container'];
        if (container) {
            container.addEventListener('click', this.containerMouseClickHandler);
            container.addEventListener('mousemove', this.containerMouseMoveHandler);
        }

        setTimeout( () => {
            let image = this.$refs['image'];
            if (image) {
                image.addEventListener('load', this.imageLoadHandler);
                image.addEventListener('error', this.imageErrorHandler);
            }
            this.canvas = this.$refs['canvas'];
            if (this.canvas) {
                this.ctx = this.canvas.getContext('2d');
                this.ctx.lineWidth = 1;
                this.ctx.strokeStyle="#bb0000";
                this.ctx.fillStyle="#dd6666";
            }
            let container = this.$refs['container'];
            if (container) {
                container.addEventListener('click', this.containerMouseClickHandler);
                container.addEventListener('mousemove', this.containerMouseMoveHandler);
                let boundingRect = container.getBoundingClientRect();
                this.canvas.width = boundingRect.width;
                this.canvas.height = boundingRect.height;
                this.lastWidth = boundingRect.width;
                if (!this.intervalId && !this.task.id) {
                    this.intervalId = setInterval(this.updateImage, 1000);
                }
            }
            if (this.task.id) {
                let image = document.getElementById('dsv-neuralSetting-preview-image');
                let widthIndex = 1;
                if (image) {
                    widthIndex = image.naturalWidth / this.lastWidth;
                }
                this.points = [];
                (this.task.zone || []).forEach( point => {
                    this.points.push( { x: point.x / widthIndex, y: point.y / widthIndex })
                }) 
                this.points.push(this.points[0]);
                this.changeEditMode(false);
                if (this.type === 'absence_and_queue') {
                    this.points2 = [];
                    (this.task.queue_zone || []).forEach( point => {
                        this.points2.push( { x: point.x / widthIndex, y: point.y / widthIndex })
                    }) 
                    this.points2.push(this.points2[0]);
                    this.changeEditMode2(false);
                }
                this.showBorder = true;
                this.showResult = true;
                this.drawLines();
            }
            const rangeInputs = document.querySelectorAll('input[type="range"]');
            rangeInputs.forEach(input => {
                input.addEventListener('input', this.handleInputChange);
                input.addEventListener('change', this.handleInputChange);
            })
        }, 1000)

        if (!this.activeCam) {
            const input = document.getElementById("neural-setting-input");
            input.addEventListener("change", this.startCheckingFile, false);
        }

        if (this.task.id) {
            if (this.task.digression_id) {
                this.selected.dev = this.task.digression_id;
            } else if (this.task.digression_ids) {
                this.selected.dev = this.task.digression_ids[1];
                this.selected.dev2 = this.task.digression_ids[0];
            }
            if (this.task.responsible_id) {
                this.selected.user = this.task.responsible_id;
            } else if (this.task.responsible_ids) {
                this.selected.user = this.task.responsible_ids[1];
                this.selected.user2 = this.task.responsible_ids[0];
            }

            if (this.type === 'bac_treatment') {
                this.selected.step = this.steps.find( st => st.step === this.task.extra_check_step * this.task.extra_check_count);
            } else {
                this.selected.step = this.steps.find( st => st.caption === this.task.period);
            }
            this.selected.renewal = this.task.auto_renewal;

            this.selected.count = this.task.threshold;
            if (this.type === 'absence_and_queue') {
                this.selected.count = this.task.queue_threshold;
            } else if (this.type === 'equipment_detection') {
                this.knifeSelected = true;
                this.descSelected = true;

                this.selectedColors0 = [];
                this.selectedColors1 = [];
                (this.task.available_equips).forEach( equip => {
                    if (equip.equip_id === 0) {
                        this.selectedColors0.push(equip.color_id);
                    } else if (equip.equip_id === 1) {
                        this.selectedColors1.push(equip.color_id);
                    }
                })
            } else if (this.type === 'human_count') {
                this.uniqueHumanCount = this.task.unique;
            }

            this.selected.startDate = new Date(this.task.start).toISOString().slice(0,10);
            this.selected.endDate = new Date(this.task.stop).toISOString().slice(0,10);

            this.selected.startTime = this.task.scheduleHours[0] + ':' + this.task.scheduleHours[2];
            this.selected.endTime = this.task.scheduleHours[1] + ':' + this.task.scheduleHours[3];

            this.selected.double_check = this.task.double_check;
            
            this.isVideo = this.task.media_type === 'video';

            this.smallWorkshop = (this.task.frames_count || 3) < 3;

            this.days = {
                'mon': false,
                'tue': false,
                'wed': false,
                'thu': false,
                'fri': false,
                'sat': false,
                'sun': false
            };
            for (let index in this.task.weekDays) {
                this.days[DAYS_SCHEDULE[index]] = true;
            }

            
        }

        this.getWatchers();
        this.getWatchers2();
        return;

    },
    updated() {
        if (this.needSetSize) {
            this.imageLoadHandler();
            this.needSetSize = false;
        }
    },
    beforeDestroy() {
        const rangeInputs = document.querySelectorAll('input[type="range"]');
        rangeInputs.forEach(input => {
            input.removeEventListener('input', this.handleInputChange)
            input.removeEventListener('change', this.handleInputChange);
        })
        if (!this.activeCam) {
            const input = document.getElementById("neural-setting-input");
            if (input) {
                input.removeEventListener("change", this.startCheckingFile);
            }
        }
        this.close();
    },
    methods: {
        updateselectedColors0Handler(data) {
            this.selectedColors0 = data;
        },
        updateselectedColors1Handler(data) {
            this.selectedColors1 = data;
        },
        // toggleKnife(checked) {

        // },
        addExtraTime() {
            this.extraTimes.push({
                id: new Date().valueOf(),
                startTime: this.selected.startTime,
                endTime: this.selected.endTime
            })
        },
        removeExtraTime(timeId) {
            const timeIndex = this.extraTimes.findIndex( t => t.id === timeId);
            if (timeIndex >= 0) {
                this.extraTimes.splice(timeIndex, 1);
            }
        },
        handleInputChange(e) {
            let target = e.target
            if (e.target.type !== 'range') {
                target = document.getElementById('range')
            } 
            const min = target.min
            const max = target.max
            const val = target.value
            
            target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
        },
        getObject(obj_id) {
            for (let i=0; i<window.myObjects.length; i++) {
                if (window.myObjects[i].id===obj_id) {
                    return window.myObjects[i];
                }
            }
            return null
        },
        getRootDev(parent_id) {
            for (let i = 0; i < window.myDevs.length; i++) {
                const dev = window.myDevs[i];
                if (dev.id == parent_id) {
                    if (dev.parent_id == null) {
                        return dev;
                    } else {
                        return this.getRootDev(dev.parent_id);
                    }
                }
            }
            return null;
        },
        toggleFullscreen() {
            this.fullscreen = !this.fullscreen;
            this.needGetSize = true;
        },
        imageErrorHandler() {
            this.needGetSize = true;
        },
        imageLoadHandler() {
            if (!this.ctx) {
                return;
            }
            let image = document.getElementById('dsv-neuralSetting-preview-image');
            this.naturalWidth = image.naturalWidth;
            this.naturalHeight = image.naturalHeight;
            if (!this.needGetSize) {
                image.removeEventListener('load', this.imageLoadHandler);
                image.removeEventListener('error', this.imageErrorHandler);
                return;
            }
            this.needGetSize = false;
            let container = document.getElementById('dsv-neuralSetting-preview-image-container');
            let boundingRect = container.getBoundingClientRect();
            let canvas = document.getElementById('dsv-neuralSetting-canvas');
            canvas.width = boundingRect.width;
            canvas.height = boundingRect.height;
            this.ctx.lineWidth = 1;
            this.ctx.strokeStyle="#bb0000";
            this.ctx.fillStyle="#dd6666";
            if (this.lastWidth !== boundingRect.width) {
                const kf = boundingRect.width / this.lastWidth;
                const _points = [];
                for (let i = 0; i < this.points.length; i++) {
                    const point = this.points[i];
                    _points.push({x: point.x * kf, y: point.y * kf});
                }
                this.points = [];
                for (let i = 0; i < _points.length; i++) {
                    const point = _points[i];
                    this.points.push(point);
                }
            }
            this.lastWidth = boundingRect.width;
            this.drawLines();
        },
        devsToObject(dev_id, object_id) {
            for (let i = 0; i < window.myDevsToObjects.length; i++) {
                if (
                    window.myDevsToObjects[i].digression_id == dev_id &&
                    window.myDevsToObjects[i].object_id == object_id
                ) {
                    return true;
                }
            }
            return false;
        },
        setTypes(event) {
            const id = event.target.value;
            this.devs = [];
            if (id == 0) {
                for (let i = 0; i < window.myDevs.length; i++) {
                    const dev = window.myDevs[i];
                    if (this.activeCam) {
                        if (
                            dev.parent_id != null &&
                            dev.priority_id != null &&
                            this.devsToObject(dev.id, this.activeCam.object_id)
                        ) {
                            this.devs.push(dev);
                        }
                    } else {
                        if (this.type === 'smoker_detection' || this.type === 'face_recognition') {
                            if (
                                dev.parent_id != null &&
                                dev.priority_id != null
                            ) {
                                this.devs.push(dev);
                            }
                        }
                    }
                }
            } else {
                for (let i = 0; i < window.myDevs.length; i++) {
                    const dev = window.myDevs[i];
                    const par = this.getRootDev(dev.parent_id);
                    if (par && dev.priority_id != null) {
                        if (par.id == id) {
                            this.devs.push(dev);
                        }
                    }
                }
            }
            if (this.activeCam) {
                if (this.devs.length) {
                    this.selected.dev = this.devs[0].id;
                    this.selected.dev2 = this.devs[0].id;
                    this.getWatchers();
                    this.getWatchers2();
                } else {
                    this.selected.dev = null;
                    this.selected.dev2 = null;
                    this.users = [];
                    this.users2 = [];
                    this.selected.user = null;
                    this.selected.user2 = null;
                }
            }
        },
        setEndDate(event) {
            const value = event.target.value;
            if (value === this.selected.startDate) {
                const date = new Date(value);
                const key = Object.keys(CRON_DAYS).find(day => 
                    CRON_DAYS[day] === date.getDay()
                );
                this.resetDays();
                this.setDay(key);
            }
        },
        resetDays() {
            this.days['mon'] = this.days['tue'] = this.days['wed'] = this.days['thu'] = this.days['fri'] = false;
            this.days['sat'] = this.days['sun'] = false;
        },
        setDay(dayKey) {
            if (dayKey === 'weekdays') {
                this.days['mon'] = this.days['tue'] = this.days['wed'] = this.days['thu'] = this.days['fri'] = true;
                this.days['sat'] = this.days['sun'] = false;
            } else if (dayKey === 'all') {
                for (const [key] of Object.entries(this.days)) {
                    this.days[key] = true;
                }
            } else {
                this.days[dayKey] = !this.days[dayKey];
            }
        },
        getPositions(data) {
            this.loadingPositions = true;
            this.positions = [];
            window.myAjax('GET', '/ptz/positions', {
                camera_id: data.id
            }, {}, this.getPositionsCallback)
        },
        getPositionsCallback(error, response) {
            this.loadingPositions = false;
            if (error) {
                // todo
            } else {
                this.positions = (response.constructor || {}).name === 'Array' ? response : [];
                this.selected.position = this.positions[0] ? this.positions[0].id : 0;
            }
        },
        sendNewPosition() {
            if (!this.activeCam.ptz_enabled) {
                return;
            }
            this.sendPositions({
                camera_id: this.activeCam.id,
                position_id: this.selected.position
            });
        },
        sendPositions(data) {
            window.myAjax('POST', '/ptz/positions', {
                camera_id: data.camera_id,
                position_id: data.position_id
            }, this.sendPositionsCallback)
        },
        sendPositionsCallback() {
            // todo
        },
        getWatchers2() {
            if (!this.selected.dev2 || !this.activeCam) {
                return;
            } 
            this.loadingUsers2 = true;
            window.myAjax('GET', '/api/digression-executors?object_id=' + this.activeCam.object_id + '&digression_id=' + this.selected.dev2, {}, this.myCallbackGetWatchers2);
        },
        getWatchers() {
            if (!this.selected.dev || !this.activeCam) {
                return;
            } 
            this.loadingUsers = true;
            window.myAjax('GET', '/api/digression-executors?object_id=' + this.activeCam.object_id + '&digression_id=' + this.selected.dev, {}, this.myCallbackGetWatchers);
        },
        myCallbackGetWatchers2(error, response) {
            this.loadingUsers2 = false;
            if (error) {
                window.myAlert(response.message)
            } else {
                const users = [];
                for (let i=0; i < response.length; i++) {
                    users.push( this.getUser(response[i]) )
                }
                this.sortWatchers2(users)
            }
        },
        myCallbackGetWatchers(error, response) {
            this.loadingUsers = false;
            if (error) {
                window.myAlert(response.message)
            } else {
                const users = [];
                for (let i=0; i < response.length; i++) {
                    users.push( this.getUser(response[i]) )
                }
                this.sortWatchers(users)
            }
        },
        getUser(id) {
            for (let i = 0; i < window.myUsers.length; i++){
                if (id == window.myUsers[i].id) {
                    return window.myUsers[i];
                }
            }
            return null;
        },
        sortWatchers(users) {
            let needUpdate = true;
            const userObject = window.myUsers.reduce(
                (obj, item) => Object.assign(obj, { [item.id]: item }), {}
            );
            this.users = [];
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                if (userObject[user.id]) {
                    if (this.selected.user == user.id) {
                        needUpdate = false;
                    }
                    this.users.push(userObject[user.id]);
                }
            }
            if (needUpdate) {
                this.selected.user = (this.users[0] || {}).id;
            }
        },
        sortWatchers2(users) {
            let needUpdate = true;
            const userObject = window.myUsers.reduce(
                (obj, item) => Object.assign(obj, { [item.id]: item }), {}
            );
            this.users2 = [];
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                if (userObject[user.id]) {
                    if (this.selected.user2 == user.id) {
                        needUpdate = false;
                    }
                    this.users2.push(userObject[user.id]);
                }
            }
            if (needUpdate) {
                this.selected.user2 = (this.users2[0] || {}).id;
            }
        },
        clearInterval() {
            clearInterval(this.intervalId);
            this.intervalId = null;
        },
        updateImage() {
            if (this.activeCam) {
                this.src = this.activeCam.imgSrc + "&time=" + new Date();
            }
        },
        containerMouseClickHandler(event) {
            if (this.showResult) {
                return;
            }
            if (!this.showMessage && !this.editMode && !this.showMessage2 && !this.editMode2) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            if (!this.editMode2) {
                this.changeShowMessage(false);
                this.changeEditMode(true);
                if (this.thisLast && this.points.length >= 2) {
                    const startPoint = this.points[0];
                    this.points.push(startPoint);
                    this.changeEditMode(false);
                    this.changeShowMessage2(true);
                    this.changeEditMode2(true);
                    this.drawLines();
                    return;
                }
                let clientRect = document.getElementById('dsv-neuralSetting-canvas').getBoundingClientRect();
                let x = event.clientX - clientRect.x;
                let y = event.clientY - clientRect.y;
                if (this.points.length) {
                    const lastPoint = this.points[this.points.length - 1];
                    if (lastPoint.x === x && lastPoint.y === y) {
                        return;
                    }
                }
                if (x < POINT_BINDING_LENGHT) {
                    x = 0;
                } else if (x > (this.canvas.width - POINT_BINDING_LENGHT)) {
                    x = this.canvas.width;
                }
                if (y < POINT_BINDING_LENGHT) {
                    y = 0;
                } else if (y > (this.canvas.height - POINT_BINDING_LENGHT)) {
                    y = this.canvas.height;
                }
                this.points.push({x: x, y: y});
                if (this.points.length >= POINTS_MAX_NUMBER) {
                    const startPoint = this.points[0];
                    this.points.push(startPoint);
                    this.changeEditMode(false);
                    this.changeShowMessage2(true);
                    this.changeEditMode2(true);
                    this.drawLines();
                    return;
                }
            } else if (this.editMode2 && this.type === "absence_and_queue") {
                this.changeShowMessage2(false);
                this.changeEditMode2(true);

                if (this.thisLast2 && this.points2.length >= 2) {
                    const startPoint = this.points2[0];
                    this.points2.push(startPoint);
                    this.changeEditMode2(false);
                    this.drawLines();
                    return;
                }
                let clientRect = document.getElementById('dsv-neuralSetting-canvas').getBoundingClientRect();
                let x = event.clientX - clientRect.x;
                let y = event.clientY - clientRect.y;
                if (this.points2.length) {
                    const lastPoint = this.points2[this.points2.length - 1];
                    if (lastPoint.x === x && lastPoint.y === y) {
                        return;
                    }
                }
                if (x < POINT_BINDING_LENGHT) {
                    x = 0;
                } else if (x > (this.canvas.width - POINT_BINDING_LENGHT)) {
                    x = this.canvas.width;
                }
                if (y < POINT_BINDING_LENGHT) {
                    y = 0;
                } else if (y > (this.canvas.height - POINT_BINDING_LENGHT)) {
                    y = this.canvas.height;
                }
                this.points2.push({x: x, y: y});
                if (this.points2.length >= POINTS_MAX_NUMBER) {
                    const startPoint = this.points2[0];
                    this.points2.push(startPoint);
                    this.changeEditMode2(false);
                    this.drawLines();
                    return;
                }
            }
            this.drawLines();
        },
        drawLines(current) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            if (this.points.length) {
                for (let i = 0; i < this.points.length - 1; i++) {
                    const point1 = this.points[i];
                    const point2 = this.points[i + 1];
                    this.ctx.strokeStyle="#bb0000";
                    this.ctx.fillStyle = "#bb000022";
                    this.ctx.beginPath();
                    this.ctx.moveTo(point1.x, point1.y);
                    this.ctx.lineTo(point2.x, point2.y);
                    this.ctx.stroke();
                }
                if (current && !this.editMode2) {
                    let lastPoint = this.points[this.points.length - 1];
                    this.ctx.strokeStyle="#bb0000";
                    this.ctx.fillStyle = "#bb000022";
                    this.ctx.beginPath();
                    this.ctx.moveTo(lastPoint.x, lastPoint.y);
                    this.ctx.lineTo(current.x, current.y);
                    this.ctx.stroke();
                }
            }
            for (let i = 0; i < this.points.length; i++) {
                const point = this.points[i];
                this.ctx.strokeStyle="#bb0000";
                this.ctx.fillStyle = "#bb000022";
                this.ctx.beginPath();
                this.ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI);
                this.ctx.stroke();
                this.ctx.fill();
            }
            if (current && !this.editMode2) { 
                this.ctx.strokeStyle="#bb0000";
                this.ctx.fillStyle = "#bb000022";               
                this.ctx.beginPath();
                this.ctx.arc(current.x, current.y, 2, 0, 2 * Math.PI);
                this.ctx.stroke();
                this.ctx.fill();
            }
            if (!this.editMode && this.points.length > 2) {
                const lastFillStyle = this.ctx.fillStyle;
                this.ctx.strokeStyle="#bb0000";
                this.ctx.fillStyle = "#bb000022";
                this.ctx.beginPath();
                this.ctx.moveTo(this.points[0].x, this.points[0].y);
                for (let i = 1; i < this.points.length; i++) {
                    const point = this.points[i];
                    this.ctx.lineTo(point.x, point.y);
                }
                this.ctx.closePath();
                this.ctx.fill();
                this.ctx.fillStyle = lastFillStyle;
            }
            if (!this.editMode && this.type === "absence_and_queue") {
                if (this.points2.length) {
                    for (let i = 0; i < this.points2.length - 1; i++) {
                        const point1 = this.points2[i];
                        const point2 = this.points2[i + 1];
                        this.ctx.strokeStyle = "#00bb00";
                        this.ctx.fillStyle = "#00bb0022"; 
                        this.ctx.beginPath();
                        this.ctx.moveTo(point1.x, point1.y);
                        this.ctx.lineTo(point2.x, point2.y);
                        this.ctx.stroke();
                    }
                    if (current) {
                        let lastPoint = this.points2[this.points2.length - 1];
                        this.ctx.strokeStyle = "#00bb00";
                        this.ctx.fillStyle = "#00bb0022"; 
                        this.ctx.beginPath();
                        this.ctx.moveTo(lastPoint.x, lastPoint.y);
                        this.ctx.lineTo(current.x, current.y);
                        this.ctx.stroke();
                    }
                }
                for (let i = 0; i < this.points2.length; i++) {
                    const point = this.points2[i];
                    this.ctx.strokeStyle = "#00bb00";
                    this.ctx.fillStyle = "#00bb0022"; 
                    this.ctx.beginPath();
                    this.ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI);
                    this.ctx.stroke();
                    this.ctx.fill();
                }
                if (current) {
                    this.ctx.strokeStyle = "#00bb00";
                    this.ctx.fillStyle = "#00bb0022"; 
                    this.ctx.beginPath();
                    this.ctx.arc(current.x, current.y, 2, 0, 2 * Math.PI);
                    this.ctx.stroke();
                    this.ctx.fill();
                }
                if (!this.editMode && this.points2.length > 2) {
                    const lastFillStyle = this.ctx.fillStyle;
                    this.ctx.strokeStyle = "#00bb00";
                    this.ctx.fillStyle = "#00bb0022";
                    this.ctx.beginPath();
                    this.ctx.moveTo(this.points2[0].x, this.points2[0].y);
                    for (let i = 1; i < this.points2.length; i++) {
                        const point = this.points2[i];
                        this.ctx.lineTo(point.x, point.y);
                    }
                    this.ctx.closePath();
                    this.ctx.fill();
                    this.ctx.fillStyle = lastFillStyle;
                }
            }
        },
        containerMouseMoveHandler(event) {
            if ((!this.editMode && !this.editMode2) || this.showResult) {
                return;
            }
            event.preventDefault();
            event.stopPropagation();
            let clientRect = document.getElementById('dsv-neuralSetting-canvas').getBoundingClientRect();
            let x = event.clientX - clientRect.x;
            let y = event.clientY - clientRect.y;
            let lastPoint = {x: x, y: y};
            if (this.editMode2 && this.type === "absence_and_queue") {
                const startPoint = this.points2[0];
                this.thisLast2 = false;
                if (this.points2.length >= 2) {
                    if (
                        Math.abs(x - startPoint.x) < POINT_BINDING_LENGHT &&
                        Math.abs(y - startPoint.y) < POINT_BINDING_LENGHT
                    ) {
                        this.thisLast2 = true;
                        lastPoint = startPoint;
                    }
                }
            } else {
                const startPoint = this.points[0];
                this.thisLast = false;
                if (this.points.length >= 2) {
                    if (
                        Math.abs(x - startPoint.x) < POINT_BINDING_LENGHT &&
                        Math.abs(y - startPoint.y) < POINT_BINDING_LENGHT
                    ) {
                        this.thisLast = true;
                        lastPoint = startPoint;
                    }
                }
            }
            if (x < POINT_BINDING_LENGHT) {
                lastPoint.x = 0;
            } else if (x > (this.canvas.width - POINT_BINDING_LENGHT)) {
                lastPoint.x = this.canvas.width;
            }
            if (y < POINT_BINDING_LENGHT) {
                lastPoint.y = 0;
            } else if (y > (this.canvas.height - POINT_BINDING_LENGHT)) {
                lastPoint.y = this.canvas.height;
            }
            this.drawLines(lastPoint);
        },
        getFio(profile) {
            return (profile.last_name ? profile.last_name + ' ' : '') + 
                (profile.first_name ? profile.first_name + ' ' : '') +
                (profile.middle_name ? profile.middle_name + '' : '');
        },
        close() {
            this.clearInterval();
            let container = document.getElementById('dsv-neuralSetting-preview-image-container');
            if (container) {
                container.removeEventListener('click', this.containerMouseClickHandler);
                container.removeEventListener('mousemove', this.containerMouseMoveHandler);
            }
            window.$("#modalNeural").off('hidden.bs.modal', this.close);
            this.$emit('close');
        },
        changeShowResult(value) {
            this.border = {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                startX: 0,
                startY: 0,
                width: 0,
                height: 0,
                realyStartX: 0,
                realyStartY: 0
            };
            this.resetDraw();
            this.showBorder = false;
            if (this.activeCam && !this.task.id) {
                this.src = this.activeCam.imgSrc + "&time=" + new Date();
            }
            if (!this.intervalId && !this.task.id) {
                this.intervalId = setInterval(this.updateImage, 1000);
            }
            this.showResult = value;
            if (!this.showResult) {
                this.checkResult = {
                    status: null,
                    caption: ''
                }
            }
            this.changeShowMessage(!value);
        },
        resetDraw() {
            this.points = [];
            this.points2 = [];
            this.thisLast = false;
            this.thisLast2 = false;
            this.showMessage = true;
            this.showMessage2 = true;
            this.editMode = false;
            this.editMode2 = false;
            this.drawLines();
        },
        changeShowMessage(value) {
            this.showMessage = value;
            this.changeEditMode(!value);
        },
        changeShowMessage2(value) {
            this.showMessage2 = value;
            this.changeEditMode2(!value);
        },
        changeEditMode(value) {
            this.editMode = value;
        },
        changeEditMode2(value) {
            this.editMode2 = value;
        },
        convertDateToUTC(date) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        },
        getFormat(date) {
            return this.getFormatValue(date.getFullYear()) + "-"
                + this.getFormatValue((date.getMonth()+1))  + "-"
                + this.getFormatValue(date.getDate()) + " "
                + this.getFormatValue(date.getHours()) + ":"
                + this.getFormatValue(date.getMinutes()) + ":"
                + this.getFormatValue(date.getSeconds());
        },
        getFormatValue(timeValue) {
            const str = timeValue.toString();
            return str.length === 1 ? '0' + str : str;
        },
        startChanged() {
            if (this.type === 'first_in_detection' || this.type === 'last_out_detection') {
                const startTime = parseInt(this.selected.startTime.slice(0, 2));
                const minutes = this.selected.startTime.split(':')[1];
                const startEnd = (startTime + 1).toString();
                this.selected.endTime = (startEnd.length === 1 ? ('0' + startEnd) : startEnd) + ':' + minutes;
            }
        },
        getSchedule() {
            const step = this.selected.step;
            let result = '';
            if (
                this.type === 'cleaning_detection' ||
                this.type === 'bac_treatment'
            ) {
                result += '* ';
            } else {
                if (step.seconds) {
                    result += '* ';
                } else {
                    //минуты
                    if (step.minutes) {
                        result += '*' + step.value + ' ';
                    } else {
                        result += '0 ';
                    }
                }
            }
            //часы
            const hours = [];
            const startTime = parseInt(this.selected.startTime.slice(0, 2));
            const startEnd = parseInt(this.selected.endTime.slice(0, 2));
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            for (let i = startTime; i < startEnd + 1; i++) {
                if (i + diff < 0) {
                    hours.push(i + diff + 24);
                } else if (i + diff > 23) {
                    hours.push(i + diff - 24);
                } else {
                    hours.push(i + diff);
                }
            }
            result += hours.join(',');
            if (step.hours) {
                result += step.value + ' ';
            } else {
                result += ' ';
            }
            //дни месяца, месяцы
            result += '* * ';
            //дни недели
            const days = [];
            for (const [key, value] of Object.entries(this.days)) {
                if (value) {
                    days.push(CRON_DAYS[key]);
                }
            }
            if (days.length > 0) {
                result += days.join(',');
            } else {
                result += '* ';
            }
            //секунды
            if (
                this.type === 'cleaning_detection' ||
                this.type === 'bac_treatment'
            ) {
                if (this.smallWorkshop) {
                    result += ' */10';
                } else {
                    result += ' */15';
                }
            } else {
                if (step.seconds) {
                    result += ' *' + step.value;
                }
            }
            return result;
        },
        createSchedule() {
            if (this.loadingUsers) {
                return;
            }
            if (this.points.length === 0) {
                this.points.push({x: 0, y: 0});
                this.points.push({x: 0, y: this.canvas.height});
                this.points.push({x: this.canvas.width, y: this.canvas.height});
                this.points.push({x: this.canvas.width, y: 0});
                this.points.push({x: this.canvas.width, y: 0});
                if (this.type === 'absence_and_queue') {
                    if (this.points2.length === 0) {
                        this.points2.push({x: 0, y: 0});
                        this.points2.push({x: 0, y: this.canvas.height});
                        this.points2.push({x: this.canvas.width, y: this.canvas.height});
                        this.points2.push({x: this.canvas.width, y: 0});
                        this.points2.push({x: this.canvas.width, y: 0});
                    } else if (this.points2.length < 4) {
                        window.myAlert('Измените область очереди.');
                        return;
                    }
                }
            } else if (this.points.length < 4) {
                window.myAlert('Измените область.');
                return;
            }
            if (
                this.points[0].x === 0 && this.points[0].y === 0 &&
                this.points[1].x === 0 && this.points[1].y === 0 &&
                this.points[2].x === 0 && this.points[2].y === 0
            ) {
                window.myAlert('Произошла ошибка. Необходимо изменить зону на камере.');
                return;
            }
            let image = document.getElementById('dsv-neuralSetting-preview-image');

            let ratio = (image.naturalWidth || this.naturalWidth) / image.clientWidth;
            let ratioY = (image.naturalHeight || this.naturalHeight) / image.clientHeight;
            let zone = [];
            let zone2 = [];

            if (this.isVideo) {
                zone.push({x: 0, y: 1});
                zone.push({x: 1, y: 1});
                zone.push({x: 1, y: 0});
                zone.push({x: 0, y: 0});
            } else {
                for (let i = 0; i < this.points.length - 1; i++) {
                    const point = this.points[i];
                    zone.push({x: point.x * ratio, y: point.y * ratioY});
                }
                if (this.type === 'absence_and_queue') {
                    for (let i = 0; i < this.points2.length - 1; i++) {
                        const point2 = this.points2[i];
                        zone2.push({x: point2.x * ratio, y: point2.y * ratioY});
                    }
                }
            }
            console.log('image.naturalWidth', image.naturalWidth)
            console.log('this.naturalWidth', this.naturalWidth)
            console.log('image.clientWidth', image.clientWidth)
            console.log('ratio', ratio)
            var startTime = this.convertDateToUTC(new Date(this.selected.startDate + 'T' + this.selected.startTime));
            var endTime = this.convertDateToUTC(new Date(this.selected.endDate + 'T' + this.selected.endTime));
            if (
                endTime.getTime() < startTime.getTime()
            ) {
                window.myAlert('Неверные даты.');
                return;
            }
            if (
                new Date(this.selected.startDate + 'T' + this.selected.endTime).getTime() <=
                new Date(this.selected.startDate + 'T' + this.selected.startTime).getTime()
            ) {
                window.myAlert('Неверное время мониторинга.');
                return;
            }
            let startStr = this.getFormat(startTime);
            let endStr = this.getFormat(endTime);
            let data = Object.assign({
                camera_id: this.activeCam.id,
                object_id: this.activeCam.object_id,
                start_datetime: startStr,
                stop_datetime: endStr,
                auto_renewal: this.selected.renewal,
                double_check: this.selected.double_check,
                schedule: this.getSchedule(),
                zone: zone
            }, {});
            if (this.type === 'absence_and_queue') {
                data = Object.assign(data, {
                    queue_zone: zone2,
                    threshold: 1,
                    queue_threshold: this.selected.count
                });
            }
            if (
                this.type === 'cleaning_detection' ||
                this.type === 'bac_treatment'
            ) {
                if (this.selected.dev2 === this.selected.dev) {
                    window.myAlert('Выбраны одинаковые отклонения');
                    return;
                }
                data = Object.assign(data, {
                    digression_ids: JSON.stringify([this.selected.dev2, this.selected.dev]),
                    responsible_ids: JSON.stringify([this.selected.user2, this.selected.user]),
                });
            } else {
                data = Object.assign(data, {
                    digression_id: this.selected.dev,
                    responsible_id: this.selected.user,
                });
            }
            if (this.type === 'bac_treatment') {
                const EXTRA_DURATION = (this.selected.step || {}).step || 600;
                const TREATMENT_EXTRA_STEP = 15; //(this.selected.step || {}).step || 600; 15 / 15
                data = Object.assign(data, {
                    extra_check_count: Math.floor(EXTRA_DURATION / TREATMENT_EXTRA_STEP),
                    extra_check_step: TREATMENT_EXTRA_STEP, 
                    threshold: this.selected.count
                });
            }
            if (this.selected.position >= 1) {
                data = Object.assign(data, {
                    position_id: this.selected.position
                });
            }
            if (this.type === 'queue_detection') {
                data = Object.assign(data, {
                    threshold: this.selected.count
                });
            }
            if (this.type === 'absence_in_zone') {
                const AVSENCE_IN_ZONE_EXTRA_STEP = 5; 
                data = Object.assign(data, {
                    extra_check_count: Math.floor(this.selected.step.step - AVSENCE_IN_ZONE_EXTRA_STEP) - 1,
                    extra_check_step: AVSENCE_IN_ZONE_EXTRA_STEP, 
                    threshold: this.selected.count
                });
            }
            if (this.type === 'human_count') {
                data.unique = this.uniqueHumanCount;
            }
            if (this.type === 'first_in_detection' || this.type === 'last_out_detection') {
                data = Object.assign(data, {
                    extra_check_count: 1,
                    extra_check_step: this.selected.step.step,
                    auto_renewal: true,
                    threshold: 1,
                });
            }            
            if (this.type === 'equipment_detection') {
                if (!this.descSelected && !this.knifeSelected) {
                    window.myAlert('Не выбраны отклонения');
                    return;
                }
                if (this.descSelected) {
                    if (!this.selectedColors0.length) {
                        window.myAlert('Не выбраны цвета для досок');
                        return;
                    }
                }
                if (this.knifeSelected) {
                    if (!this.selectedColors1.length) {
                        window.myAlert('Не выбраны цвета для ножей');
                        return;
                    }
                }
                if (this.selectedColors0.length) {
                    if (!this.descSelected) {
                        window.myAlert('Цвета досок заданы, но не выбрано отклонение');
                        return;
                    }
                }
                if (this.selectedColors1.length) {
                    if (!this.knifeSelected) {
                        window.myAlert('Цвета ножей заданы, но не выбрано отклонение');
                        return;
                    }
                }
                const availableEquips = [];
                if (this.descSelected) {
                    this.selectedColors0.forEach(colorId => {
                        availableEquips.push({equip_id: 0, color_id: colorId});
                    })
                }
                if (this.knifeSelected) {
                    this.selectedColors1.forEach(colorId => {
                        availableEquips.push({equip_id: 1, color_id: colorId});
                    })
                }
                data = Object.assign(data, {
                    available_equips: availableEquips,
                    digression_ids: JSON.stringify([
                        this.descSelected ? this.selected.dev : 0,
                        this.knifeSelected ? this.selected.dev2 : 0,
                    ]),
                    responsible_ids: JSON.stringify([
                        this.descSelected ? this.selected.user : 0,
                        this.knifeSelected ? this.selected.user2 : 0,
                    ]),
                });
            }
            data.use_cooking_module = this.isCooking;
            data.media_type = this.isVideo ? 'video' : 'image';
            data.frames_count = this.smallWorkshop ? 2 : 3;
            this.loading = true;
            if (this.task.id) {
                data.task_id = this.task.id;
                window.myAjaxJson('POST', '/ml/' + this.type + '/update_schedule', data, this.createScheduleCallback)  
            } else {
                window.myAjaxJson('POST', '/ml/' + this.type + '/create_schedule', data, this.createScheduleCallback) 
            }
        },
        createScheduleCallback(error, response) {
            if (error) {
                window.myAlert(response.message || 'Произошла ошибка, попробуйте позже.');
            } else {
                if (this.task.id) {
                    this.close();
                } else {
                    if (this.extraTimes.length) {
                        const next = this.extraTimes.pop();
                        this.selected.endTime = next.endTime;
                        this.selected.startTime = next.startTime;
                        this.createSchedule();
                    } else {
                        this.close();
                    }
                }
            }
        },
        startCheckingFile(event) {
            if (this.loading) {
                return;
            }
            
            this.startCheckingFile2(event);
        },
        startCheckingFile2(event) {
            if (this.loading) {
                return;
            }
            this.showBorder = false;
            this.showResult = false;
            if (this.isVideo) {
                window.URL.revokeObjectURL(objectUrl);
                let zone = [];
                if (this.isVideo) {
                    zone.push({x: 0, y: 1});
                    zone.push({x: 1, y: 1});
                    zone.push({x: 1, y: 0});
                    zone.push({x: 0, y: 0});
                }

                this.loading = true;

                let reader = new FileReader();
                reader.onload = (e) => {
                    console.log('FileReader onload', e.target.result);
                    this.src = e.target.result;
                    window.myAjaxJson('POST', '/ml/' + this.type + '/test_from_file', {
                        zone: zone,
                        use_cooking_module: this.isCooking,
                        image: e.target.result,
                        media_type: this.isVideo ? 'video' : 'image'
                    }, (error, response) => {
                        this.loading = false;
                        if (!error) {
                            if (response.image_link) {
                                window.myAjaxJson('GET', response.image_link, {}, (error, response) => {
                                    if (error) {
                                        window.myAlert('Произошла ошибка, попробуйте позже.');
                                    } else {
                                        this.src = response;
                                    }
                                });
                            }
                            let status = response.is_detect;
                            let caption = '';
                            this.showBorder = true;
                            this.showResult = true;
                            let faces = [];
                            let persons = [];
                            let names = '';
                            let equipments = [];
                            let equipmentsStrings = '';
                            switch (this.type) {
                                case 'equipment_detection':
                                    status = !response.is_detect;
                                    equipments = response.equipment || [];
                                    equipmentsStrings = [];
                                    equipments.forEach( equip => {
                                        equipmentsStrings.push(`${EQUIPMENT_CLASSES[equip.class.toString()]} (${EQUIPMENT_COLORS[equip.color_class.toString()]})`)
                                    })
                                    caption = response.is_detect ?
                                        `Обнаружен инвентарь: (${equipments.length}) ${ equipments.length ? ': ' + equipmentsStrings.join(', ') : ''}` :
                                        'Инвентарь не обнаружен'
                                    break;
                                case 'smoker_detection':
                                    caption = response.is_detect ? 'Обнаружены курильщики' : 'Курильщики не обнаружены'
                                    break;
                                case 'bac_treatment':
                                    caption = response.is_detect ? 
                                        'Производится бактерицидная обработка' :
                                        'Бактерицидная обработка отсутствует'
                                    status = !response.is_detect;
                                    break;
                                case 'uniform_detection':
                                    caption = response.is_detect ? 
                                        'Отсутствуют шапочки' :
                                        'Не обнаружено отклонение'
                                    status = response.is_detect;
                                    break;
                                case 'gloves_detection':
                                    caption = response.is_detect ? 
                                        'Отсутствуют перчатки на руках' :
                                        'Не обнаружено отклонение'
                                    status = response.is_detect;
                                    break;
                                case 'cleaning_detection':
                                    caption = response.is_detect ? 
                                        'Обнаружена уборка в помещении' :
                                        'Уборка не производится'
                                    status = !response.is_detect;
                                    break;
                                case 'face_recognition':
                                    faces = response.faces;
                                    persons = [];
                                    faces.forEach( face => {
                                        if (face.person) {
                                            persons.push(face.person.name);
                                        }
                                    }) 
                                    names = persons.join(', ');
                                    caption = response.is_detect ? `Распознаны лица (${faces.length}) ${ persons.length ? ': ' + names : ''}` : 'Нет распознанных лиц'
                                    break;
                                default:
                                    caption = response.is_detect ? 'Обнаружено отклонение' : 'Не обнаружено отклонение'
                                    break;
                            }
                            this.checkResult = {
                                show: true,
                                status: status,
                                caption: caption
                            }
                        }
                    })
                };
                reader.readAsDataURL(event.target.files[0]);
                return;
            } else {
                const image = new Image();
                var objectUrl = window.URL.createObjectURL(event.target.files[0]);
                image.onload = () => {
                    window.URL.revokeObjectURL(objectUrl);
                    let zone = [];
                    zone.push({x: 0, y: image.height});
                    zone.push({x: image.width, y: image.height});
                    zone.push({x: image.width, y: 0});
                    zone.push({x: 0, y: 0});

                    this.loading = true;

                    let reader = new FileReader();
                    reader.onload = (e) => {
                        console.log('FileReader onload', e.target.result);
                        this.src = e.target.result;
                        window.myAjaxJson('POST', '/ml/' + this.type + '/test_from_file', {
                            zone: zone,
                            use_cooking_module: this.isCooking,
                            image: e.target.result,
                            media_type: this.isVideo ? 'video' : 'image'
                        }, (error, response) => {
                            this.loading = false;
                            if (!error) {
                                if (response.image_link) {
                                    window.myAjaxJson('GET', response.image_link, {}, (error, response) => {
                                        if (error) {
                                            window.myAlert('Произошла ошибка, попробуйте позже.');
                                        } else {
                                            this.src = response;
                                        }
                                    });
                                }
                                let status = response.is_detect;
                                let caption = '';
                                this.showBorder = true;
                                this.showResult = true;
                                let faces = [];
                                let persons = [];
                                let names = '';
                                let equipments = [];
                                let equipmentsStrings = '';
                                switch (this.type) {
                                    case 'equipment_detection':
                                        status = !response.is_detect;
                                        equipments = response.equipment || [];
                                        equipmentsStrings = [];
                                        equipments.forEach( equip => {
                                            equipmentsStrings.push(`${EQUIPMENT_CLASSES[equip.class.toString()]} (${EQUIPMENT_COLORS[equip.color_class.toString()]})`)
                                        })
                                        caption = response.is_detect ?
                                            `Обнаружен инвентарь: (${equipments.length}) ${ equipments.length ? ': ' + equipmentsStrings.join(', ') : ''}` :
                                            'Инвентарь не обнаружен'
                                        break;
                                    case 'smoker_detection':
                                        caption = response.is_detect ? 'Обнаружены курильщики' : 'Курильщики не обнаружены'
                                        break;
                                    case 'bac_treatment':
                                        caption = response.is_detect ? 
                                            'Производится бактерицидная обработка' :
                                            'Бактерицидная обработка отсутствует'
                                        status = !response.is_detect;
                                        break;
                                    case 'uniform_detection':
                                        caption = response.is_detect ? 
                                            'Отсутствуют шапочки' :
                                            'Не обнаружено отклонение'
                                        status = response.is_detect;
                                        break;
                                    case 'gloves_detection':
                                        caption = response.is_detect ? 
                                            'Отсутствуют перчатки на руках' :
                                            'Не обнаружено отклонение'
                                        status = response.is_detect;
                                        break;
                                    case 'cleaning_detection':
                                        caption = response.is_detect ? 
                                            'Обнаружена уборка в помещении' :
                                            'Уборка не производится'
                                        status = !response.is_detect;
                                        break;
                                    case 'face_recognition':
                                        faces = response.faces;
                                        persons = [];
                                        faces.forEach( face => {
                                            if (face.person) {
                                                persons.push(face.person.name);
                                            }
                                        }) 
                                        names = persons.join(', ');
                                        caption = response.is_detect ? `Распознаны лица (${faces.length}) ${ persons.length ? ': ' + names : ''}` : 'Нет распознанных лиц'
                                        break;
                                    default:
                                        caption = response.is_detect ? 'Обнаружено отклонение' : 'Не обнаружено отклонение'
                                        break;
                                }
                                this.checkResult = {
                                    show: true,
                                    status: status,
                                    caption: caption
                                }
                            }
                        })
                    };
                    reader.readAsDataURL(event.target.files[0]);
                };
                image.src = objectUrl;
            }
        },
        startChecking() {
            if (this.loading) {
                return;
            }
            
            if (this.points.length === 0) {
                this.points.push({x: 0, y: 0});
                this.points.push({x: 0, y: this.canvas.height});
                this.points.push({x: this.canvas.width, y: this.canvas.height});
                this.points.push({x: this.canvas.width, y: 0});
                this.points.push({x: this.canvas.width, y: 0});

                if (this.type === 'absence_and_queue') {
                    if (this.points2.length === 0) {
                        this.points2.push({x: 0, y: 0});
                        this.points2.push({x: 0, y: this.canvas.height});
                        this.points2.push({x: this.canvas.width, y: this.canvas.height});
                        this.points2.push({x: this.canvas.width, y: 0});
                        this.points2.push({x: this.canvas.width, y: 0});
                    } else if (this.points2.length < 4) {
                        window.myAlert('Измените область очереди.');
                        return;
                    }
                }

                
            } else if (this.points.length < 4) {
                window.myAlert('Измените область.');
                return;
            }

            this.clearInterval();
            let image = document.getElementById('dsv-neuralSetting-preview-image');
            this.loading = true;
            let ratio = (image.naturalWidth || this.naturalWidth) / image.clientWidth;
            let ratioY = (image.naturalHeight || this.naturalHeight) / image.clientHeight;
            let zone = [];
            let zone2 = [];
            if (this.isVideo) {
                zone.push({x: 0, y: 1});
                zone.push({x: 1, y: 1});
                zone.push({x: 1, y: 0});
                zone.push({x: 0, y: 0});
            } else {
                for (let i = 0; i < this.points.length - 1; i++) {
                    const point = this.points[i];
                    zone.push({x: point.x * ratio, y: point.y * ratioY});
                }
                if (this.type === 'absence_and_queue') {
                    for (let i = 0; i < this.points2.length - 1; i++) {
                        const point2 = this.points2[i];
                        zone2.push({x: point2.x * ratio, y: point2.y * ratioY});
                    }
                }
            }
            let data = {
                camera_id: this.activeCam.id,
                threshold: this.selected.count,
                zone: zone
            };
            if (this.selected.position >= 1) {
                Object.assign(data, {
                    position_id: this.selected.position
                });
            }
            if (this.type === 'absence_and_queue') {
                data = Object.assign(data, {
                    queue_zone: zone2,
                    threshold: 1,
                    queue_threshold: this.selected.count
                });
            }
            data.use_cooking_module = this.isCooking;
            data.media_type = this.isVideo ? 'video' : 'image';
            window.myAjaxJson('POST', '/ml/' + this.type + '/test_from_cam', data, this.checkingCallback)
        },
        checkingCallback(error, response) {
            this.loading = false;
            if (error) {
                window.myAlert('Произошла ошибка, попробуйте позже.');
            } else {
                this.showBorder = true;
                this.showResult = true;
                window.myAjaxJson('GET', response.image_link, {}, this.imageCallback)
                let status = response.is_detect;
                let caption = this.type === 'license_plate_detection' ?
                    (response.is_detect ?
                        ('Обнаружены номера: ' + response.plates.map(plate => plate.value || '[нераспознанный]').join(', ')) :
                        'Не обнаружено номеров'
                    ) :
                    (response.is_detect ? 'Обнаружено отклонение' : 'Не обнаружено отклонение')
                if (this.type === 'bac_treatment') {
                    if (response.is_detect) {
                        caption = 'Производится бактерицидная обработка';
                        status = false;
                    } else {
                        caption = 'Бактерицидная обработка отсутствует';
                        status = true;
                    }
                }
                if (this.type === 'equipment_detection') {
                    status = !response.is_detect;
                    let equipments = response.equipment || [];
                    let equipmentsStrings = [];
                    equipments.forEach( equip => {
                        equipmentsStrings.push(`${EQUIPMENT_CLASSES[equip.class.toString()]} (${EQUIPMENT_COLORS[equip.color_class.toString()]})`)
                    })
                    caption = response.is_detect ?
                        `Обнаружен инвентарь: (${equipments.length}) ${ equipments.length ? ': ' + equipmentsStrings.join(', ') : ''}` :
                        'Инвентарь не обнаружен'
                }
                this.checkResult = {
                    show: true,
                    status: status,
                    caption: caption
                }
            }
        },
        imageCallback(error, response) {
            if (error) {
                window.myAlert('Произошла ошибка, попробуйте позже.');
            } else {
                this.src = response;
            }
        },
        toggleRenewal() {
            this.selected.renewal = !this.selected.renewal;
            if (this.selected.renewal) {
                if (this.selected.endDate === this.selected.startDate) {
                    this.setDay('all');
                }
            }
        },
        toggleExtraCount() {
            this.selected.double_check = !this.selected.double_check;
        }
    }
}
