import { render, staticRenderFns } from "./content.vue?vue&type=template&id=64fccb80&scoped=true&"
import script from "./_content/content.js?vue&type=script&lang=js&"
export * from "./_content/content.js?vue&type=script&lang=js&"
import style0 from "./_content/content.scss?vue&type=style&index=0&id=64fccb80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64fccb80",
  null
  
)

export default component.exports