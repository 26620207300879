<style lang="scss" src="./_clients/card.scss"></style>
<style scoped>
    .admin_bg {
        background-color: #ccc;
    }
    .myGreen {
        color: #219653;
    }
    .myRed {
        color: #ee877e;
    }
    .object_right_panel {
        background: #FFFFFF;
        box-shadow: 0px 0.747159px 2.13079px rgba(0, 0, 0, 0.0196802), 0px 1.79553px 5.12058px rgba(0, 0, 0, 0.0282725), 0px 3.38082px 9.64159px rgba(0, 0, 0, 0.035), 0px 6.0308px 17.199px rgba(0, 0, 0, 0.0417275), 0px 11.28px 32.1688px rgba(0, 0, 0, 0.0503198), 0px 27px 17px rgba(0, 0, 0, 0.07);
        border-radius: 10px;
        width: 100%;
    }
    .my_label {
        font-size: 16px;
        margin-bottom: 12px;
    }
    input, select, textarea {
        border: 2px solid #eee;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
        padding: 4px;
        padding-right: 8px;
        padding-left: 8px;
    }
    select.filled, input.filled {
        border: 2px solid #096866;
    }
    .add_node {
        background: url('../../assets/img/new_role.svg');
    }
    .object_users {
        background: url('../../assets/img/list_users.svg');
    }
    .profile_user {
        background: url('../../assets/img/user2.svg');
        background-color: #ddd;
        border-radius: 20px;
        max-width: 44px !important;
    }
    .profile_user2 {
        background: url('../../assets/img/enter_to_lk.svg');
        max-width: 44px !important;
    }
    .profile_user_edit {
        background: url('../../assets/img/edit_client.svg');
        max-width: 44px !important;
    }
    .controls {
        width: 40px;
        height: 40px;
    }
    .settingsAdmin {
        background: url('../../assets/img/settings.svg');
        max-width: 44px !important;
    }
    .filterNo {
        filter: none;
    }
    .errors {
        color: #F65656;
    }
    .cams_header>div {
        font-weight: 700;
    }
    .cam_check {
        min-width: 24px;
        max-width: 24px;
        height: 24px;
        padding: 0px;
        content: '';
        border: 2px solid #C2C2C2;
        border-radius: 20px;
        cursor: pointer;
    }
    .list_header {
        font-size: 22px;
        font-weight: 700;
    }
</style>

<template>

    <div class="mx-6 mt-7">
        <div class="row py-0 mx-0 px-0">
            <div class="my_h1 mt-2 mb-3">{{ $t('Клиенты') }}</div>
            <div v-if="showUserCard && !editClient" class='controls mb-0 object_users' :title="$t('список клиентов')" @click="allUsersList"></div>
            <div v-if="!showUserCard" class='controls mb-0 add_node' :title="$t('создать нового клиента')" @click="userCard(null)"></div>
        </div>

        <div class="object_right_panel mb-12">
            <div v-if="showUserCard" class="row mx-0">
                <div class="col-12 mx-0 px-2 py-6 row">
                    <div v-if="cardUser.id" class='controls profile_user2 col-1 mr-5' v-bind:class="usersClass" :title="$t('вход в ЛК клиента')" @click="enterToClient"></div>
                    <div v-else class='controls profile_user col-1 mr-2 ml-5' v-bind:class="usersClass"></div>
                    <div class="col">
                        <h2>{{ cardUser.name }}</h2>
                        <div :class="this.cardUser.status==1 ? 'myRed' : 'myGreen'">{{ $t('Учетная запись') }} {{ userStatusText }}</div>
                    </div>
                    <div v-if="cardUser.id && editClient" class="col-1 pr-2 my-auto">
                        <div  :class="['toggleBtn', this.cardUser.status==1 ? 'grayFilter' : '']" @click="slideBall">
                            <div></div>
                        </div>
                    </div>
                    <div v-if="cardUser.id && editClient" class="col-2 pl-0 my-auto">
                        {{ userStatusBtnText }}
                    </div>
                    <div v-if="cardUser.id" :class="['controls profile_user_edit col-1 mr-5', editClient ? 'filterNo' : '']" :title="$t('редактировать данные клиента')" @click="editClient = !editClient"></div>
                    <div v-if="cardUser.id" :class="['controls settingsAdmin col-1 mr-5', showSettings ? 'filterNo' : '']" :title="$t('настройки')" @click="showSettings = !showSettings"></div>

                    <hr class="col-12 mx-0 my-4 px-0">
                    
                    <div v-if="showSettings" class="dsv-adminClients__card-settings">
                        <div class="my_h1">Расписание смарт-архива</div>
                        <div class="dsv-adminClients__card-settings-row">
                            <div class="dsv-schedule__items">
                                <div class="dsv-schedule__item">
                                    <div class="dsv-schedule__item-caption">Шаг:</div>
                                    <select v-model="selected.step" class="dsv-schedule__select" :disabled="!editClient">
                                        <option v-for="step in steps" v-bind:key="step.id" :value="step">{{ step.caption }}</option>
                                    </select>
                                </div>
                                <div class="dsv-schedule__item">
                                    <div class="dsv-schedule__item-caption">{{ $t('Время мониторинга') }}:</div>
                                    <span class="dsv-schedule__input-caption">C</span>
                                    <select class="dsv-schedule__select" v-model="selected.startTime" :disabled="!editClient">
                                        <option v-bind:key="time.value" v-for="time in times" :value="time.value">{{ time.caption || time.value }}</option>
                                    </select>
                                    <span class="dsv-neuralSetting__input-caption">По</span>
                                    <select class="dsv-neuralSetting__select" v-model="selected.endTime" :disabled="!editClient">
                                        <option v-bind:key="time.value" v-for="time in times" :value="time.value">{{ time.caption || time.value }}</option>
                                    </select>
                                </div>
                                <div class="dsv-schedule__item">
                                    <div class="dsv-schedule__item-caption">
                                        {{ $t('Дни недели') }}
                                        <span :class="[ editClient ? '' : 'dsv-schedule__week_disabled' ]">
                                            ( <span @click="setDay('weekdays')">{{ $t('будни') }}</span>, <span @click="setDay('all')"> {{ $t('все') }}</span> )
                                        </span>
                                    </div>
                                    <div :class="['dsv-schedule__week', editClient ? '' : 'dsv-schedule__week_disabled' ]">
                                        <button type="button" :class="['dsv-schedule__day', days.mon ? 'dsv-schedule__day_selected' : '']" @click="setDay('mon')">{{ $t('Пн') }}</button>
                                        <button type="button" :class="['dsv-schedule__day', days.tue ? 'dsv-schedule__day_selected' : '']" @click="setDay('tue')">{{ $t('Вт') }}</button>
                                        <button type="button" :class="['dsv-schedule__day', days.wed ? 'dsv-schedule__day_selected' : '']" @click="setDay('wed')">{{ $t('Ср') }}</button>
                                        <button type="button" :class="['dsv-schedule__day', days.thu ? 'dsv-schedule__day_selected' : '']" @click="setDay('thu')">{{ $t('Чт') }}</button>
                                        <button type="button" :class="['dsv-schedule__day', days.fri ? 'dsv-schedule__day_selected' : '']" @click="setDay('fri')">{{ $t('Пт') }}</button>
                                        <button type="button" :class="['dsv-schedule__day', days.sat ? 'dsv-schedule__day_selected' : '']" @click="setDay('sat')">{{ $t('Сб') }}</button>
                                        <button type="button" :class="['dsv-schedule__day', days.sun ? 'dsv-schedule__day_selected' : '']" @click="setDay('sun')">{{ $t('Вс') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="my_h1">{{ $t('Настройки') }}</div>
                        <div class="dsv-adminClients__card-settings-row">
                            <div class="dsv-schedule__items">
                                <div class="dsv-schedule__item">
                                    <BaseCheckbox
                                        text="Отключение превью при старте видеопотока"
                                        :value="stopAllPreviewOnStreamStart"
                                        @click="toggleStopAllPreviewOnStreamStart"/>
                                </div>
                            </div>
                        </div>
                        
                        <div class="my_h1">{{ $t('Хранилище данных') }}</div>
                        <div class="dsv-adminClients__card-settings-row">
                            <MediaSpaceList :clientId="cardUser.id" />
                        </div>
                        
                        <div class="my_h1">{{ $t('Настройки хранилища') }}</div>
                        <div class="dsv-adminClients__card-settings-row">
                            <MediaSpaceSettings :clientId="cardUser.id" />
                        </div>
                        
                        <!--
                        <div class="my_h1">{{ $t('Проверки камер') }}</div>
                        <div class="dsv-adminClients__card-settings-row">
                            <CamerasLogsList :clientId="cardUser.id" />
                        </div>
                        -->
                        
                        <div class="my_h1">{{ $t('Индивидуальные пути для ML-модулей') }}</div>
                        <div class="dsv-adminClients__card-settings-row">
                            <ModulePaths :clientId="cardUser.id" />
                        </div>
                        
                        <div class="my_h1">{{ $t('Пользовательские настройки') }} ({{ $t('для всех клиентов') }})</div>
                        <div class="dsv-adminClients__card-settings-row">
                            <UsersSettings />
                        </div>
                        <!--
                        <div class="my_h1 border-bottom">Настройки</div>
                        <div class="dsv-adminClients__card-settings-row">
                            <div class="dsv-schedule__items">
                                <div class="dsv-schedule__item">
                                    <div class="dsv-schedule__item-caption">Разрешение превью:</div>
                                    <div class="dsv-adminClients__card-inputs">
                                        <input :disabled="!editClient" type="number" v-model.number="resolution.width" class="dsv-schedule__item-input">
                                        <span>x</span>
                                        <input :disabled="!editClient" v-model="resolution.height" class="dsv-schedule__item-input">
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->
                    </div>

                    <div v-else class="col-12 mx-0 px-2 py-6 row">

                        <div class="col-6 px-6 py-0">
                            <div class="my_label">
                                {{ $t('Название') }} *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.name" placeholder="Пятерочка" @change="checkLength('name')">
                            <div class="errors">{{ cardUser.errors.name }}</div>
                        </div>
                        <div class="col-6 px-6 py-0">
                            <div class="my_label">
                                {{ $t('Название') }} (латиницей) *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.latin_name" placeholder="Pyaterochka" @change="checkLength('latin_name')">
                            <div class="errors">{{ cardUser.errors.latin_name }}</div>
                        </div>
                        <div class="col-12 px-6 py-0">
                            <div class="my_label">
                                {{ $t('Адрес') }}
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.address">
                            <div class="errors"></div>
                        </div>

                        <div class="col-12 my_h1 pb-5 pt-3 px-6 border-bottom admin_bg">{{ $t('Администратор') }}</div>

                        <div class="col-4 px-6 pb-0 admin_bg pt-3">
                            <div class="my_label">
                                {{ $t('Фамилия') }} *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.admin_last_name" @change="checkLength('admin_last_name')">
                            <div class="errors">{{ cardUser.errors.admin_last_name }}</div>
                        </div>
                        <div class="col-4 px-6 pb-0 admin_bg pt-3">
                            <div class="my_label">
                                {{ $t('Имя') }} *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.admin_first_name" @change="checkLength('admin_first_name')">
                            <div class="errors">{{ cardUser.errors.admin_first_name }}</div>
                        </div>
                        <div class="col-4 px-6 pb-0 admin_bg pt-3">
                            <div class="my_label">
                                {{ $t('Отчество') }}
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.admin_middle_name">
                            <div class="errors"></div>
                        </div>
                        <div class="col-6 px-6 py-0 admin_bg">
                            <div class="my_label">
                                {{ $t('Логин') }}/email *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.admin_email" @change="checkEmail('admin_email')">
                            <div class="errors">{{ cardUser.errors.admin_email }}</div>
                        </div>
                        <div class="col-6 px-6 py-0 admin_bg">
                            <div class="my_label">
                                {{ $t('Телефон') }} *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.admin_phone" @change="checkPhone('admin_phone')">
                            <div class="errors">{{ cardUser.errors.admin_phone }}</div>
                        </div>

                        <div class="col-12 my_h1 pb-5 px-6 border-bottom pt-3">{{ $t('Главное контактное лицо') }}</div>

                        <div class="col-4 px-6 pb-0 pt-3">
                            <div class="my_label">
                                {{ $t('Фамилия') }} *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.chief_last_name" @change="checkLength('chief_last_name')">
                            <div class="errors">{{ cardUser.errors.chief_last_name }}</div>
                        </div>
                        <div class="col-4 px-6 pb-0 pt-3">
                            <div class="my_label">
                                {{ $t('Имя') }} *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.chief_first_name" @change="checkLength('chief_first_name')">
                            <div class="errors">{{ cardUser.errors.chief_first_name }}</div>
                        </div>
                        <div class="col-4 px-6 pb-0 pt-3">
                            <div class="my_label">
                                {{ $t('Отчество') }}
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.chief_middle_name">
                            <div class="errors"></div>
                        </div>
                        <div class="col-4 px-6 py-0">
                            <div class="my_label">
                                {{ $t('Должность') }} *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.chief_position" @change="checkLength('chief_position')">
                            <div class="errors">{{ cardUser.errors.chief_position }}</div>
                        </div>
                        <div class="col-4 px-6 py-0">
                            <div class="my_label">
                                {{ $t('Email') }} *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.chief_email" @change="checkEmail('chief_email')">
                            <div class="errors">{{ cardUser.errors.chief_email }}</div>
                        </div>
                        <div class="col-4 px-6 py-0">
                            <div class="my_label">
                                {{ $t('Телефон') }} *
                            </div>
                            <input :disabled="!editClient" v-model="cardUser.chief_phone" @change="checkPhone('chief_phone')">
                            <div class="errors">{{ cardUser.errors.chief_phone }}</div>
                        </div>

                        <div class="col-6 px-6 py-0 mb-2">
                            <div class="my_label">
                                {{ $t('Комментарий') }}
                            </div>
                            <textarea :disabled="!editClient" rows="5" v-model="cardUser.comment"></textarea>
                        </div>
                    </div>

                    <div class="col-6 px-9 row mx-0 justify-content-end">
                        <div class="my_btn my-auto" @click="allUsersList">{{ $t('Отмена') }}</div>
                        <div class="my_btn btn_delete ml-6 my-auto" v-if="editClient && cardUser.id" @click="deleteUser">{{ $t('Удалить') }}</div>
                        <div class="my_btn btn_green ml-6 my-auto" v-if="editClient" :title="$t('сохранить изменения')" @click="saveUser">{{ $t('Сохранить') }}</div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="cam_list py-4 mx-9">
                    <div class="cams_header row py-2">
                        <div class="col-1 py-0">#</div>
                        <div class="col-3 py-0">{{ $t('Наименование') }}</div>
                        <div class="col-3 py-0">{{ $t('Контактное лицо') }}</div>
                        <div class="col-3 py-0">{{ $t('Объекты / камеры') }}</div>
                        <div class="col-2 py-0 text-right">{{ $t('Статус') }}</div>
                    </div>
                    <div v-for="user in users" v-bind:key="user.id"
                        v-bind:class="[ user.status==1 ? 'text--disabled' : '', 'cam row border-top py-2' ]">
                        <div class="col-1 my-auto py-0 the_link" @click="userCard(user)">{{ user.id }}</div>
                        <div class="col-3 my-auto py-0 the_link" @click="userCard(user)">{{ user.name }}</div>
                        <div class="col-3 my-auto py-0">{{ userContact(user) }}</div>
                        <div class="col-3 my-auto py-0">{{user.num_objs}}/{{user.num_cams}}</div>
                        <div class="col-2 row pr-0 my-auto justify-content-end">
                            {{ userStatusStr(user.status) }}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import BaseCheckbox from './../base/checkbox2';
import MediaSpaceSettings from './settings/mediaSpace';
import UsersSettings from './settings/userSettings';
import MediaSpaceList from './../reports/mediaSpaceList';
// import CamerasLogsList from './../reports/camerasLogsList';
import ModulePaths from './../mlPaths/settingsPage';

import { PERIODS, CRON_DAYS } from './../neural/utils'; 

const CRON_DAYS2 = {
    1: 'mon',
    2: 'tue',
    3: 'wed',
    4: 'thu',
    5: 'fri',
    6: 'sat',
    0: 'sun'
}


export default {
    name: 'Clients',
    components: {
        BaseCheckbox,
        MediaSpaceList,
        MediaSpaceSettings,
        // CamerasLogsList,
        UsersSettings,
        ModulePaths,
    },
    data: () => ({
        steps: [
            { id: '1', caption: '3 сек.', value: '/3', seconds: true, step: 3 },
            { id: '2', caption: '5 сек.', value: '/5', seconds: true, step: 5 },
            { id: '3', caption: '15 сек.', value: '/15', seconds: true, step: 15 },
            { id: '4', caption: '30 сек.', value: '/30', seconds: true, step: 30 },
            { id: '5', caption: '1 мин.', value: '/1', minutes: true, step: 60 },
            { id: '6', caption: '2 мин.', value: '/2', minutes: true, step: 120 },
            { id: '7', caption: '5 мин.', value: '/5', minutes: true, step: 300 },
            { id: '8', caption: '10 мин.', value: '/10', minutes: true, step: 600 },
            { id: '9', caption: '15 мин.', value: '/15', minutes: true, step: 900 },
            { id: '10', caption: '30 мин.', value: '/30', minutes: true, step: 1800 },
            { id: '11', caption: '1 час', value: '/1', hours: true, step: 3600 },
            { id: '12', caption: '2 часа', value: '/2', hours: true, step: 7200 }
        ],
        times: [
            { value: '00:00', caption: '0:00' },
            { value: '01:00', caption: '1:00' },
            { value: '02:00', caption: '2:00' },
            { value: '03:00', caption: '3:00' },
            { value: '04:00', caption: '4:00' },
            { value: '05:00', caption: '5:00' },
            { value: '06:00', caption: '6:00' },
            { value: '07:00', caption: '7:00' },
            { value: '08:00', caption: '8:00' },
            { value: '09:00', caption: '9:00' },
            { value: '10:00' },
            { value: '11:00' },
            { value: '12:00' },
            { value: '13:00' },
            { value: '14:00' },
            { value: '15:00' },
            { value: '16:00' },
            { value: '17:00' },
            { value: '18:00' },
            { value: '19:00' },
            { value: '20:00' },
            { value: '21:00' },
            { value: '22:00' },
            { value: '23:00' }
        ],
        showUserCard: false,
        editClient: false,
        usersClass: '',
        allUsersClass: '',
        users: [],
        errors: {
            name: '',
            latin_name: '',
            address: '',
            chief_email: '',
            chief_phone: '',
            chief_first_name: '',
            chief_last_name: '',
            chief_position: '',
            admin_email: '',
            admin_phone: '',
            admin_first_name: '',
            admin_last_name: ''
        },
        newUser: {
            id: 0,
            status: 10,
            name: '',
            latin_name: '',
            address: '',
            chief_email: '',
            chief_phone: '',
            chief_first_name: '',
            chief_middle_name: '',
            chief_last_name: '',
            chief_position: '',
            comment: '',
            admin_email: '',
            admin_phone: '',
            admin_first_name: '',
            admin_middle_name: '',
            admin_last_name: '',

            num_cams: 0,
            num_objs: 0,

            errors: {
                name: '',
                latin_name: '',
                address: '',
                chief_email: '',
                chief_phone: '',
                chief_first_name: '',
                chief_last_name: '',
                chief_position: '',
                admin_email: '',
                admin_phone: '',
                admin_first_name: '',
                admin_last_name: ''
            }
        },
        cardUser: {
            id: 0,
            status: 10,
            name: '',
            latin_name: '',
            address: '',
            chief_email: '',
            chief_phone: '',
            chief_first_name: '',
            chief_middle_name: '',
            chief_last_name: '',
            chief_position: '',
            comment: '',
            admin_email: '',
            admin_phone: '',
            admin_first_name: '',
            admin_middle_name: '',
            admin_last_name: '',

            num_cams: 0,
            num_objs: 0,

            errors: {
                name: '',
                latin_name: '',
                address: '',
                chief_email: '',
                chief_phone: '',
                chief_first_name: '',
                chief_last_name: '',
                chief_position: '',
                admin_email: '',
                admin_phone: '',
                admin_first_name: '',
                admin_last_name: ''
            }
        },
        showSettings: false,
        selected: {
            step: null,
            startTime: null,
            endTime: null
        },
        resolution: {
            width: null,
            height: null
        },
        days: {
            'mon': false,
            'tue': false,
            'wed': false,
            'thu': false,
            'fri': false,
            'sat': false,
            'sun': false
        },
        loading: false,
        smartSetting: null,
        stopAllPreviewOnStreamStart: true,
        stopAllPreviewOnStreamStartUpdated: false,
        stopAllPreviewOnStreamStartSettings: null
    }),
    computed: {
        userStatusText() {
            let res
            switch(this.cardUser.status) {
                case 1:
                    res = 'заблокирована'
                    break
                case 10:
                    res = 'активна'
                    break
            }
            return res
        },
        userStatusBtnText() {
            let res
            switch(this.cardUser.status) {
                case 1:
                    res = 'Разблокировать'
                    break
                case 10:
                default:
                    res = 'Заблокировать'
                    break
            }
            return res
        }
    },
    mounted() {
        this.startData()
    },
    methods: {
        startData() {
            this.getClients()
        },
        userContact(user) {
            return user.chief_position + ' ' + user.chief_last_name + ' ' + user.chief_first_name
        },
        userStatusStr(status_id) {
            let res
            switch(status_id) {
                case 1:
                    res = 'Заблокирован'
                    break
                case 10:
                default:
                    res = 'Активен'
                    break
            }
            return res
        },
        slideBall() {
//              window.$('.toggleBtn').toggleClass('grayFilter')
//            window.$('.myGreen').toggleClass('myRed')
            if(this.cardUser.status == 1) {
                this.cardUser.status = 10
            } else {
                this.cardUser.status = 1
            }
        },
//          setUserStatus() {
//            window.$('.toggleBtn').toggleClass('grayFilter')
//          window.$('.myGreen').toggleClass('myRed')
    //    },

        checkLength(val) {
            if(this.cardUser[val].trim().length)
                this.cardUser.errors[val] = ''
            else
                this.cardUser.errors[val] = 'Заполните поле'
        },
        checkEmail(email) {
            if(window.isEmail(this.cardUser[email]))
                this.cardUser.errors[email] = ''
            else
                this.cardUser.errors[email] = 'Некорректный email'
        },
        checkPhone(phone) {
            if (window.phoneRegular.test(this.cardUser[phone]))
                this.cardUser.errors[phone] = ''
            else
                this.cardUser.errors[phone] = 'Некорректный телефон'
        },

        getSchedule() {
            const step = this.selected.step;
            let result = '';
            //минуты
            if (step.minutes) {
                result += '*' + step.value + ' ';
            } else {
                result += '0 ';
            }
            //часы
            const hours = [];
            const startTime = parseInt(this.selected.startTime.slice(0, 2));
            const startEnd = parseInt(this.selected.endTime.slice(0, 2));
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            for (let i = startTime; i < startEnd; i++) {
                if (i + diff < 0) {
                    hours.push(i + diff + 24);
                } else if (i + diff > 23) {
                    hours.push(i + diff - 24);
                } else {
                    hours.push(i + diff);
                }
            }
            result += hours.join(',');
            if (step.hours) {
                result += step.value + ' ';
            } else {
                result += ' ';
            }
            //дни месяца, месяцы
            result += '* * ';
            //дни недели
            const days = [];
            for (const [key, value] of Object.entries(this.days)) {
                if (value) {
                    days.push(CRON_DAYS[key]);
                }
            }
            if (days.length > 0) {
                result += days.join(',');
            } else {
                result += '* ';
            }
            //секунды
            if (step.seconds) {
                result += ' *' + step.value;
            }
            return result;
        },

        saveUser() {
            if (this.cardUser.id) {
                const schedule = this.getSchedule();
                if (this.stopAllPreviewOnStreamStartUpdated) {
                    const data = {
                        name: 'stop_all_preview_on_stream_start',
                        value: this.stopAllPreviewOnStreamStart,
                        client_id: this.cardUser.id,
                        setting_id: this.stopAllPreviewOnStreamStartSettings.id
                    }
                    if (this.stopAllPreviewOnStreamStartSettings.id) {
                        window.myAjax('PUT', '/api/settings', data, () => {})
                    } else {
                        window.myAjax('POST', '/api/settings', data, () => {})
                    }
                }
                if ((this.smartSetting || {}).schedule !== schedule) {
                    if (this.smartSetting) {
                        const data = {
                            name: 'smart_archive_schedule',
                            value: schedule,
                            client_id: this.cardUser.id,
                            setting_id: this.smartSetting.id
                        }
                        window.myAjax('PUT', '/api/settings', data, this.saveUser2)
                    } else {
                        const data = {
                            name: 'smart_archive_schedule',
                            value: schedule,
                            client_id: this.cardUser.id
                        }
                        window.myAjax('POST', '/api/settings', data, this.saveUserSettingsCallback)
                    }
                } else {
                    this.saveUser2()
                }
            } else {
                this.saveUser2()
            }
        },
        saveUserSettingsCallback(error, response) {
            window.myAlert(response.message)
            if(!error) {
                this.saveUser2()
            }
        },

        saveUser2() {
            if(this.valideUser()) {
                let data = Object.assign({}, this.cardUser);
                let url = '/api/client';
                let method = 'POST';
                if (this.cardUser.id) {
                    url = '/api/client'
                    method = 'PUT';
                }
                window.myAjax(method, url, data, this.myCallbackSaveUser)
            }
        },
        myCallbackSaveUser (error, response) {
            window.myAlert(response.message)
            if(!error) {
                if(!this.cardUser.id)
                    this.cardUser.id = response.client.id
                this.getClients()
            }
        },
        deleteUser() {
            window.myAjax('DELETE', '/api/client', this.cardUser, this.myCallbackDeleteUser)
        },
        myCallbackDeleteUser (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.myAlert(response.message)
                this.getClients()
            }
        },

        updateUsers() {
            let url = '/api/clients',
                method = 'GET';
            window.myAjax(method, url, {}, this.myCallbackGetStartU)
        },
        myCallbackGetStartU (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.myCliets = response.clients
                this.startData()
            }
        },

        usersList() {
            this.showUsers=!this.showUsers
            if(this.showUsers)
                this.usersClass = 'active'
            else
                this.usersClass = ''
        },
        allUsersList() {
            this.showUserCard = false
        },

        valideUser() {
            let res = true;
            if(this.cardUser.name.length) {
                this.cardUser.errors.name = ''
            } else {
                res = false
                this.cardUser.errors.name = 'Не указано название'
            }
            if(this.cardUser.latin_name.length) {
                this.cardUser.errors.latin_name = ''
            } else {
                res = false
                this.cardUser.errors.latin_name = 'Не указано название латиницей'
            }

            // admin
            if(this.cardUser.admin_first_name.length) {
                this.cardUser.errors.admin_first_name = ''
            } else {
                res = false
                this.cardUser.errors.admin_first_name = 'Не указано имя администратора'
            }
            if(this.cardUser.admin_last_name.length) {
                this.cardUser.errors.admin_last_name = ''
            } else {
                res = false
                this.cardUser.errors.admin_last_name = 'Не указана фамилия администратора'
            }
            if(window.isEmail(this.cardUser.admin_email)) {
                this.cardUser.errors.admin_email = ''
            } else {
                res = false
                this.cardUser.errors.admin_email = 'Некорректный логин-email администратора'
            }
            if(this.cardUser.admin_phone.length) {
                this.cardUser.errors.admin_phone = ''
            } else {
                res = false
                this.cardUser.errors.admin_phone = 'Не указан контактный телефон администратора'
            }

            // contact
            if(this.cardUser.chief_position.length) {
                this.cardUser.errors.chief_position = ''
            } else {
                res = false
                this.cardUser.errors.chief_position = 'Не указана должность контактного лица'
            }
            if(this.cardUser.chief_first_name.length) {
                this.cardUser.errors.chief_first_name = ''
            } else {
                res = false
                this.cardUser.errors.chief_first_name = 'Не указано имя контактного лица'
            }
            if(this.cardUser.chief_last_name.length) {
                this.cardUser.errors.chief_last_name = ''
            } else {
                res = false
                this.cardUser.errors.chief_last_name = 'Не указана фамилия контактного лица'
            }
            if(window.isEmail(this.cardUser.chief_email)) {
                this.cardUser.errors.chief_email = ''
            } else {
                res = false
                this.cardUser.errors.chief_email = 'Некорректный email контактного лица'
            }
            if(this.cardUser.chief_phone.length) {
                this.cardUser.errors.chief_phone = ''
            } else {
                res = false
                this.cardUser.errors.chief_phone = 'Не указан телефон контактного лица'
            }

              return res
          },
          notEmptyArray(arr) {
              let res = false
              for(let i=0; i<arr.length; i++)
                  if(arr[i].length) {
                      res = true
                      break
                  }
              return res
          },
        
        notEmptyEmails(arr) {
            let res = true
            for(let i=0; i<arr.length; i++)
                if(!window.isEmail(arr[i])) {
                    res = false
                    break
                }
            return res
        },

        userCard(user) {
            if(user==null) {
                this.cardUser = Object.assign({}, this.newUser)
                this.editClient = true
            } else {
                this.cardUser = Object.assign({}, user)
                this.editClient = false
            }

            this.selected.step = this.steps[6];
            this.selected.startTime = '08:00';
            this.selected.endTime = '21:00';

            this.cardUser.errors = this.errors
            this.showUserCard = true;
            this.showSettings = false;
            if (this.cardUser.id) {
                this.getSettings();
            }
        },

        toggleStopAllPreviewOnStreamStart() {
            this.stopAllPreviewOnStreamStart = !this.stopAllPreviewOnStreamStart;
            this.stopAllPreviewOnStreamStartUpdated = true;
        },
        getSettings() {
            this.loading = true;
            window.myAjax('GET', '/api/settings', {
                client_id: this.cardUser.id
            }, this.getSettingsCallback)
        },
        getSettingsCallback(error, response) {
            if (error) {
                window.myAlert(response.message)
            } else {
                this.loading = false;

                this.stopAllPreviewOnStreamStartSettings = (response || []).find( (res) => res.name === "stop_all_preview_on_stream_start") || {};
                this.stopAllPreviewOnStreamStart = this.stopAllPreviewOnStreamStartSettings.value === 'false' ? false : true;
                this.stopAllPreviewOnStreamStartUpdated = false;
                this.smartSetting = null;

                const setting = (response || []).find( (res) => res.name === "smart_archive_schedule");

                if (!setting) {
                    return;
                }

                this.smartSetting = setting;

                const schedule = setting.value;

                const data = {};
                data.days = this.getWeekDays(schedule);

                this.days = data.days;

                const steps = this.getPeriod(schedule);
                data.step = this.steps.find( (s) => s.caption === steps);

                data.startTime = (hours[0].length === 1 ? ('0' + hours[0]) : hours[0]) + ':00';
                data.endTime = (hours[1].length === 1 ? ('0' + hours[1]) : hours[1]) + ':00';
                const hours = this.getScheduleHours(schedule, data.startTime, data.endTime);
                this.selected = Object.assign({}, this.selected, data);
            }
        },

        getClients() {
            let url = '/api/clients',
                method = 'GET';
            window.myAjax(method, url, {}, this.myCallbackGetStartObj)
        },
        myCallbackGetStartObj (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                this.users = window.myClients = response.clients;
                this.showUserCard = false;
            }
        },

        enterToClient() {
            let url = '/api/super-login',
                method = 'POST';
            window.myAjax(method, url, {client_id: this.cardUser.id}, this.myCallbackToClient)
        },
        myCallbackToClient (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.setCookieTime('superAdminToken', window.getCookie('userToken'), 1)
                window.setCookieTime('userId', (response.profile || {}).user_id, 1)
                window.setCookieTime('userToken', response.token, 1)
                window.setCookie('refreshToken', response.refresh_token);
                window.userBaseRole = 'Администратор'
                window.myAjax('GET', '/api/client_features', {
                    client_id: this.cardUser.id
                }, (cferror, cfresponse) => {
                    if (!cferror) {
                        window.features = cfresponse;
                        this.$emit('enterToClient')
                    }
                });
            }
        },


          
        setDay(dayKey) {
            if (!this.editClient) {
                return;
            }
            if (dayKey === 'weekdays') {
                this.days['mon'] = this.days['tue'] = this.days['wed'] = this.days['thu'] = this.days['fri'] = true;
                this.days['sat'] = this.days['sun'] = false;
            } else if (dayKey === 'all') {
                for (const [key] of Object.entries(this.days)) {
                    this.days[key] = true;
                }
            } else {
                this.days[dayKey] = !this.days[dayKey];
            }
        },

         getScheduleHours(schedule, start, stop) {
            let result = [];
            let periods = schedule.split(/\s/);
            let hours = periods[1].split(/\\/);
            let hoursValues = hours[0].split(',');
            const d = new Date();
            let diff = d.getTimezoneOffset()/ -60;
            const startTime = parseInt(hoursValues[0]) + diff;
            const lastTime = parseInt(hoursValues[hoursValues.length - 1]) + diff;
            if (startTime < 0) {
                result.push(startTime + 24);
            } else if (startTime > 23) {
                result.push(startTime - 24);
            } else {
                result.push(startTime);
            }
            if (lastTime < 0) {
                result.push(lastTime + 24);
            } else if (lastTime > 23) {
                result.push(lastTime - 24);
            } else {
                result.push(lastTime);
            }
            if (start && stop) {
                const minutesStart = ("0" + new Date(start).getMinutes()).slice(-2);
                const minutesStop = ("0" + new Date(stop).getMinutes()).slice(-2);
                result.push(minutesStart);
                result.push(minutesStop);
            } else {
                result.push('00');
                result.push('00');
            }
            return result;
        },
        getWeekDays(schedule) {
            let periods = schedule.split(/\s/);
            if (periods[4] === '*') {
                const weekDays = {};
                for (let key in CRON_DAYS2) {
                    weekDays[key] = true;
                }
                return weekDays;
            }
            let days = periods[4].split(',');
            const weekDays = {};
            days.forEach(dayKey => {
                weekDays[CRON_DAYS2[dayKey]] = true;
            });
            return weekDays;
        },
        getPeriod(schedule) {
            const periods = schedule.split(/\s/);
            let key = '';
            if (periods[0].indexOf('/') > -1) {
                key = 'm' + periods[0].split(/\//)[1];
            } else if (periods[1].indexOf('/') > -1) {
                key = 'h' + periods[1].split(/\//)[1];
            } else if ((periods[5] || '').indexOf('/') > -1) {
                key = 's' + periods[5].split(/\//)[1];
            }
            return PERIODS[key];
        },
    }
}
</script>
