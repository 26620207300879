import { getAllChecklists } from './../../../checklist/Utils/getAllChecklists';
import { createRelations, deleteRelations, getRelationsByUser } from '../../../checklist/Utils/userChecklistRelations';
import globalPermissions from './permissions';
import rolesPermissions from './rolesPermissions';

import UserCard from './../userCard';
import UserList from './../userList';
import UserFilter from './../userFilter';
import UserHeader from './../userHeader';

import RelationsSelector from './../relationsSelector';

import ModalNew from './../../../base/modalNew';
import CheckboxNew from './../../../base/checkboxNew';

const GROUP_TITLES = {
    'analytic': 'Аналитика и отчёты',
    'cameras': 'Доступ Пользователей к Камерам',
    'checklists': 'Чек-листы',
    'clients': 'Клиенты',
    'contacts': 'Контакты',
    'digressions': 'Отклонения',
    'journal': 'Журнал',
    'logs': 'Отчёты об активности пользователей',
    'ml': 'Нейромодули',
    'objects': 'Доступ Пользователей к Объектам',
    'permissions': 'Настройка прав',
    'roles': 'Доступ Пользователей к Ролям',
    'users': 'Доступ Пользователей к Пользователям',
    'price tag': 'Работа с ценниками',
    'users settings': 'Пользовательские настройки',
    'monitoring': 'Хранилище медиа данных',
    'archive': 'Работа с архивом',
    'smart archive': 'Работа со смарт-архивом',
    'sensors': 'Работа с датчиками',
}

const PERMISSION_DESCRIPTIONS = {
    1010: 'Получение аналитики о созданных отклонениях по своим объектам; получение статистики о распознанных ценниках и штрихкодах', 
    1011: 'Получение аналитики о созданных отклонениях по всем объектам',
    1012: 'Выгрузка аналитики об отклонениях, чек-листах, пользовательской активности в формате Excel; запуск расписания об автоматической отправке отчетов',

    1029: 'Просмотр записи архива, получение кадров/фрагмента из архива; просмотр списка запущенных расписаний',
    1030: 'Запуск и остановка расписаний архива на камерах',

    1019: 'Создание, изменение и удаление камеры',
    1020: 'Получение информации о камерах; запуск и просмотр изображения превью; запуск и просмотр видео-потока с камеры',

    1008: 'Создание, изменение и удаление чек-листа; управление связями чек-лист - объект - пользователь',
    1009: 'Получение списка чек-листов; создание "лога" (отметки) чек-листа',

    1004: 'Создание, изменение и удаление контактов',
    1005: 'Получение информации о контактах',
    
    1000: 'Создание, изменение и удаление отклонений; настройка связей отклонение - объект - пользователь; ',

    1001: 'Создание записи отклонения; изменение статуса записи отклонения; добавление/удаление своих комментариев к записи отклонения',
    1002: 'Просмотр "своих" (автор/исполнитель) отклонений',
    1003: 'Просмотр всех отклонений клиента',

    1006: 'Просмотр логов (отчет) о действиях пользователей на платформе',
    1007: 'Выгрузка отчет о действиях пользователей на платформе в формате Excel; запуск расписания автоматических отчетов',

    1014: 'Запуск и остановка расписания работы МЛ-модулей; получение списка запущенных модулей; тест через подгружаемое изображения ML-модулей. Тест по камере и настройка (запуск/изменение) конкретного модуля осуществляется через права по конкретному модулю.',

    1022: 'Получение информации о занятом объеме диска в разбивке по категориям: смарт-архив, архив, файлы записей отклонений',
    1023: 'Получение клиентских настроек о сроках хранения файлов в хранилище',

    1013: 'Настройка связей пользователя - объект',
    1018: 'Создание, изменение и удаление объектов; настройка связей объект - отклонение; создание, изменение и удаление форматов объектов',

    1017: 'Привязка прав к ролям',

    110: 'Просмотр доступных конкурентов, объектов мониторинга (и их форматов), категорий, товаров, штрихкодов, данных справочника',
    111: 'Редактирование (создание/изменение/удаление) конкурентов, объектов мониторинга (и их форматов), категорий, товаров, штрихкодов, данных справочника',
    112: 'Просмотр запущенных задач по распознаванию ценников/штрихкодов',
    113: 'Запуск задач на распознавание ценников/штрихкодов',
    114: 'Просмотр журнала распознавания ценников/штрихкодов (просмотр результатов); получение уведомлений о результатах, требующих ручной проверки',
    115: 'Редактирование карточек результатов распознавания ценников/штрихкодов',

    1016: 'Создание, изменение и удаление ролей',

    1027: 'Получение информации о доступных датчиках на камерах',
    1028: 'Администрирование датчиков на камерах',

    1031: 'Получение списка запущенных расписаний смарт-архива; получение кадров смарт-архива',
    1032: 'Запуск и остановка расписания смарт-архива',

    1015: 'Создание, изменение и удаление пользователей',

    1025: 'Получение списка доступных пользовательских настроек',
    1026: 'Изменение пользовательских настроек для пользователя',
}

export default {
    name: 'Users',
    props: ['addRootObject'],
    components: {
        ModalNew,
        CheckboxNew,
        UserCard,
        UserList,
        UserFilter,
        UserHeader,
        RelationsSelector
    },
    data: () => ({
        selectedUser: null,
        showPermissionModal: false,
        permMode: 1,
        permIds: [],
        permData: [],
        permLoading: false,

        selectedAll: false,
        searchObject: '',
        filterStatus: '0',

        showDigressionModal: false,
        digMode: 1,
        digObj: [],
        digCams: [],
        digDigressions: [],
        digData: [],
        digGetLoading: false,

        setUsers: [],
        getUsers: [],

        selecting: false,
        selectingUsers: [],
        selectingUsersCards: [],

        modalChecklists: [],
        allSelected: false,
        allSelectedCreate: false,
        allSelectedExecute: false,
        modalDevsHead: '',
        modalDevs: [],
        user_object_id: null,
        showUsersList: true,
        showUserCard: false,
        roleName: '',
        nameClass: '',
        cityClass: '',
        mapClass: 'addMap',
        addressClass: '',
        saveClass: '',
        camsClass: '',
        usersClass: '',
        allUsersClass: '',
        devClass: '',
        select1: 1,
        select2: 5,
        select3: 3,
        select1Class: 'selectRed',
        select2Class: 'selectGreen',
        select3Class: 'selectYellow',
        showCams: false,
        showUsers: true,
        showDev: false,
        objects: [],
        filteredObjects: [],
        users: [],
        childrenOfBaseRoles: [],
        search_str: '',
        cardBaseRole: {},

        permissions: [],
        permissionsChecks: {},
        rolePermissionsChecks: {},
        permissionsBlock: {},

        devsToUserObjectsToAdd: [],
        devsToUserObjectsToDelete: [],
        devsToUserObjects: [],

        checklistsToUserObjectsToAdd: [],
        checklistsToUserObjectsToDelete: [],
        checklistsToUserObjects: [],
        constpermissions: [],
        selectedAllReps: [],
        newUser: {
            id: 0,
            baseRole: 0,
            status: 1,
            is_ai: false,
            first_name: '',
            middle_name: '',
            last_name: '',
            login: '',
            email: '',
            dolg: '',
            comment: '',
            emails: [''],
            phones: [''],
            roles: [],
            permissionsId: [],
            directPermissionsId: [],
            objectsId: [],
            objectsLinks: [],
            camsId: [],
            camsLinks: [],
            errorFirstName: '',
            errorMiddleName: '',
            errorLastName: '',
            errorBaseRoles: '',
            errorLogin: '',
            errorRoles: '',
            errorEmails: '',
            errorPhones: ''
        },
        visibleChecklists: [],
        cardUser: {
            id: 0,
            status: 1,
            is_ai: false,
            first_name: '',
            middle_name: '',
            last_name: '',
            login: '',
            email: '',
            dolg: '',
            comment: '',
            emails: [''],
            phones: [''],
            roles: [],
            permissionsId: [],
            directPermissionsId: [],
            objectsId: [],
            objectsLinks: [],
            camsId: [],
            camsLinks: [],
            errorFirstName: '',
            errorMiddleName: '',
            errorLastName: '',
            errorLogin: '',
            errorBaseRoles: '',
            errorRoles: '',
            errorEmails: '',
            errorPhones: ''
        },
        cams: [],
        devs: [],
        objectNew: {
            id: 0,
            parent_id: null,
            name: '',
            permissions: [],
            icon_id: null
        },
        permissionsOrder: [
            'archive', 'smart archive', //2-2
            'digressions', 'checklists', 'journal', //3-5
            'analytic', 'logs', //2-7
            'ml', 'price tag', //2-9
            'cameras', 'objects', //2-11
            'roles', 'users', 'contacts', 'permissions', //4-15
            'users settings', 'sensors', 'monitoring' //2-17
        ],
        objectRoot: {
            id: 0,
            parent_id: 0,
            parentType: 'node',
            name: '',
            permissions: [],
            object_type: 'object',
            city: '',
            address: ''
        },
        usersObjects: [],
        object: {
            id: 0,
            parent_id: null,
            name: '',
            permissions: [],
            icon_id: null,
        },
        objectParent: {
            id: 0,
            parent_id: null,
            name: '',
            permissions: [],
            icon_id: null,
        },
        objectsChildrenId: [],
        errorName: '',
        errorCity: '',
        errorAddress: '',
        allRoles: [],
        baseRoles: [],
        allPermissions: [],
        allPermissionsCheck: {},

        objectsRelations: {},
        objectsRelationsTmp: {},
        edited: false,
        showObjects: false,
        showPermissions: false,
        showNotify: false,
        telegram: false,
        myNumberData: null,
        showedZones: {},
        showLogin: false,
        filter: '0',
        copyMode: false,
        showModalDevs: false,
        copyObjects: [],
        copyCams: [],
        copyDigs: [],
        copyChecklists: [],
        usersForCopy: [],
        checklistTree: {},
        disabledChecklistTree: {},
        descritions: {},
    }),
    computed: {
        fio() {
            let res = this.cardUser.last_name + ' ' + this.cardUser.first_name
            if(this.cardUser.middle_name)
                res += ' ' + this.cardUser.middle_name
            return res
        },
        userStatusText() {
            let res
            switch(this.cardUser.status) {
                case 1:
                    res = 'заблокирована'
                    break
                case 10:
                    res = 'активна'
                    break
            }
            return res
        },
        userStatusBtnText() {
            let res
            switch(this.cardUser.status) {
                case 1:
                    res = 'Разблокировать'
                    break
                case 10:
                    res = 'Заблокировать'
                    break
            }
            return res
        }
    },
    watch: {
        selectedUser(value) {
            if (this.showPermissionModal) {
                this.setPermsIds(value);
            }
        },
        filterStatus() {
            this.filterObjects();
        },
        searchObject() {
            this.filterObjects();
        }
    },
    mounted() {
        this.descritions = PERMISSION_DESCRIPTIONS;
        this.getAllChecklistsCallback = this.getAllChecklistsCallback.bind(this);
        this.startData();
        // for (const id in globalPermissions) {
        //     this.permissions.push(globalPermissions[id]);
        // }
        this.getPermissions();
    },
    methods: {
        saveCopyObjects() {
            this.copyObjects = this.cardUser.objectsId;
            // this.copyDigs = this.cardUser.camsId;
            // this.copyChecklists =
            this.saveNextObj();
        },
        saveNextObj() {
            const nextId = this.copyObjects.pop();
            window.myAjax('POST', '/api/user-object-relation', {
                object_id: nextId,
                user_id: this.cardUser.id
            }, (error, response) => {
                if (!error) {
                    let newRelation = {
                        object_id: parseInt(response.relation.object_id),
                        user_id: parseInt(response.relation.user_id),
                        id: parseInt(response.relation.id)
                    }
                    this.cardUser.objectsLinks.push(newRelation)
                    this.cardUser.objectsId.push(newRelation.object_id)
                    if (this.copyObjects.length) {
                        this.saveNextObj();
                    } else {
                        this.saveCopyCams();
                    }
                }
            });
        },
        saveCopyCams() {
            this.copyCams = this.cardUser.camsId;
            this.saveNextCam();
        },
        saveNextCam() {
            const nextId = this.copyCams.pop();
            window.myAjax('POST', '/api/user-camera-relation', {
                camera_id: nextId,
                user_id: this.cardUser.id
            }, (error, response) => {
                if (!error) {
                    let newRelation = {
                        camera_id: parseInt(response.relation.camera_id),
                        user_id: parseInt(response.relation.user_id),
                        id: parseInt(response.relation.id)
                    }
                    this.cardUser.camsLinks.push(newRelation)
                    this.cardUser.camsId.push(newRelation.camera_id)
                    if (this.copyCams.length) {
                        this.saveNextCam();
                    } else {
                        this.allUsersList();
                        window.myAlert('Пользователь сохранен');
                    }
                }
            });
        },
        copyUser() {
            window.myConfirm('Внимание!', 'Создать пользователя на основе текущего? Несохранненные настройки на текущем не будет применены.', () => {
                this.copyMode = true;
                this.showLogin = true;
                this.cardUser.id = null;
                this.cardUser.login = '';
                this.cardUser.emails = [];
                this.cardUser.emails.push('')
                this.cardUser.phones = [];
                this.cardUser.phones.push('')
                this.edited = true;
                this.cardUser.first_name = '';
                this.cardUser.last_name = '';
                this.cardUser.middle_name = '';
            });
        },
        closeDigressionModal() {
            this.showDigressionModal = false;
            this.digMode = 1;
            this.digObj = [];
            this.digCams = [];
            this.digDigressions = [];
            this.digData = [];
            this.selectedUser = null;
            this.digGetLoading = false;

            this.selecting = false;
            this.selectingUsers = [];
            this.selectingUsersCards = [];
        },
        closePermissionModal() {
            this.showPermissionModal = false;
            this.permMode = 1;
            this.permIds = [];
            this.permData = [];
            this.selectedUser = null;
            this.permLoading = false;

            this.selecting = false;
            this.selectingUsers = [];
            this.selectingUsersCards = [];
        },
        togglePermIds(perm_id, checked) {
            if (checked) {
                this.permIds.push(perm_id);
            } else {
                this.permIds.splice(this.permIds.indexOf(perm_id), 1);
            }
        },
        setDigMode(mode) {
            this.digMode = mode;
        },
        setPermMode(mode) {
            this.permMode = mode;
            if (mode === 2) {
                this.savePerm()
            }
        },
        savePerm() {
            this.permData = [];
            this.selectingUsersCards.forEach( userCard => {
                const card = Object.assign({
                    directPermissionsId: []
                }, userCard);
                let toAdd = [];
                let toRemove = [];
                for (let i=0; i < userCard.permissions.length; i++) {
                    card.directPermissionsId.push(userCard.permissions[i].id);
                }
                card.directPermissionsId.forEach( permId => {
                    if (!this.permIds.includes(permId)) {
                        toRemove.push(this.constpermissions.find( p => p.id == permId));
                    }
                })
                this.permIds.forEach( permId => {
                    if (!card.directPermissionsId.includes(permId)) {
                        toAdd.push(this.constpermissions.find( p => p.id == permId));
                    }
                })
                card.toAdd = [...new Set(toAdd)];
                card.toRemove = [...new Set(toRemove)];
                this.permData.push(card);
            })
        },
        saveNext() {
            this.permLoading = true;

            const nextId = this.selectingUsers.pop();

            const user = window.myUsers.find( u => u.id == nextId);
            const card = Object.assign({}, user);

            card.roles = [];
            card.emails = [];
            card.phones = [];
            card.directPermissionsId = [];
            card.objectsId = [];
            card.objectsLinks = [];
            card.camsId = [];
            card.camsLinks = [];

            card.baseRole = this.baseRoleIdOfThe(user.roles[0]);
            card.id = user.id;
            card.login = user.email;
            card.email = user.email;
            card.status = user.status_id;
            for (let i=0; i<user.roles.length; i++) {
                card.roles.push(user.roles[i]);
            }
            if (user.profile !== undefined) {
                card.first_name = user.profile.first_name;
                card.last_name = user.profile.last_name;
                card.middle_name = user.profile.middle_name;
                card.dolg = user.profile.position;
                card.comment = user.profile.comments;
                card.is_ai = user.profile.is_ai;
                for (let i=0; i<user.profile.emails.length; i++) {
                    if (user.profile.emails[i]) {
                        card.emails.push(user.profile.emails[i].email);
                    }
                }
                for (let i=0; i<user.profile.phones.length; i++) {
                    if (user.profile.phones[i]) {
                        card.phones.push(user.profile.phones[i].phone);
                    }
                }
            }
            card.directPermissionsId = [...new Set(this.permIds)];

            card.emails = JSON.stringify(card.emails)
            card.phones = JSON.stringify(card.phones)
            card.roles = JSON.stringify(card.roles)
            card.permissionsId = JSON.stringify(card.permissionsId)
            card.directPermissionsId = JSON.stringify(card.directPermissionsId)
            card.objectsId = JSON.stringify(card.objectsId)
            card.objectsLinks = JSON.stringify(card.objectsLinks)
            card.camsId = JSON.stringify(card.camsId)
            card.camsLinks = JSON.stringify(card.camsLinks)
            window.myAjax('POST', '/api/update-user', card, (error, response) => {
                this.permLoading = false;
                if (!error) {
                    if (this.selectingUsers.length) {
                        this.saveNext();
                    } else {
                        window.myAlert('Права сохранены успешно.');
                        window.myAjax('GET', '/api/users', {}, (error2, response2) =>  {
                            if(error2) {
                                window.myAlert(response2.message);
                            } else {
                                window.myUsers = response2.users;
                                this.closePermissionModal();
                                this.startData()
                            }
                        });
                    }
                } else {
                    window.myAlert(response.message || 'Ошибка в запросе.');
                }
            })
        },
        getPermissions() {
            window.myAjax('GET', '/api/permissions', {}, this.getPermissionsCallback)
        },
        getPermissionsCallback(error, response) {
            if (!error) {
                this.constpermissions = response.permissions;
                const perms = this.groupPermissions(response.permissions);
                this.permissions = [];
                (perms || []).forEach( permissionGroup => {
                    if (
                        (window.features.find( feature =>
                                feature.feature_name === permissionGroup.id
                        ) || {}).enabled
                    ) {
                        this.permissions.push(permissionGroup);
                    }
                })
            }
        },
        groupPermissions(permissions) {
            const result = [];
            permissions.forEach( (permession) => {
                const index = result.findIndex( (perm) => perm.id === permession.guard_name);
                if (index < 0) {
                    result.push({
                        id: permession.guard_name,
                        name: GROUP_TITLES[permession.guard_name],
                        subs: []
                    })
                }
                result[index < 0 ? (result.length - 1) : index].subs.push(permession)
            });
            return result.sort( (a, b) => this.permissionsOrder.indexOf(a.id) - this.permissionsOrder.indexOf(b.id));
        },
        filterObjects() {
            const filter = this.filterStatus;
            this.filteredObjects = [];
            for (let i = 0; i < this.objects.length; i++) {
                const obj = this.objects[i];
                if (
                    (filter === '0') ||
                    (filter === '1' && this.userObjectsHas(obj.id)) ||
                    (filter === '2' && !this.userObjectsHas(obj.id))
                ) {
                    if (
                        (this.searchObject || '').length === 0 ||
                        obj.name.toLowerCase().indexOf(this.searchObject.toLowerCase()) >= 0 
                    )
                    this.filteredObjects.push(obj)
                }
            }
        },
        toggleShowZones(id) {
            const obj = {};
            obj[id] = !this.showedZones[id];
            this.showedZones = Object.assign({}, this.showedZones, obj);
        },
        toggleObjects() {
            this.showObjects = !this.showObjects;
        },
        togglePermissions() {
            this.showPermissions = !this.showPermissions;
        },
        setEdited() {
            this.edited = true;
        },
        startData() {
            this.users = window.myUsers;
            this.users = this.users.sort( (a, b) => (this.userFio(a.profile) > this.userFio(b.profile)) ? 1 : -1);

            this.allRoles = window.myRoles
            this.setBaseRoles()
            this.allPermissions = window.myAllAdminPermissions
            
            const data = {}
            for (let i = 0; i < window.myAllAdminPermissions.length; i++) {
                const perm =  window.myAllAdminPermissions[i];
                data[perm.id] = true;
            }
            this.allPermissionsCheck = Object.assign({}, data);
            this.objects = window.myObjects
            this.filteredObjects = this.objects;
            this.cams = window.myCams
            this.devs = window.myDevs
            // this.getUsersObjects()
            this.usersForCopy = this.users.filter( user => user.status_id !== 1 && (user.profile || {}).last_name);
        },

        filterUsers(data) {
            this.search_str = data;
            console.log(data);
            if(this.search_str.length) {
                this.users = []
                for(let i = 0; i < window.myUsers.length; i++) {
                    if (!this.showUserCard && !this.showUsersList) {
                        if (this.searchStrInUser(window.myUsers[i]) && this.userHasChildrenRole(window.myUsers[i])) {
                            this.users.push(window.myUsers[i])
                        }
                    } else if (this.searchStrInUser(window.myUsers[i])) {
                        this.users.push(window.myUsers[i])
                    }
                }
            } else {
                this.users = window.myUsers
            }
            this.users = this.users.sort( (a, b) => (this.userFio(a.profile) > this.userFio(b.profile)) ? 1 : -1);
        },
        selectingHandler(data) {
            this.selectingUsers = data;
            const availableUsers = this.users.filter( user => !(user.profile || {}).is_ai && user.status_id !== 1);
            this.selectedAll = availableUsers.length === this.selectingUsers.length;
        },
        toggleSelecting() {
            this.selecting = !this.selecting;
            this.selectingUsers = [];
            this.selectedAll = false;
        },
        searchStrInUser(user) {
            if (!user.profile) {
                return;
            }
            let str = this.search_str.toLowerCase()
            if (
                user.profile.last_name.toLowerCase().indexOf(str)!==-1 ||
                user.profile.first_name.toLowerCase().indexOf(str)!==-1 ||
                (user.profile.middle_name && user.profile.middle_name.toLowerCase().indexOf(str)!==-1 )
            ) {
                return true
            }                
            return false
        },
        selectActionHandler(data) {
            console.log('selectActionHandler', data, this.selectingUsers);
            switch(data) {
                case 'permissions': 
                    this.showPermissionModal = true;
                    this.permMode = 1;
                    this.permIds = [];
                    this.selectingUsersCards = [];
                    this.users.forEach(user => {
                        if (this.selectingUsers.includes(user.id)) {
                            this.selectingUsersCards.push(user);
                        }
                    });
                    break;
                case 'digressions': 
                    this.showDigressionModal = true;
                    this.digMode = 1;
                    this.digObj = [];
                    this.digCams = [];
                    this.digDigressions = [];
                    this.digData = [];
                    this.selectingUsersCards = [];
                    this.users.forEach(user => {
                        if (this.selectingUsers.includes(user.id)) {
                            this.selectingUsersCards.push(user);
                        }
                    });
                    this.startGetObjects();
                    break;
                default:
                    break;
            }
        },
        startGetObjects() {
            // this.digGetLoading = true;
            // this.getUsers = this.setUsers = this.selectingUsers;
            // this.getNextUser();
        },
        
        getNextUser() {
            const id = this.getUsers.pop();
            window.myAjax('GET', '/api/user-object-relations', {
                user_id: id
            }, (error, response) => {
                if(!error) {
                    console.log(response.user_objects)
                    window.myAjax('GET', '/api/user-camera-relations', {
                        user_id: id
                    }, (error2, response2) => {
                        if(!error2) {
                            console.log(response2.user_cameras)
                            window.myAjax('GET', '/api/user-digression-relations', {
                                user_id: id
                            }, (error3, response3) => {
                                if(!error3) {
                                    console.log(response3.relations)
                                    if (this.getUsers.length) {
                                        this.getNextUser();
                                    } else {
                                        this.digGetLoading = false;
                                    }
                                }
                            })
                        }
                    })
                }
            })
        },
        setBaseRoles() {
            this.baseRoles = []
            for(let i=0; i<this.allRoles.length; i++)
                if(this.allRoles[i].parent_id==null)
                    this.baseRoles.push(this.allRoles[i])
        },
        filterRoles() {
            this.childrenOfBaseRoles = []
            for(let i=0; i<this.allRoles.length; i++)
                if(this.baseRoleIdOfThe(this.allRoles[i])===this.cardUser.baseRole)
                    this.childrenOfBaseRoles.push(this.allRoles[i])
            this.cardUser.roles = []
            this.userPermissions()
        },
        baseRoleIdOfThe(role) {
            if(role.parent_id==null)
                return role.id
            else
                for(let i=0; i<this.allRoles.length; i++)
                    if(this.allRoles[i].id===role.parent_id)
                        return this.baseRoleIdOfThe(this.allRoles[i])
        },
        roleById(roleId) {
            for(let i=0; i<this.allRoles.length; i++){
                if(this.allRoles[i].id===roleId){
                    return this.allRoles[i]
                }
            }
                
        },
        getUsersObjects() {
            window.myAjax('GET', '/api/user-object-relations', {}, this.myCallbackGetUsersObjs)
        },
        myCallbackGetUsersObjs(error, response) {
            if(!error) {
                this.usersObjects = response.user_objects
                for(let j=0; j<this.users.length; j++) {
                    this.users[j].objects = []
                    for(let k=0; k < this.usersObjects.length; k++) {
                        if(this.usersObjects[k].user_id===this.users[j].id) {
                            let obj = this.getObject(this.usersObjects[k].object_id)
                            if(obj) {
                                if( obj.object_type==='zone') {
                                    let parentObj = this.getObject(obj.parent_id)
                                    this.users[j].objects.push({
                                        name: parentObj.name + '/' + obj.name
                                    })
                                } else
                                    this.users[j].objects.push({
                                        name: obj.name
                                    })
                            }
                        }
                    }
                }
                this.users.push(this.users[0])
                this.users.pop()
                this.users = this.users.sort( (a, b) => (this.userFio(a.profile) > this.userFio(b.profile)) ? 1 : -1)
            }
        },
        getObject(obj_id) {
            for(let i=0; i<window.myObjects.length; i++)
                if(window.myObjects[i].id===obj_id)
                    return window.myObjects[i]
            return null
        },

        openDevsModal(object_id) {
            this.modalDevs = []
            for(let i=0; i<window.myDevsToObjects.length; i++) {
                if(window.myDevsToObjects[i].object_id===object_id) {
                    let deg = this.getDev(window.myDevsToObjects[i].digression_id)
                    if(deg)
                        this.modalDevs.push(deg)
                }
            }
            this.modalDevs.sort((dev, devNext) => (dev.name > devNext.name) ? 1 : ((devNext.name > dev.name) ? -1 : 0));

            this.modalDevs = [...new Set(this.modalDevs)];
            this.user_object_id = this.getUserObjectLinkId(object_id);
            this.objectsRelationsTmp = Object.assign({}, JSON.parse(JSON.stringify(this.objectsRelations)));
            if (!this.objectsRelationsTmp[this.user_object_id]) {
                this.objectsRelationsTmp[this.user_object_id] = {};
            }
            if(this.modalDevs.length)
                this.modalDevsHead = 'Привязка отклонений объекта/зоны к пользователю'
            else
                this.modalDevsHead = 'К этому объекту/зоне не привязаны отклонения'

            // clear arrays
            this.devsToUserObjectsToAdd = [];
            this.devsToUserObjectsToDelete = [];
            this.allSelected = this.isAllChosen();
            this.allSelectedCreate = this.isAllSelected('create');
            this.allSelectedExecute = this.isAllSelected('execute');

            this.showModalDevs = true;
            // window.setTimeout(() => {
            //     window.$('#modalDevs').modal('show')
            // }, 100); 
        },

            openChecklistModal(object_id) {
                window.object_id = object_id;
                getAllChecklists(this.getAllChecklistsCallback);
            },

            getAllChecklistsCallback(response) {
                const lists = response.check_lists;
                this.modalChecklists = [];
                for (let id in lists) {
                    this.modalChecklists.push(lists[id]);
                    
                }
                getRelationsByUser({
                    id: this.cardUser.id,
                    params: {
                        user_id: this.cardUser.id,
                        object_id: window.object_id
                    }
                }, function(result) {
                    this.checklistsToUserObjects = result.relations;
                    /** waiting for result.relations*/
                    this.user_object_id = this.getUserObjectLinkId(window.object_id)
                    // clear arrays
                    this.devsToUserObjectsToAdd = [];
                    this.devsToUserObjectsToDelete = [];
                    this.allSelected = this.isAllChosen();
                    window.$('#modalChecklist').modal('show');
                }.bind(this));
            },

        devFullName(id, fullName) {
            let dev = this.getDev(id)
            if(dev) {
                if (dev.parent_id)
                    fullName = this.devFullName(dev.parent_id, ' / ' + dev.name + fullName)
                else
                    fullName = dev.name + fullName
            }
            return fullName
        },
        getDev(id) {
            for(let i=0; i<this.devs.length; i++)
                if(this.devs[i].id===id)
                    return this.devs[i]
            return null
        },

        closeDevsModal() {
            this.showModalDevs = false;
            this.modalDevs = [];
            // window.$('#modalDevs').modal('hide');
            this.allSelected = false;

            // clear arrays
            this.devsToUserObjectsToAdd = [];
            this.devsToUserObjectsToDelete = [];
        },
        
        closeChecklistModal() {
            window.$('#modalChecklist').modal('hide');
            this.allSelected = false;

            // clear arrays
            this.checklistsToUserObjectsToAdd = [];
            this.checklistsToUserObjectsToDelete = [];
        },

        thereIs(dev) {
            for(let i=0; i<this.devsToUserObjects.length; i++) {
                if(this.devsToUserObjects[i].user_object_id===this.user_object_id
                    && this.devsToUserObjects[i].digression_id===dev.id) {
                    return this.devsToUserObjects[i]
                }
            }
            return false
        },

        _isChosen(dev, type) {
            if (((this.objectsRelationsTmp[this.user_object_id] || {})[dev.id] || {})[type || 'create']) { 
                return true;
            }
            return false;
        },

        _toggleObjectRelationFromList(dev, type) {
            this._toggleObjectRelation(dev, type);
            this.allSelectedCreate = this.isAllSelected('create');
            this.allSelectedExecute = this.isAllSelected('execute');
        },

        _toggleObjectRelation(dev, type) {
            if (!this.objectsRelationsTmp[this.user_object_id]) {
                this.objectsRelationsTmp[this.user_object_id] = {};
            }
            if (!this.objectsRelationsTmp[this.user_object_id][dev.id]) {
                this.objectsRelationsTmp[this.user_object_id][dev.id] = {};
            }
            this.objectsRelationsTmp[this.user_object_id][dev.id][type || 'create'] = !this.objectsRelationsTmp[this.user_object_id][dev.id][type || 'create'];
            this.objectsRelationsTmp = Object.assign({}, this.objectsRelationsTmp);
        },

        isChosen(dev) {
            let toAdd = this.devsToUserObjectsToAdd.find(i => i.digression_id === dev.id);
            let toDelete = this.devsToUserObjectsToDelete.find(i => i.digression_id === dev.id);
            return (toAdd !== undefined || this.thereIs(dev)) && toDelete === undefined;
        },

        isChosenCheck(checklist) {
            let toAdd = this.checklistsToUserObjectsToAdd.find(i => i.checklist_id === checklist.id);
            let toDelete = this.checklistsToUserObjectsToDelete.find(i => i.checklist_id === checklist.id);
            return (toAdd !== undefined || this.thereIsChecklists(checklist)) && toDelete === undefined;
        },

        

        thereIsChecklists(dev) {
            for(let i=0; i<this.checklistsToUserObjects.length; i++) {
                if(this.checklistsToUserObjects[i].user_id===this.cardUser.id
                    && this.checklistsToUserObjects[i].checklist_id ===dev.id) {
                    return this.checklistsToUserObjects[i]
                }
            }
            return false
        },

            isAllSelected(type) {
                for (let i = 0; i < this.modalDevs.length; i++) {
                    let dev = this.modalDevs[i];
                    if (!this._isChosen(dev, type)) {
                        return false;
                    }
                }
                return true;
            },

        isAllChosen() {
            let allChosen = true;
            for (let i = 0; i < this.modalDevs.length; i++) {
                let dev = this.modalDevs[i];
                if (!this.isChosen(dev)) {
                    allChosen = false;
                }
            }

            return allChosen;
        },

        toggleAllSelection(type) {
            if (type === 'create') {
                this.allSelectedCreate = !this.allSelectedCreate;
                for (let i = 0; i < this.modalDevs.length; i++) {
                    let dev = this.modalDevs[i];
                    let selected = this._isChosen(dev, 'create');
                    if (
                        (this.allSelectedCreate && !selected) ||
                        (!this.allSelectedCreate && selected)
                    ) {
                        this._toggleObjectRelation(dev, 'create')
                    }
                }
            } else {
                this.allSelectedExecute = !this.allSelectedExecute;
                for (let i = 0; i < this.modalDevs.length; i++) {
                    let dev = this.modalDevs[i];
                    let selected = this._isChosen(dev, 'execute');
                    if (
                        (this.allSelectedExecute && !selected) ||
                        (!this.allSelectedExecute && selected)
                    ) {
                        this._toggleObjectRelation(dev, 'execute')
                    }
                }
            }
            console.log('objectsRelationsTmp', this.objectsRelationsTmp[this.user_object_id]);
            // this.allSelected = !this.allSelected;
            // if (this.allSelected) {
            //     this.selectAllDevs(this.modalDevs);
            // } else {
            //     this.cancelAllDevs(this.modalDevs);
            // }
        },

        selectAllDevs(devs) {
            this.devsToUserObjectsToDelete = [];
            for (let i = 0; i < devs.length; i++){
                let currentDev = devs[i];
                let devToAddIndex = this.devsToUserObjectsToAdd.findIndex(i => i.digression_id === currentDev.id);
                let devToObject = this.thereIs(currentDev);
                if (!devToObject && devToAddIndex === -1) {
                    devToObject = {
                        user_object_id: this.user_object_id,
                        digression_id: currentDev.id
                    }
                    this.devsToUserObjectsToAdd.push(devToObject);
                }
            }
        },

        cancelAllDevs(devs) {
            this.devsToUserObjectsToAdd = [];
            for (let i = 0; i < devs.length; i++){
                let currentDev = devs[i];
                let devToDeleteIndex = this.devsToUserObjectsToDelete.findIndex(i => i.digression_id === currentDev.id);
                let devToObject = this.thereIs(currentDev);
                if (devToObject && devToDeleteIndex === -1) {
                    this.devsToUserObjectsToDelete.push(devToObject);
                }
            }
        },

        checklistToUserObj(checklist) {
            // todo
            let devToAddIndex = this.checklistsToUserObjectsToAdd.findIndex(i => i.checklist_id === checklist.id);
            let devToDeleteIndex = this.checklistsToUserObjectsToDelete.findIndex(i => i.checklist_id === checklist.id);
            let devToObject = this.thereIsChecklists(checklist);
            if(devToObject){
                if (devToDeleteIndex === -1) {
                this.checklistsToUserObjectsToDelete.push(devToObject);
                }
                else {
                this.checklistsToUserObjectsToDelete.splice(devToDeleteIndex, 1);
                }
            }
            else {
                if (devToAddIndex === -1)
                {
                devToObject = {
                    user_id: this.cardUser.id,
                    checklist_id: checklist.id,
                    object_id: window.object_id
                }
                this.checklistsToUserObjectsToAdd.push(devToObject);
                }
                else {
                this.checklistsToUserObjectsToAdd.splice(devToAddIndex, 1);
                }
            }
        },

        devToUserObj(dev) {
            let devToAddIndex = this.devsToUserObjectsToAdd.findIndex(i => i.digression_id === dev.id);
            let devToDeleteIndex = this.devsToUserObjectsToDelete.findIndex(i => i.digression_id === dev.id);
            let devToObject = this.thereIs(dev);
            if(devToObject){
                if (devToDeleteIndex === -1) {
                this.devsToUserObjectsToDelete.push(devToObject);
                }
                else {
                this.devsToUserObjectsToDelete.splice(devToDeleteIndex, 1);
                }
            }
            else {
                if (devToAddIndex === -1)
                {
                devToObject = {
                    user_object_id: this.user_object_id,
                    digression_id: dev.id
                }
                this.devsToUserObjectsToAdd.push(devToObject);
                }
                else {
                this.devsToUserObjectsToAdd.splice(devToAddIndex, 1);
                }
            }

            this.allSelected = this.isAllChosen();
            this.allSelectedCreate = this.isAllSelected('create');
            this.allSelectedExecute = this.isAllSelected('execute');
        },

        saveDevsToUser() {
            
            let data = {
                "objects": [],
            };
            const toDeleteIds = [];
            const toAdd = [];
            const objectId = this.user_object_id;
            const relations = this.objectsRelationsTmp[objectId] || {};
            for (var digId in relations) {
                const relation = relations[digId] || {};
                if (relation.create && !((this.objectsRelations[objectId] || {})[digId] || {}).create) {
                    toAdd.push({
                        user_object_id: objectId,
                        digression_id: digId,
                        digression_type: 'create'
                    });
                }
                if (relation.execute && !((this.objectsRelations[objectId] || {})[digId] || {}).execute) {
                    toAdd.push({
                        user_object_id: objectId,
                        digression_id: digId,
                        digression_type: 'execute'
                    });
                }
                if ((
                    !relation.create && ((this.objectsRelations[objectId] || {})[digId] || {}).create)
                ) {
                    toDeleteIds.push({
                        objectId: objectId,
                        digId: digId,
                        type: 'create'
                    })
                }
                if (
                    (!relation.execute && ((this.objectsRelations[objectId] || {})[digId] || {}).execute)
                ) {
                    toDeleteIds.push({
                        objectId: objectId,
                        digId: digId,
                        type: 'execute'
                    })
                }
            }
            const toDelete = this.findRelationsByObjectsIds(toDeleteIds);
            
            this.objectsRelations = Object.assign({}, JSON.parse(JSON.stringify(this.objectsRelationsTmp)));

            // send devsToUserObjectsToAdd to apply changes
            for(let i = 0; i < toAdd.length; i++) {
                data.objects.push(toAdd[i]);
            }
            if (data.objects.length !== 0) {
                data.objects = JSON.stringify(data.objects);
                window.myAjax('POST', '/api/user-digression-relations', data, this.myCallbackDevToUserObj);
                data.objects = [];
            }

            // send devsToUserObjectsToDelete to apply changes
            for(let i = 0; i < toDelete.length; i++) {
                data.objects.push(toDelete[i]);
            }
            if (data.objects.length !== 0) {
                data.objects = JSON.stringify(data.objects);
                window.myAjax('DELETE', '/api/user-digression-relations', data, this.myCallbackDevToUserObj);
                data.objects = [];
            }
            // close dialog
            this.closeDevsModal();
        },

        myCallbackDevToUserObj (error, response) {
            if(error)
                window.myAlert(response.message);
            else {
                this.getUserObjDevs();
                // show that all right
                window.myAlert('Изменения сохранены');
            }
        },

        findRelationsByObjectsIds(ids) {
            const result = [];
            for (let i = 0; i < this.devsToUserObjects.length; i++) {
                const relation = this.devsToUserObjects[i];
                for (let j = 0; j < ids.length; j++) {
                    const value = ids[j];
                    if (
                        relation.user_object_id == value.objectId &&
                        relation.digression_id == value.digId &&
                        relation.digression_type == value.type
                    ) {
                        result.push(relation);
                    }
                }
            }
            return result;
        },

        saveChecklistsToUser() {
            let data = {};
            data.objects = [];
            for(let i = 0; i < this.checklistsToUserObjectsToAdd.length; i++) {
                data.objects.push(this.checklistsToUserObjectsToAdd[i]);
            }
            if (data.objects.length !== 0) {
                for (let i = 0; i < data.objects.length; i++) {
                    const object = data.objects[i];
                    const disabled = this.disabledChecklistTree[object.checklist_id];
                    data.objects[i].disabled_node_ids = [...new Set(disabled)];
                }
                data.objects = JSON.stringify(data.objects);
                createRelations(data, function() { /** todo */})
                data.objects = [];
            }

            // send devsToUserObjectsToDelete to apply changes
            for(let i = 0; i < this.checklistsToUserObjectsToDelete.length; i++) {
                data.objects.push(this.checklistsToUserObjectsToDelete[i]);
            }
            if (data.objects.length !== 0) {
                data.objects = JSON.stringify(data.objects);
                deleteRelations(data, function() { /** todo */})
                data.objects = [];
            }
            window.$('#modalChecklist').modal('hide');
            this.checklistsToUserObjectsToAdd = [];
            this.checklistsToUserObjectsToDelete = [];
        },


        getUserObjectLinkId(object_id) {
            let res = null
            for(let i=0; i<this.cardUser.objectsLinks.length; i++) {
                if(this.cardUser.objectsLinks[i].object_id===object_id) {
                    res = this.cardUser.objectsLinks[i].id
                    break
                }
            }
            return res
        },

        userFio(user_profile) {
            let res
            if(!user_profile)
                res = 'Профиль пользователя не создан'
            else if(user_profile.middle_name)
                res = user_profile.last_name + ' ' + user_profile.first_name + ' ' + user_profile.middle_name
            else
                res = user_profile.last_name + ' ' + user_profile.first_name
            return res
        },
        userStatusStr(status_id) {
            let res
            switch(status_id) {
                case 1:
                    res = 'Заблокирован'
                    break
                case 10:
                    res = 'Активен'
                    break
            }
            return res
        },
        slideBall() {
            if (!this.edited) {
                return;
            }
            window.$('.toggleBtn').toggleClass('grayFilter')
            window.$('.myGreen').toggleClass('myRed')
            if(this.cardUser.status === 1) {
                this.cardUser.status = 10
            } else {
                this.cardUser.status = 1
            }
        },
        setUserStatus() {
            window.$('.toggleBtn').toggleClass('grayFilter')
            window.$('.myGreen').toggleClass('myRed')
        },
        newChild(parent_id) {
            this.$emit('addRootObjectFromObjects', false);
            this.object = Object.assign({}, this.objectNew);
            this.object.parent_id = parent_id;
            this.object.name = '';
            this.objectParent = this.getParentRole(this.object.parent_id);
            this.object.permissions = [];
            this.rolePermissionsChecks = [];
            const obj = {};
            for(let i = 0; i<(this.objectParent.permissions || []).length; i++) {
                obj[this.objectParent.permissions[i].id] = true;
            }
            this.rolePermissionsChecks = Object.assign({}, this.rolePermissionsChecks, obj);
            this.errorName = '';
            this.errorCity = '';
            this.errorAddress = '';
            this.showCams = false;
            this.showDev = false;
            this.showUsers = false;
        },
        showParentRoleCard() {
            let parentRole = this.getParentRole(this.object.parent_id)
            if(parentRole)
                this.showObjectCard(parentRole)
        },
        showObjectCard(obj) {
            this.edited = false;
            this.showUsersList = false
            this.errorName = ''
            this.showUsers = true
            this.showUserCard = false
            this.object = obj
            if(this.object.parent_id) {
                this.objectParent = this.getParentRole(this.object.parent_id)
            }

            this.objectsChildrenId = this.getChildrenRoles(this.object.id)
            this.rolePermissionsChecks = [];
            const perms = {};
            for(let i = 0; i<(this.object.permissions || []).length; i++) {
                perms[this.object.permissions[i].id] = true;
            }
            this.rolePermissionsChecks = Object.assign({}, this.rolePermissionsChecks, perms);

            this.users = [];
            window.myUsers.forEach( user => {
                if (this.userHasChildrenRole(user)) {
                    this.users.push(user);
                }
            });
            this.selectingUsers = [];
        },
        toggleRolePermission(perm_id) {
            const obj = {};
            obj[perm_id] = !this.rolePermissionsChecks[perm_id];
            this.rolePermissionsChecks = Object.assign({}, this.rolePermissionsChecks, obj);
        },
        getParentRole(parent_role_id) {
            for(let i=0; i<window.myRoles.length; i++)
                if(window.myRoles[i].id===parent_role_id)
                    return Object.assign({}, window.myRoles[i])
            return null
        },

        updateUserEmails(i, val) {
            this.cardUser.emails[i] = val
            if(this.notEmptyArray(this.cardUser.emails)) {
                if (this.notEmptyEmails(this.cardUser.emails)) {
                    this.cardUser.errorEmails = ''
                } else {
                    this.cardUser.errorEmails = 'Проверьте корректность указанных адресов.'
                }
            } else {
                this.cardUser.errorEmails = 'Укажите хотя бы 1 контактный e-mail.'
            }
        },
        updateUserPhones(i, val) {
            this.cardUser.phones[i] = val
            if(this.notEmptyArray(this.cardUser.phones) || this.cardUser.is_ai) {
                if(this.notEmptyPhones(this.cardUser.phones)) {
                    this.cardUser.errorPhones = ''
                } else {
                    this.cardUser.errorPhones = 'Проверьте корректность указанных телефонов.'
                }
            } else {
                this.cardUser.errorPhones = 'Укажите хотя бы 1 контактный телефон'
            }
        },
        objectPermissionInParent(perm_id) {
            for(let i=0; i<this.object.permissions.length; i++)
                if(this.object.permissions[i].id===perm_id)
                    return true
            return false
        },

        userHasRole(user) {
            for (let i=0; i<user.roles.length; i++)
                if (this.object.id===user.roles[i].id)
                    return true
            return false
        },
        userHasChildrenRole(user) {
            for (let i=0; i<user.roles.length; i++) {
                if (this.objectsChildrenId.indexOf(user.roles[i])!==-1)
                    return true
            }
            return false
        },
        getChildrenRoles(role_id) {
            let childrenRolesId = [role_id]
            for(let i=0; i<this.allRoles.length; i++)
                if(this.allRoles[i].parent_id===role_id) {
                    let tempArr = this.getChildrenRoles(this.allRoles[i].id)
                    for(let j=0; j<tempArr.length; j++)
                        childrenRolesId.push(tempArr[j])
                }
            return childrenRolesId
        },
        alertUserRolePermission() {
            window.myAlert('Права делегированые ролям можно снять отвязав роль')
        },
        addAllPerms(perm){
            for (let i = 0; i < perm.ids.length; i++) {
                this.addUserDirectPermission(perm.ids[i]);
            }
        },
        removeAllPermissionStatus(perm) {
            for (let i = 0; i < perm.ids.length; i++) {
                this.removeUserDirectPermission(perm.ids[i]);
            }
        },
        addUserDirectPermission(perm_id) {
            if (this.cardUser.directPermissionsId.indexOf(perm_id) === -1) {
                this.cardUser.directPermissionsId.push(perm_id);
            }
        },
        removeUserDirectPermission(perm_id) {
            const index = this.cardUser.directPermissionsId.indexOf(perm_id);
            if (index !== -1) {
                this.cardUser.directPermissionsId.splice(index, 1);
            }
        },
        toggleUserPermission(perm_id) {
            if (!this.edited) {
                return;
            }
            const obj = {};
            obj[perm_id] = !this.permissionsChecks[perm_id];
            this.permissionsChecks = Object.assign({}, this.permissionsChecks, obj);
            this.setSelectedAllReps();
        },
        addPermission(perm) {
            this.object.permissions.push(perm)
            const data = {};
            data[perm.id] = true;
            this.allPermissionsCheck = Object.assign({}, this.allPermissionsCheck, data);
        },
        deletePermission(perm) {
            for(let i=0; i<this.object.permissions.length; i++)
                if(this.object.permissions[i].id===perm.id) {
                    this.object.permissions.splice(i, 1)
                    const data = {};
                    data[perm.id] = false;
                    this.allPermissionsCheck = Object.assign({}, this.allPermissionsCheck, data);
                    break
                }
        },
        assignPermission(perm_id, to_assign) {
            let data = {
                    role_id: this.object.id,
                    permission_id: perm_id
                },
                url = '/api/permission-role',
                method = 'DELETE';
            if(to_assign) {
                url = '/api/permission-role';
                method = 'POST';
            }

            window.myAjax(method, url, data, this.myCallbackAssignPermission)
        },
        myCallbackAssignPermission (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                this.$emit('updateRolesListFromUsers', false)
                window.setTimeout(this.updatePerm, 500)
            }
        },

        updatePerm() {
            for(let i =0; i<window.myRoles.length; i++)
                if(this.object.id===window.myRoles[i].id) {
                    this.object = window.myRoles[i]
                    break
                }
        },

        rootRightProtected() {
            window.myAlert('Права корневых ролей может изменять только супер-администратор')
        },

        saveUser() {
            if (this.cardUser.id)
                window.myConfirm('Внимание!', 'Вы уверены, что хотите сохранить изменения пользователя?', this.saveUser2)
            else
                this.saveUser2()
        },

        saveUser2() {
            if(this.validateUser()) {
                let data = Object.assign({}, this.cardUser),
                    url = '/api/user',
                    method = 'POST';
                if (this.cardUser.id) {
                    url = '/api/user'
                    method = 'PUT'
                } else {
                    data = Object.assign({}, this.cardUser, {login: this.cardUser.email})
                }
                data.directPermissionsId = [];
                for (let index in this.permissionsChecks) {
                    if (this.permissionsChecks[index]) {
                        data.directPermissionsId.push(index);
                    }
                }
                const emails = [];
                (data.emails || []).forEach((email) => {
                    if (window.isEmail(email)) {
                        emails.push(email);
                    }
                });
                data.emails = JSON.stringify(emails)
                data.phones = JSON.stringify(data.phones)
                data.roles = JSON.stringify(data.roles)
                data.permissionsId = JSON.stringify(data.permissionsId)
                data.directPermissionsId = JSON.stringify(data.directPermissionsId)
                data.objectsId = JSON.stringify(data.objectsId)
                data.objectsLinks = JSON.stringify(data.objectsLinks)
                data.camsId = JSON.stringify(data.camsId)
                data.camsLinks = JSON.stringify(data.camsLinks)
                if (this.copyMode) {
                    window.myAjax(method, url, data, (error, response) => {
                        if (!error) {
                            this.cardUser.id = response.user.id;
                            this.saveCopyObjects();
                        }
                    })
                } else {
                    window.myAjax(method, url, data, this.myCallbackSaveUser)
                }
            } else {
                window.$('.scrolling_content').animate({
                    scrollTop: 0
                }, 1000);
            }
                
        },
        myCallbackSaveUser (error, response) {
            if(error) {
                window.myAlert(response.message || 'Произошла ошибка. Проверьте правильность данных и повторите попытку.')
            } else {
                if(!this.cardUser.id)
                    this.cardUser.id = response.user.id
                window.myAlert(response.message)
                this.updateUsers()
            }
        },

        copyUserCard() {

        },

        deleteUser() {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить пользователя?', this.deleteUser2)
        },
        deleteUser2() {
            window.myAjax('DELETE', '/api/user', {id: this.cardUser.id}, this.myCallbackDeleteUser)
        },
        myCallbackDeleteUser (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.myAlert(response.message)
                this.updateUsers()
                this.allUsersList()

            }
        },

        getObjectAddress(obj) {
            return obj.city + ', ' + obj.address
        },
        userObjectsHas(obj_id) {
            return this.cardUser.objectsId.indexOf(obj_id) !== -1;
        },
        toggleObjToUser(obj_id) {
            if (!this.edited) {
                return;
            }
            if (this.copyMode) {
                if (this.userObjectsHas(obj_id)) {
                    this.cardUser.objectsId.splice(this.cardUser.objectsId.indexOf(obj_id), 1)
                } else {
                    this.cardUser.objectsId.push(obj_id);
                }
                return;
            }
            if (this.userObjectsHas(obj_id)) {
                this.deleteObjFromUser(obj_id);
            }
            else {
                this.addObjToUser(obj_id);
            }
        },
        addObjToUser(obj_id) {
            window.myAjax('POST', '/api/user-object-relation', {
                object_id: obj_id,
                user_id: this.cardUser.id
            }, this.myCallbackObjectToUser)
        },
        myCallbackObjectToUser(error, response) {
            if(!error) {
                let newRelation = {
                    object_id: parseInt(response.relation.object_id),
                    user_id: parseInt(response.relation.user_id),
                    id: parseInt(response.relation.id)
                }
                this.cardUser.objectsLinks.push(newRelation)
                this.cardUser.objectsId.push(newRelation.object_id)
            }
        },
        deleteObjFromUser(obj_id) {
            let objToUserLink = this.getObjToUserLink(obj_id)
            if(objToUserLink) {
                window.myAjax('DELETE', '/api/user-object-relation', objToUserLink, this.myCallbackDeleteObjectFromUser)
            }
        },

        toggleChecklistVisible(checklist) {
            const index = this.visibleChecklists.indexOf(checklist.id);
            if (index >= 0) {
                this.visibleChecklists.splice(index, 1);
            } else {
                this.visibleChecklists.push(checklist.id);
                if (this.checklistTree[checklist.id]) {
                    return;
                }
                window.myAjax('GET', '/api/checklist', {
                    checklist_id:  checklist.id
                }, (error, response) => {
                    if (!error) {
                        const obj = {};
                        obj[checklist.id] = response.check_list;
                        this.disabledChecklistTree[checklist.id] = [];
                        this.checklistTree = Object.assign({}, this.checklistTree, obj);
                    }
                })
            }
        },

        toggleChecklistPoint(checklistId, pointId) {
            const idsArray = Object.assign({}, this.disabledChecklistTree)[checklistId];
            const index = idsArray.indexOf(pointId);
            if (index >= 0) {
                idsArray.splice(index, 1);
            } else {
                idsArray.push(pointId);
            }
            const obj = {};
            obj[checklistId] = idsArray;
            this.disabledChecklistTree = Object.assign({}, this.disabledChecklistTree, obj);
        },

        myCallbackDeleteObjectFromUser(error, response) {
            if(!error) {
                let relation = this.getUserObjectRelation(response.relation_id)
                if(relation) {
                    this.cardUser.objectsId.splice(this.cardUser.objectsId.indexOf(relation.object_id), 1)
                    this.cardUser.objectsLinks.splice(this.cardUser.objectsLinks.indexOf(relation), 1)
                }
            }
        },
        getUserObjectRelation(relation_id) {
            relation_id = Number(relation_id);
            for(let i=0; i<this.cardUser.objectsLinks.length; i++) {
                if (this.cardUser.objectsLinks[i].id === relation_id)
                    return this.cardUser.objectsLinks[i]
            }
            return null
        },
        getObjToUserLink(obj_id) {
            for(let i=0; i<this.cardUser.objectsLinks.length; i++)
                if(this.cardUser.objectsLinks[i].object_id===obj_id)
                    return this.cardUser.objectsLinks[i]
            return null
        },

        getUserObjDevs() {
            this.visibleChecklists = [];
            this.disabledChecklistTree = [];
            window.myAjax('GET', '/api/user-digression-relations', {
                user_id: this.cardUser.id
            }, this.myCallbackGetUserObjDevs);
        },
        myCallbackGetUserObjDevs(error, response) {
            if(!error) {
                this.objectsRelations = {};
                this.devsToUserObjects = response.relations;
                for (let i = 0; i < this.devsToUserObjects.length; i++) {
                    const relation = this.devsToUserObjects[i];
                    const obj = {};
                    obj[relation.checklist_id] = relation.disabled_node_ids || [];
                    this.disabledChecklistTree = Object.assign({}, this.disabledChecklistTree,obj);
                    if (!this.objectsRelations[relation.user_object_id]) {
                        this.objectsRelations[relation.user_object_id] = {};
                    }
                    if (!this.objectsRelations[relation.user_object_id][relation.digression_id]) {
                        this.objectsRelations[relation.user_object_id][relation.digression_id] = {};
                    }
                    if (relation.digression_type === 'create') {
                        this.objectsRelations[relation.user_object_id][relation.digression_id].create = true;
                    } else if (relation.digression_type === 'execute') {
                        this.objectsRelations[relation.user_object_id][relation.digression_id].execute = true;
                    }
                }
            }
        },
        getUserObjs() {
            window.myAjax('GET', '/api/user-object-relations', {
                user_id: this.cardUser.id
            }, this.myCallbackGetUserObjs)
        },
        myCallbackGetUserObjs(error, response) {
            if(!error) {
                this.cardUser.objectsLinks = response.user_objects;
                this.cardUser.objectsId = [];
                for (let i=0; i<this.cardUser.objectsLinks.length; i++) {
                    this.cardUser.objectsId.push(this.cardUser.objectsLinks[i].object_id)
                }
            }
        },
        getUserCams() {
            window.myAjax('GET', '/api/user-camera-relations', {
                user_id: this.cardUser.id
            }, this.myCallbackGetUserCams)
        },
        myCallbackGetUserCams(error, response) {
            if(!error) {
                this.cardUser.camsLinks = response.user_cameras
                this.cardUser.camsId = []
                for(let i=0; i<this.cardUser.camsLinks.length; i++)
                    this.cardUser.camsId.push(this.cardUser.camsLinks[i].camera_id)
            }
        },

        toggleCamToUser(cam_id, zoneId) {
            if (!this.edited) {
                return;
            }
            
            if (this.copyMode) {
                if (this.userCamsHas(cam_id)) {
                    this.cardUser.camsId.splice(this.cardUser.camsId.indexOf(cam_id), 1)
                } else {
                    this.cardUser.camsId.push(cam_id);
                }
                return;
            }
            if (this.userCamsHas(cam_id)) {
                this.deleteCamFromUser(cam_id);
            } else {
                if (zoneId) {
                    if (!this.userObjectsHas(zoneId)) {
                        this.addObjToUser(zoneId);
                    }
                }
                this.addCamToUser(cam_id);
            }
        },
        userCamsHas(cam_id) {
            return this.cardUser.camsId.indexOf(cam_id) !== -1;
        },
        addCamToUser(cam_id) {
            let url = '/api/user-camera-relation',
                method = 'POST',
                data = {
                    camera_id: cam_id,
                    user_id: this.cardUser.id
                }
            window.myAjax(method, url, data, this.myCallbackCreateCamToUser)
        },
        myCallbackCreateCamToUser(error, response) {
            if(!error) {
                let newRelation = {
                    camera_id: parseInt(response.relation.camera_id),
                    user_id: parseInt(response.relation.user_id),
                    id: parseInt(response.relation.id)
                }
                this.cardUser.camsLinks.push(newRelation)
                this.cardUser.camsId.push(newRelation.camera_id)
            }
        },
        deleteCamFromUser(cam_id) {
            let camToUserLink = this.getCamToUserLink(cam_id)
            if(camToUserLink) {
                let url = '/api/user-camera-relation',
                    method = 'DELETE';
                window.myAjax(method, url, camToUserLink, this.myCallbackDeleteCamFromUser)
            }
        },
        myCallbackDeleteCamFromUser(error, response) {
            if(!error) {
                let relation = this.getUserCamRelation(response.relation_id)
                if(relation) {
                    const camLinkIndex = this.cardUser.camsLinks.indexOf(relation);
                    if (camLinkIndex >= 0) {
                        const camId = this.cardUser.camsLinks[camLinkIndex].camera_id;
                        const camIdIndex = this.cardUser.camsId.indexOf(camId);
                            this.cardUser.camsId.splice(camIdIndex, 1);
                            this.cardUser.camsLinks.splice(camLinkIndex, 1);
                    }
                }
            }
        },
        getUserCamRelation(relation_id) {
            relation_id = Number(relation_id)
            for(let i=0; i<this.cardUser.camsLinks.length; i++)
                if(this.cardUser.camsLinks[i].id===relation_id)
                    return this.cardUser.camsLinks[i]
            return null
        },
        getCamToUserLink(cam_id) {
            for(let i=0; i<this.cardUser.camsLinks.length; i++)
                if(this.cardUser.camsLinks[i].camera_id===cam_id)
                    return this.cardUser.camsLinks[i]
            return null
        },


        updateUsers() {
            window.myAjax('GET', '/api/users', {}, this.myCallbackGetStartU)
        },
        myCallbackGetStartU (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.myUsers = response.users
                this.startData()
            }
        },

        saveObject() {
            if (this.object.id)
                window.myConfirm('Внимание!', 'Вы уверены, что хотите сохранить изменения роли?', this.saveObject2)
            else
                this.saveObject2()
        },
        saveObject2() {
            if(this.validateObject()) {
                const data = Object.assign({}, this.object);
                let url = '/api/role',
                    method = 'POST';
                if (data.id) {
                    url = '/api/role'
                    method = 'PUT'
                } else {
                    data.id = null
                }
                data.permissions_id = []
                for (const index in this.rolePermissionsChecks) {
                    if (index !== null && index !== undefined) {
                        data.permissions_id.push(parseInt(index))
                    }
                }
                data.permissions = null;
                data.permissions_id = JSON.stringify(data.permissions_id);
                window.myAjax(method, url, data, this.myCallbackSaveObject)
            }
        },
        myCallbackSaveObject (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                if(!this.object.id)
                    this.object.id = response.role.id
                window.myAlert(response.message)
                this.$emit('updateRolesListFromUsers', false)
                window.setTimeout(this.updateThisRoles, 1500)
            }
        },

        deleteObject() {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить роль?', this.deleteObject2)
        },
        deleteObject2() {
            let url = '/api/role',
                method = 'DELETE';
            const data = {
                id: this.object.id
            }
            window.myAjax(method, url, data, this.myCallbackDeleteObject)
        },
        myCallbackDeleteObject (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                window.myAlert(response.message)
                this.$emit('updateRolesListFromUsers', false)
                this.$emit('addRootObjectFromObjects', false)
                window.setTimeout(this.updateThisRoles, 1500)
            }
        },

        updateThisRoles() {
            this.allRoles = window.myRoles
        },

        usersList() {
            this.showUsers=!this.showUsers
            if(this.showUsers)
                this.usersClass = 'active'
            else
                this.usersClass = ''
        },
        toggleTelegram() {
            if (!this.edited) {
                return;
            }
            this.telegram = !this.telegram;
        },
        allUsersList() {
            this.edited = false;
            this.showUsersList = true;
            this.showUserCard = false;
            this.permissionsChecks = {};
            this.showObjects = false;
            this.showPermissions = false;
            this.showNotify = false;
            this.telegram = false;
            this.users = window.myUsers;
            this.search_str = '';
            this.selecting = false;
            this.selectedAll = false;
            this.selectingUsers = [];
            
            window.$('.ierarhia p').removeClass('active');
        },

        selectingAllHandler() {
            if (this.selecting) {
                this.selectedAll = !this.selectedAll;
            }
            this.selectingUsers = [];
            if (this.selectedAll) {
                this.users.forEach( user => {
                    if (!(user.profile || {}).is_ai && user.status_id !== 1) {
                        this.selectingUsers.push(user.id)
                    }
                })
            }
        },

        validateObject() {
            let res = true;
            if(this.object.name.length) {
                this.errorName = ''
            } else {
                res = false
                this.errorName = 'Не указано название'
            }

            return res
        },
        validateUser() {
            let res = true;
            if(this.cardUser.first_name.length) {
                this.cardUser.errorFirstName = ''
            } else {
                res = false
                this.cardUser.errorFirstName = 'Не указано имя'
            }
            if(this.cardUser.last_name.length) {
                this.cardUser.errorLastName = ''
            } else {
                res = false
                this.cardUser.errorLastName = 'Не указана фамилия'
            }
            if (this.showLogin) {
                if (window.isEmail(this.cardUser.email)) {
                    this.cardUser.errorLogin = ''
                } else {
                    res = false
                    this.cardUser.errorLogin = 'Некорректный логин-email'
                }
            }
            if(this.cardUser.baseRole===0) {
                res = false
                this.cardUser.errorBaseRoles = 'Выберите базовую роль пользователя'
            } else {
                this.cardUser.errorBaseRoles = ''
            }
            if(this.cardUser.roles.length) {
                this.cardUser.errorRoles = ''
            } else {
                res = false
                this.cardUser.errorRoles = 'Присвойте хотя бы одну роль'
            }

            if(this.notEmptyArray(this.cardUser.emails)) {
                if(this.notEmptyEmails(this.cardUser.emails)) {
                    this.cardUser.errorEmails = ''
                } else {
                    res = false
                    this.cardUser.errorEmails = 'Проверьте корректность указанных адресов.'
                }
            } else {
                res = false
                this.cardUser.errorEmails = 'Укажите хотя бы 1 контактный e-mail.'
            }
            if(this.notEmptyArray(this.cardUser.phones) || this.cardUser.is_ai) {
                if(this.notEmptyPhones(this.cardUser.phones)) {
                    this.cardUser.errorPhones = ''
                } else {
                    res = false
                    this.cardUser.errorPhones = 'Проверьте корректность указанных телефонов.'
                }
            } else {
                res = false
                this.cardUser.errorPhones = 'Укажите хотя бы 1 контактный телефон'
            }

            return res
        },
        notEmptyArray(arr) {
            let res = false
            for(let i=0; i<arr.length; i++)
                if(arr[i].length) {
                    res = true
                    break
                }
            return res
        },
        notEmptyEmails(arr) {
            let res = true
            for (let i = 0; i < arr.length; i++) {
                if (!window.isEmail(arr[i])) {
                    res = false
                    break
                }
            }
            return res
        },
        notEmptyPhones(arr) {
            let res = true
            for (let i = 0; i < arr.length; i++) {
                if (!window.phoneRegular.test(arr[i])) {
                    res = false;
                    break;
                }
            }
                
            return res
        },

        addContactEmail () {
            if (!this.edited) {
                return;
            }
            this.cardUser.emails.push('')
        },
        addContactPhone () {
            if (!this.edited) {
                return;
            }
            this.cardUser.phones.push('')
        },
        deleteContactEmail (index) {
            if (!this.edited) {
                return;
            }
            this.cardUser.emails.splice(index, 1)
        },
        deleteContactPhone (index) {
            if (!this.edited) {
                return;
            }
            this.cardUser.phones.splice(index, 1)
        },
        userCard(user) {
            this.filterStatus = '0';
            this.cardBaseRole = {};
            this.showLogin = true;
            this.copyMode = false;
            this.permissionsChecks = {};
            this.showObjects = false;
            this.showPermissions = false;
            this.cardUser = Object.assign({},this.newUser)
            this.cardUser.roles = [];
            this.cardUser.emails = [];
            this.cardUser.phones = [];
            this.cardUser.directPermissionsId = [];
            this.cardUser.objectsId = [];
            this.cardUser.objectsLinks = [];
            this.cardUser.camsId = [];
            this.cardUser.camsLinks = [];
            if (user==null) {
                this.cardUser.emails.push('');
                this.cardUser.phones.push('');
                this.edited = true;
            } else {
                this.showLogin = false;
                this.cardUser.baseRole = this.baseRoleIdOfThe((window.myRoles || []).find( r => r.id == user.roles[0]));
                this.cardBaseRole = this.cardUser.baseRole;
                this.filterRoles();
                this.cardUser.id = user.id;
                this.cardUser.login = user.email;
                this.cardUser.email = user.email;
                this.cardUser.status = user.status_id;
                for (let i=0; i<user.roles.length; i++) {
                    this.cardUser.roles.push(user.roles[i]);
                }
                for (let i=0; i < user.permissions.length; i++) {
                    this.cardUser.directPermissionsId.push(user.permissions[i]);
                }
                if (user.profile !== undefined) {
                    this.cardUser.first_name = user.profile.first_name;
                    this.cardUser.last_name = user.profile.last_name;
                    this.cardUser.middle_name = user.profile.middle_name;
                    this.cardUser.dolg = user.profile.position;
                    this.cardUser.comment = user.profile.comments;
                    this.cardUser.is_ai = user.profile.is_ai;
                    for (let i=0; i<user.profile.emails.length; i++) {
                        if (user.profile.emails[i]) {
                            this.cardUser.emails.push(user.profile.emails[i].email);
                        }
                    }
                    for (let i=0; i<user.profile.phones.length; i++) {
                        if (user.profile.phones[i]) {
                            this.cardUser.phones.push(user.profile.phones[i].phone);
                        }
                    }
                    this.getUserObjs();
                    this.getUserObjDevs();
                    this.getUserCams();
                    this.getUserChecklists();
                    if (this.cardUser.status === 1) {
                        window.setTimeout(this.setUserStatus, 600);
                    }
                }
            }
            
            this.setUserPermissions();
            this.showUserCard = true;
            this.showUsersList = false;

            window.$('.scrolling_content').animate({
                scrollTop: 0
            }, 10);
        },
        setUserPermissions() {
            this.permissionsChecks = {};
            this.permissionsBlock = {};
            const obj = {};
            const blocks = {};
            const role = this.roleById(this.cardUser.roles[0]);
            for (let i = 0; i < this.cardUser.directPermissionsId.length; i++) {
                obj[this.cardUser.directPermissionsId[i]] = true;
            }
            if (role) {
                for (let i = 0; i < role.permissions.length; i++) {
                    blocks[role.permissions[i].id] = true;
                }
            }
            this.permissionsBlock = Object.assign({}, this.permissionsBlock, blocks);
            this.permissionsChecks = Object.assign({}, this.permissionsChecks, obj);
            this.setSelectedAllReps();
        },
        toggleShowNotify() {
            this.showNotify = !this.showNotify;
        },
        getUserChecklists() {
            // todo
        },
        toggleUserRole(role_id) {
            if (!this.edited) {
                return;
            }
            if (this.cardUser.roles.indexOf(role_id) === -1) {
                this.cardUser.roles = [];
                this.cardUser.roles.push(role_id)
            } else {
                this.cardUser.roles.splice(this.cardUser.roles.indexOf(role_id), 1)
            }
            
            if (this.cardUser.roles[0]) {
                const role = this.roleById(this.cardUser.roles[0]);
                this.cardUser.baseRole = this.baseRoleIdOfThe(role);
                this.cardBaseRole = this.cardUser.baseRole;
                this.setDirectPermissions();
            }
            if (!this.cardUser.id) {
                this.userPermissions()
            }
        },
        setDirectPermissions() {
            if (!rolesPermissions[this.cardBaseRole]) {
                return;
            }
            for (let key in rolesPermissions[this.cardBaseRole].ids) {
                const rolesPerm = rolesPermissions[this.cardBaseRole].ids[key];
                for (let i = 0; i < globalPermissions.length; i++) {
                    for (let j = 0; j < globalPermissions[i].subs.length; j++) {
                        const perm = globalPermissions[i].subs[j];
                        if (rolesPerm === perm.id) {
                            this.addAllPerms(perm);
                            // const obj = {};
                            // obj[perm.id] = true;
                            // console.log(obj);
                            // this.permissionsChecks = Object.assign({}, this.permissionsChecks, obj);
                        }
                    }
                }
            }
        },
        userPermissions() {
            this.cardUser.directPermissionsId = []
            for(let i=0; i<window.myRoles.length; i++) {
                if(this.cardUser.roles.indexOf(window.myRoles[i].id)!==-1) {
                    for(let j=0; j<window.myRoles[i].permissions.length; j++) {
                        if(this.cardUser.directPermissionsId.indexOf(window.myRoles[i].permissions[j].id))
                            this.cardUser.directPermissionsId.push(window.myRoles[i].permissions[j].id)
                    }
                }
            }
            this.setUserPermissions();
        },
        camToObject(cam) {
            switch(this.object.object_type) {
                case 'object':
                    if(cam.object_id==null)
                        cam.object_id = this.object.id
                    else {
                        cam.object_id = null
                        cam.zone_id = null
                    }
                    break
                case 'zone':
                    if(cam.zone_id==null)
                        cam.zone_id = this.object.id
                    else {
                        cam.zone_id = null
                    }
            }
            let url = '/api/camera',
                method = 'PUT';
            window.myAjax(method, url, cam, this.myCallbackEmpty)
        },
        myCallbackEmpty (error, response) {
            if(error) {
                window.myAlert(response.message)
            }
        },
        setPermsIds(user) {
            this.permIds = [];
            for (let i=0; i < user.permissions.length; i++) {
                this.permIds.push(user.permissions[i].id);
            }
        },
        setSelectedAllReps() {
            this.selectedAllReps = [];
            for (let i=0; i < this.permissions.length; i++) {
                let result = true;
                for (let j=0; j < this.permissions[i].subs.length; j++) {
                    if (!this.permissionsChecks[this.permissions[i].subs[j].id]) {
                        result = false;
                        break;
                    }
                }
                if (result) {
                    this.selectedAllReps.push(this.permissions[i].id);
                }
            }
        },
        toggleAllReps(permId) {
            if (!this.edited) {
                return;
            }
            const selectedAll = this.selectedAllReps.includes(permId);
            const permissionGroup = this.permissions.find(p => p.id === permId);
            if (selectedAll) {
                (permissionGroup.subs || []).forEach(sub => {
                    if (this.permissionsChecks[sub.id]) {
                        this.toggleUserPermission(sub.id);
                    }                
                });
                // this.selectedAllReps.splice(this.selectedAllReps.indexOf(permId), 1);
            } else {
                (permissionGroup.subs || []).forEach(sub => {
                    if (!this.permissionsChecks[sub.id]) {
                        this.toggleUserPermission(sub.id);
                    }
                });
                // this.selectedAllReps.push(permId);
            }
        },
    }
}
