import Checkbox from './../../../base/checkbox';
import { PERIODS, CRON_DAYS, STEPS, TIMES, WEEKDAYS } from './../../../neural/utils'; 
import ArchiveList from './../archiveList'


const CRON_DAYS2 = {
    1: 'mon',
    2: 'tue',
    3: 'wed',
    4: 'thu',
    5: 'fri',
    6: 'sat',
    0: 'sun'
}

export default {
    name: 'ArchiveSettings',
    components: {
        Checkbox,
        ArchiveList
    },
    props: {
        camera: {
            type: Object,
            default: {}
        },
        type: {
            type: String,
            default: 'archive'
        },
        positions: {
            type: Array,
            default: []
        },
        options: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        steps: [],
        times: [],
        weekdays: [],

        selected: {
            step: null,
            startDate: null,
            endDate: null,
            startTime: '08:00',
            endTime: '21:00',
            auto_renewal: false,
            position_ids: [],
            days: {}
        },
        
        days: {
            'mon': false,
            'tue': false,
            'wed': false,
            'thu': false,
            'fri': false,
            'sat': false,
            'sun': false,
        },

        running: [],
        runningToAdd: [],
    }),
    mounted() {
        this.steps = STEPS;
        this.times = TIMES;
        this.weekdays = WEEKDAYS;

        
        this.setSelected();

        if (this.options) {
            this.running = [];

                    for (let key in this.options) {
                        const smart = {};
                        const run = this.options[key];
                        if (run.status === 'stopped') {
                            continue
                        }
                        smart.id = run.record_id;
                        smart.auto_renewal = run.auto_renewal;
                        const d = new Date();
                        let diff = d.getTimezoneOffset();
                        const start = new Date(run.start.replace(/-/g, '/'));
                        start.setTime(start.getTime() + (-diff*60*1000));
                        const stop = new Date(run.stop.replace(/-/g, '/'));
                        stop.setTime(stop.getTime() + (-diff*60*1000));
                        smart.startDate = new Date(start).toISOString().substring(0,10);
                        smart.endDate = new Date(stop).toISOString().substring(0,10);
                        if (this.type === 'smart_archive') {
                            const steps = this.getSchedulePeriod(run.schedule);
                            smart.step = this.steps.find( (s) => s.caption === steps);
                        } else {
                            smart.step = this.steps[5];
                        }
                        const hours = this.getScheduleHours(run.schedule, run.start, run.stop);
                        smart.days = this.getScheduleDays(run.schedule);
                        smart.startTime = hours[0] + ':' + hours[2];
                        smart.endTime = hours[1] + ':' + hours[3];
                        this.running.push(smart);
                    }
        } else {
            this.getRecordsTasks();
        }
    },
    methods: {
        setSelected() {
            var now = new Date();
            now.setMinutes(now.getMinutes() - now.getTimezoneOffset() + 10);
            this.selected.startDate = now.toISOString().slice(0,10);
            now.setMinutes(now.getMinutes() + 60);
            this.selected.endDate = now.toISOString().slice(0,10);

            if (this.camera.smart_archive_schedule) {
                const defaultSetting = {};
                const steps = this.getSchedulePeriod(this.camera.smart_archive_schedule);
                // const step = this.steps.find( (s) => s.caption === steps);
                defaultSetting.step = this.steps.find( (s) => s.caption === steps);

                const hours = this.getScheduleHours(this.camera.smart_archive_schedule);
                
                defaultSetting.startTime = (hours[0].toString().length === 1 ? ('0' + hours[0].toString()) : hours[0].toString()) + ':00';
                defaultSetting.endTime = (hours[1].toString().length === 1 ? ('0' + hours[1].toString()) : hours[1].toString()) + ':00';
                defaultSetting.days = this.getDefaultDays(this.camera.smart_archive_schedule);

                this.selected = Object.assign({}, this.selected, defaultSetting);
            } else {
                this.selected.auto_renewal = false;
                this.selected.startTime = '08:00';
                this.selected.endTime = '21:00';
                this.selected.step = this.steps[5];
            }
        },
        getRecordsTasks() {
            this.loading = true;
            const url = this.type === 'archive' ? '/archive/running' : '/smart_archive/running';
            window.myAjax('GET', url, {
                camera_id: this.camera.id
            }, (error, response) => {
                if (!error) {
                    this.running = [];

                    for (let key in response) {
                        const smart = {};
                        const run = response[key];
                        if (run.status === 'stopped') {
                            continue
                        }
                        smart.id = run.record_id;
                        smart.auto_renewal = run.auto_renewal;
                        const d = new Date();
                        let diff = d.getTimezoneOffset();
                        const start = new Date(run.start.replace(/-/g, '/'));
                        start.setTime(start.getTime() + (-diff*60*1000));
                        const stop = new Date(run.stop.replace(/-/g, '/'));
                        stop.setTime(stop.getTime() + (-diff*60*1000));
                        smart.startDate = new Date(start).toISOString().substring(0,10);
                        smart.endDate = new Date(stop).toISOString().substring(0,10);
                        if (this.type === 'smart_archive') {
                            const steps = this.getSchedulePeriod(run.schedule);
                            smart.step = this.steps.find( (s) => s.caption === steps);
                        } else {
                            smart.step = this.steps[5];
                        }
                        const hours = this.getScheduleHours(run.schedule, run.start, run.stop);
                        smart.days = this.getScheduleDays(run.schedule);
                        smart.startTime = hours[0] + ':' + hours[2];
                        smart.endTime = hours[1] + ':' + hours[3];
                        this.running.push(smart);
                    }
                }
            })
        },
        addTask() {
            const days = {}
            let alldays = true;
            for (const [key, value] of Object.entries(this.selected.days)) {
                if (value === true) {
                    console.log(key)
                    alldays = false;
                    break;
                }
            }
            for (const [key, value] of Object.entries(this.selected.days)) {
                if (value || alldays) {
                    days[key] = true;
                }
            }
            const newSmart = Object.assign({}, this.selected, {
                ui_days: days,
                id: this.uuidv4()
            });
            this.runningToAdd.push(newSmart);
        },
        removeTask(task, revert) {
            const index = this.running.findIndex( b => b.id == task.id);
            if (index < 0 ) {
                return;
            }
            this.running.splice(index, 1);
            const deleted = Object.assign({}, task, {
                ui_status: revert ? null : 'deleted'
            });
            this.running.splice(index, 0, deleted)
            // todo
        },
        removeToAddTask(task, revert) {
            const index = this.runningToAdd.findIndex( b => b.id == task.id);
            if (index < 0 ) {
                return;
            }
            this.runningToAdd.splice(index, 1);
            const deleted = Object.assign({}, task, {
                ui_status: revert ? null : 'deleted'
            });
            this.runningToAdd.splice(index, 0, deleted)
        },
        uuidv4() {
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
              (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        },
        setDay(dayKey) {
            if (dayKey === 'weekdays') {
                this.selected.days['mon'] = this.selected.days['tue'] = this.selected.days['wed'] = this.selected.days['thu'] = this.selected.days['fri'] = true;
                this.selected.days['sat'] = this.selected.days['sun'] = false;
            } else if (dayKey === 'all') {
                for (const [id] of Object.entries(WEEKDAYS)) {
                    this.selected.days[id] = true;
                }
            } else {
                const keyDays = {};
                keyDays[dayKey] = !this.selected.days[dayKey];
                this.selected.days = Object.assign({}, this.selected.days, keyDays);
            }
        },
        autoRenewalClick(value) {
            this.selected.auto_renewal = value;
        },

        getScheduleHours(schedule, start, stop) {
            let result = [];
            const d = new Date();
            let diff = d.getTimezoneOffset()/ -60;
            if (start) {
                const startTime = new Date(start);
                startTime.setHours(startTime.getHours() + diff);
                const lastTime = new Date(stop);
                lastTime.setHours(lastTime.getHours() + diff);
                result.push(this.getFormatValue(startTime.getHours()));
                result.push(this.getFormatValue(lastTime.getHours()));
                result.push(this.getFormatValue(startTime.getMinutes()));
                result.push(this.getFormatValue(lastTime.getMinutes()));
            } else {
                
                let periods = schedule.split(/\s/);
                let hours = periods[1].split(/\\/);
                let hoursValues = hours[0].split(',');
                const startTime = parseInt(hoursValues[0]) + diff;
                const lastTime = parseInt(hoursValues[hoursValues.length - 1]) + diff;
                if (startTime < 0) {
                    result.push(startTime + 24);
                } else if (startTime > 23) {
                    result.push(startTime - 24);
                } else {
                    result.push(startTime);
                }
                if (lastTime < 0) {
                    result.push(lastTime + 24);
                } else if (lastTime > 23) {
                    result.push(lastTime - 24);
                } else {
                    result.push(lastTime);
                }
                if (start && stop) {
                    const minutesStart = ("0" + new Date(start).getMinutes()).slice(-2);
                    const minutesStop = ("0" + new Date(stop).getMinutes()).slice(-2);
                    result.push(minutesStart);
                    result.push(minutesStop);
                } else {
                    result.push('00');
                    result.push('00');
                }
            }return result;
        },
        getDefaultDays(schedule) {
            let periods = schedule.split(/\s/);
            if (periods[4] === '*') {
                const weekDays = {};
                for (let key in CRON_DAYS) {
                    weekDays[key] = true;
                }
                return weekDays;
            }
            let days = periods[4].split(',');
            const weekDays = {};
            days.forEach(dayKey => {
                weekDays[CRON_DAYS2[dayKey]] = true;
            });
            return weekDays;
        },
        getScheduleDays(schedule) {
            let periods = schedule.split(/\s/);
            if (periods[4] === '*') {
                const weekDays = {};
                for (let key in CRON_DAYS2) {
                    weekDays[key] = true;
                }
                return weekDays;
            }
            let days = periods[4].split(',');
            const weekDays = {};
            days.forEach(dayKey => {
                weekDays[CRON_DAYS2[dayKey]] = true;
            });
            return weekDays;
        },
        getSchedulePeriod(schedule) {
            const periods = schedule.split(/\s/);
            let key = '';
            if (periods[0].indexOf('/') > -1) {
                key = 'm' + periods[0].split(/\//)[1];
            } else if (periods[1].indexOf('/') > -1) {
                key = 'h' + periods[1].split(/\//)[1];
            } else if ((periods[5] || '').indexOf('/') > -1) {
                key = 's' + periods[5].split(/\//)[1];
            }
            return PERIODS[key];
        },

        save() {
            this.runningToAdd.forEach( task => {
                console.log('task save', task);
                let startTime = this.convertDateToUTC(new Date(task.startDate + 'T' + task.startTime));
                let endTime = this.convertDateToUTC(new Date(task.endDate + 'T' + task.endTime));
                if ( endTime.getTime() < startTime.getTime() ) {
                    window.myAlert('Неверные даты.');
                    return;
                }
                if (
                    new Date(task.startDate + 'T' + task.endTime).getTime() <=
                    new Date(task.startDate + 'T' + task.startTime).getTime()
                ) {
                    window.myAlert('Неверное время мониторинга.');
                    return;
                }
                let data = Object.assign({
                    start_datetime: this.getFormat(startTime),
                    stop_datetime: this.getFormat(endTime),
                    auto_renewal: task.auto_renewal,
                    schedule: this.createSchedule({
                        step: task.step,
                        startDate: task.startDate,
                        endDate: task.endDate,
                        startTime: task.startTime,
                        endTime: task.endTime,
                        auto_renewal: task.auto_renewal,
                        days: task.days
                    }),
                    camera_id: this.camera.id
                }, {});
                if ( (this.type === 'archive' || this.type === 'smart_archive') && task.position_ids.length >= 1) {
                    data = Object.assign(data, {
                        position_id: JSON.stringify(task.position_ids)
                    });
                }
                const url = this.type === 'archive' ? '/archive/start' : '/smart_archive/start';
                window.myAjax('POST', url, data, () => {});
            })
            this.running.forEach( task => {
                const url = this.type === 'archive' ? '/archive/stop' : '/smart_archive/stop';
                if (task.ui_status === 'deleted') {
                    window.myAjax('POST', url, {
                        record_id: task.id,
                        camera_id: this.camera.id
                    }, () => {});
                }
            })
        },
        
        createSchedule(task) {
            const step = task.step;
            let result = '';
            //минуты
            if (this.type === 'archive') {
                result += '* ';
            } else {
                if (step.seconds) {
                    result += '* ';
                } else {
                    if (step.minutes) {
                        result += '*' + step.value + ' ';
                    } else {
                        result += '0 ';
                    }
                }
            }
            //часы
            const hours = [];
            const startTime = parseInt(task.startTime.slice(0, 2));
            const startEnd = parseInt(task.endTime.slice(0, 2));
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            for (let i = startTime; i < startEnd + 1; i++) {
                if (i + diff < 0) {
                    hours.push(i + diff + 24);
                } else if (i + diff > 23) {
                    hours.push(i + diff - 24);
                } else {
                    hours.push(i + diff);
                }
            }
            result += hours.join(',');
            if (step.hours) {
                result += step.value + ' ';
            } else {
                result += ' ';
            }
            //дни месяца, месяцы
            result += '* * ';
            //дни недели
            const days = [];
            for (const [key, value] of Object.entries(task.days)) {
                if (value) {
                    days.push(CRON_DAYS[key]);
                }
            }
            if (days.length > 0) {
                result += days.filter( d => (!!d || d === 0 || d === '0') ).sort().join(',');
            } else {
                result += '* ';
            }
            //секунды
            if (this.type === 'smart_archive' && step.seconds) {
                result += ' *' + step.value;
            }
            return result;
        },
        convertDateToUTC(date) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        },
        getFormat(date) {
            return this.getFormatValue(date.getFullYear()) + "-"
                + this.getFormatValue((date.getMonth()+1))  + "-"
                + this.getFormatValue(date.getDate()) + " "
                + this.getFormatValue(date.getHours()) + ":"
                + this.getFormatValue(date.getMinutes()) + ":"
                + this.getFormatValue(date.getSeconds());
        },
        getFormatValue(timeValue) {
            const str = timeValue.toString();
            return str.length === 1 ? '0' + str : str;
        },

       

        postAllShedule() {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите применить расписание ко всем камерам?', () =>{
                const days = {}
                let alldays = true;
                for (const [key, value] of Object.entries(this.selected.days)) {
                    if (value === true) {
                        console.log(key)
                        alldays = false;
                        break;
                    }
                }
                for (const [key, value] of Object.entries(this.selected.days)) {
                    if (value || alldays) {
                        days[key] = true;
                    }
                }
                const smart = Object.assign({}, this.selected, {
                    ui_days: days,
                    days: this.selected.days,
                });
                const schedule = this.createSchedule(smart);

                // if (this.camCard) {
                //     this.camCard.smart_archive_schedule = schedule;
                // }

                window.myAjax('POST', '/smart_archive/schedule', {
                    schedule: schedule
                }, this.postAllSheduleCallback);


            })
        },

        postAllSheduleCallback(error) {
            if(error) {
                window.myAlert('Произошла ошибка. Попробуйте позже.');
            } else {
                // this.getAllCams();
                window.myAlert('Расписание применено ко всем камерам.');
            }
        },
    }
}
