
import CheckboxNew from './../../base/checkboxNew';

export default {
    name: 'DigressionsSchedule',
    components: {
        CheckboxNew
        //todo
    },
    props: {
        objects: {
            type: Array,
            default: []
        },
        digressions: {
            type: Array,
            default: []
        },
        cameras: {
            type: Array,
            default: []
        },
        groupByFormat: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'digressions'
        }
    },
    data: () => ({
        contactsLoadings: false,
        contacts: [],
        selectedContacts: [],
        selectedEmails: [],

        subject: null,

        emails: {},

        selected: {
            startDate: null,
            endDate: null,
            time: '10:00',
            type: 'yesterday'
        },
        days: [],

        selectEmailsTemplates: false,
        emailTemplates: [],
        selectedEmailTemplates: [],
    }),
    mounted() {
        this.getContacts();
        this.getTemplates();
        const now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        this.selected.startDate = now.toISOString().slice(0,10);
        now.setDate(now.getDate() + 30);
        this.selected.endDate = now.toISOString().slice(0,10);
    },
    methods: {
        getTemplates() {
            window.myAjax('GET', '/api/email-templates', {}, (error, response) => {
                if (!error) {
                    this.emailTemplates = response;
                }
            });
        },
        getContacts() {
            this.contactsLoadings = true;
            window.myAjax('GET', '/api/contacts?page=0&limit=1000', {}, (error, response) => {
                if (!error) {
                    this.contacts = (response.contacts || []);
                    this.contacts.forEach( contact => {
                        this.emails[contact.id] = [];
                    } );
                    this.getEmails();
                }
            });
        },
        getEmails() {
            window.myAjax('GET', '/api/emails', {}, (error, response) => {
                this.contactsLoadings = false;
                if (!error) {
                    const emails = (response.emails || []).filter( email => email.deleted_at === null);
                    emails.forEach( email => {
                        if (this.emails[email.contact_id]) {
                            this.emails[email.contact_id].push(email);
                        }
                    });
                }
            });
        },
        check(contactId, checked) {
            if (checked) {
                this.selectedEmails.push(contactId);
            } else {
                this.selectedEmails.splice(this.selectedEmails.indexOf(contactId), 1);
            }
        },
        startSaving() {
            const time = this.selected.time.split(':');
            let hour = time[0].startsWith('0') ? time[0].slice(-1) : time[0];
            const d = new Date();
            let diff = d.getTimezoneOffset() / 60;
            hour = (parseInt(hour) + diff + 24) % 24;
            const minute = time[1].startsWith('0') ? time[1].slice(-1) : time[1];

            if (this.selected.type === 'last_week') {
                this.days = [1];
            }
            let monthDays = '*';
            if (this.selected.type === 'last_month') {
                monthDays = '1';
                this.days = [0, 1, 2, 3, 4, 5, 6];
            }

            const data = {
                start_datetime: this.selected.startDate + ' 00:00:00',
                stop_datetime: this.selected.endDate + ' 23:59:59',
                schedule: `${minute} ${hour} ${monthDays} * ${ this.days.length ? this.days.join(',') : '*'}`,
                schedule_type: this.selected.type
            };

            if (this.selectEmailsTemplates) {
                data.email_template_ids = JSON.stringify(this.selectedEmailTemplates);
                data.emails_id = JSON.stringify([]);
            } else {
                data.email_template_ids = JSON.stringify([]);
                data.emails_id = JSON.stringify(this.selectedEmails);
            }
            data.objects = this.objects.length > 0 ? JSON.stringify(this.objects) : null;
            if (this.type === 'checklist' || this.type === 'digressions') {
                data.digressions = this.digressions.length > 0 ? JSON.stringify(this.digressions) : null;
            }

            if ((this.subject || '').length) {
                data.headline = this.subject;
            }
            if (this.groupByFormat) {
                data.group_by_format = true;
            }
            if (this.type === 'checklist') {
                data.checklist_ids = this.digressions.length > 0 ? JSON.stringify(this.digressions) : null;
                data.object_ids = this.objects.length > 0 ? JSON.stringify(this.objects) : null;
            }
            if (this.type === 'checklist') {
                window.myAjax('POST', '/analytic/checklist/create_schedule', data, (error, response) => {
                    if (error) {
                        window.myAlert(response.message);
                    } else {
                        window.myAlert('Расписание задано');
                        this.$emit('saved');
                    }
                });
            } else if (this.type === 'digressions') {
                window.myAjax('POST', '/analytic/create_schedule', data, (error, response) => {
                    if (error) {
                        window.myAlert(response.message);
                    } else {
                        window.myAlert('Расписание задано');
                        this.$emit('saved');
                    }
                });
            } else if (this.type === 'cameras') {
                window.myAjax('POST', '/analytic/camera_log/create_schedule', data, (error, response) => {
                    if (error) {
                        window.myAlert(response.message);
                    } else {
                        window.myAlert('Расписание задано');
                        this.$emit('saved');
                    }
                });
            }
        },
        setDay(dayKey) {
            if (dayKey === 'weekdays') {
                this.days = [];
                this.days.push(1, 2, 3, 4, 5);
            } else if (dayKey === 'all') {
                this.days = [];
                this.days.push(0, 1, 2, 3, 4, 5, 6);
            } else {
                const index = this.days.indexOf(dayKey);
                if (index < 0) {
                    this.days.push(dayKey);
                } else {
                    this.days.splice(index, 1);
                }
            }
        },
        checkEmailTemplate(emailTemplateId, checked) {
            if (checked) {
                this.selectedEmailTemplates.push(emailTemplateId);
            } else {
                this.selectedEmailTemplates.splice(this.selectedEmailTemplates.indexOf(emailTemplateId), 1);
            }
        },
    }
}