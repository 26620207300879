import {
    createContact,
    updateContact,
    deleteContact
} from './../utils';

import Checkbox from './../../base/checkbox';
import ModalNew from './../../base/modalNew';

import CheckboxList from './../../base/checkboxList';

// import rolesUtils from './../../roles/utils';

export default {
    name: 'ContactCard',
    components: {
        Checkbox,
        ModalNew,
        CheckboxList
    },
    props: ['contact'],
    data: () => ({
        contactCard: {},
        editMode: true,

        emails: [],

        showEmailes: false,
        defaultEmail: {},
        defaultEmailId: null,

        showPhones: false,
        defaultPhone: {},
        defaultPhoneId: null,

        errorMessage: null,
        validTelegramError: false,

        showDigressionsMenu: false,
        digressionsObjects: [],
        availablesDigressionsForObjects: {},

        showObjectDigressions: false,
        modalDigressions: [],
        modalObject: null,
        selectedDigressions: [],
        selectedAllDigressions: false,
        modalGroups: [],
        oldRelations: [],

        objectsCounts: {},

        showUsersModal: false,

        contacts: [],
        contactsFilter: '',
        contactsPage: 0,
        contactsTotal: 0,
        contactsLimit: 10,

        copySaving: false,

        currentContactWatchers: [],
        copyContactWatchers: [],

        selectedContact: null,

        useDigressionsFirst: false,

        showDigressionsModal: false,
        selectedCommonDigressions: [],
        selectedCommonObjects: [],

        showDigressionsModalMode: 'digressions', 
    }),
    mounted() {
        this.contactCard = Object.assign({}, this.contact);
        this.emails = [...new Set(this.contactCard.emails)];
        if (this.contactCard.id) {
            this.getContact();
        } else {
            if (this.emails[0] !== null) {
                for (let i = 0; i < this.emails.length; i++) {
                    const email = this.emails[i];
                    if (!email) {
                        this.emails[i] = {};
                        delete this.emails[i];
                    } else if (email.is_default) {
                        this.defaultEmail = email;
                        this.defaultEmailId = email.id;
                    }
                }
            } else {
                this.emails = []
            }
            if (this.contactCard.phones[0] !== null) {
                for (let i = 0; i < this.contactCard.phones.length; i++) {
                    const phone = this.contactCard.phones[i];
                    if (!phone) {
                        this.contactCard.phones[i] = {};
                        delete this.contactCard.phones[i];
                    } else if (phone.is_default) {
                        this.defaultPhone = phone;
                        this.defaultPhoneId = phone.id;
                    }
                }
            } else {
                this.contactCard.phones = []
            }
        }
        // this.modalGroups = (window.myDevs || []);
        this.modalGroups = (window.myDevs || []).filter( digression => !digression.priority_id );
        const userId = window.getCookie('userId');
        const permissions = ((window.myUsers || []).find( (user) => user.id == userId) || {}).permissions;
        this.editMode = (permissions || []).findIndex( (permission) => permission == 1004) > -1;

        this.digressionsObjects = (window.myObjects || []).filter( obj => obj.object_type === 'object');
        this.availablesDigressionsForObjects = {};

        window.myAjax('GET', '/api/objects-digressions', {}, (error, response) => {
            if(!error) {
                (response.object_digressions || []).forEach( relation => {
                    const objectId = relation.object_id;
                    if (!this.availablesDigressionsForObjects[objectId]) {
                        this.availablesDigressionsForObjects[objectId] = [];
                    }
                    if (this.availablesDigressionsForObjects[objectId].findIndex(dev => dev.id == relation.digression_id) === -1) {
                        this.availablesDigressionsForObjects[objectId].push((window.myDevs || []).find( dev => dev.id == relation.digression_id) );
                    }
                });
            }
        });
        this.updateObjectCounts();
    },
    methods: {
        updateSelectedDigressions(data) {
            this.selectedCommonDigressions = data;
        },
        updateSelectedObjects(data) {
            this.selectedCommonObjects = data;
        },
        openUserModal() {
            this.showUsersModal = true;
            this.contactsFilterHandler();
        },
        openRemoveConfirm() {
            window.myConfirm('Внимание!', 'Очистить все уведомления?', () => {
                window.myAjax('GET', '/api/digression-watchers', {
                    contact_id: this.contactCard.id
                }, (error, response) => {
                    if (error) {
                        window.myAlert(response.message || 'Ошибка в запросе.');
                        return;
                    } else {
                        if (!(response || []).length) {
                            window.myAlert('Уведомления очищены.');
                            return;
                        }
                        window.myAjax('DELETE', '/api/digression-watchers', {
                            watchers: JSON.stringify((response || []))
                        }, (error, response) => {
                            if (error) {
                                window.myAlert(response.message || 'Ошибка в запросе.');
                                return;
                            } else {
                                this.updateObjectCounts();
                                window.myAlert('Уведомления очищены.');
                            }
                        });
                    }
                });
            });
        },
        openDigressionsModal() {
            this.showDigressionsModal = true;
            this.selectedCommonDigressions = [];
            window.myDevs.forEach( dev => {
                this.modalDigressions.push(dev);
            });
        },
        closeDigressionsModal() {
            this.showDigressionsModal = false;
            this.modalDigressions = [];
            this.selectedCommonDigressions = [];
            this.showDigressionsModalMode = 'digressions';
        },
        saveDigressionsModal() {
            this.showDigressionsModalMode = 'saving';
            const toAdd = [];
            for (let i = 0; i < this.selectedCommonDigressions.length; i++) {
                const digressionId = this.selectedCommonDigressions[i];
                for (let j = 0; j < this.selectedCommonObjects.length; j++) {
                    const objectId = this.selectedCommonObjects[j];
                    toAdd.push({
                        contact_id: this.contactCard.id,
                        object_id: objectId,
                        digression_id: digressionId,
                    });
                }
            }
            window.myAjax('GET', '/api/digression-watchers', {
                contact_id: this.contactCard.id
            }, (error, response) => {
                if (error) {
                    window.myAlert(response.message || 'Ошибка в запросе.');
                    return;
                } else {
                    if (!(response || []).length) {
                        this.saveCommonRelations(toAdd);
                    } else {
                        window.myAjax('DELETE', '/api/digression-watchers', {
                            watchers: JSON.stringify((response || []))
                        }, (error, response) => {
                            if (error) {
                                window.myAlert(response.message || 'Ошибка в запросе.');
                                return;
                            } else {
                                this.saveCommonRelations(toAdd);
                            }
                        });
                    }
                }
            });
        },
        saveCommonRelations(relations) {
            window.myAjax('POST', '/api/digression-watchers', {
                watchers: JSON.stringify(relations)
            }, (error, response) => {
                if (error) {
                    window.myAlert(response.message || 'Ошибка в запросе.');
                } else {
                    window.myAlert('Сохранено.');
                    this.closeDigressionsModal();
                }
            });
        },
        getContact() {
            window.myAjax(
                'GET', '/api/contact', {
                    contact_id: this.contact.id
                }, this.getContactCallback
            )
        },
        contactsFilterHandler() {
            this.contacts = [];
            this.contactsPage = 0;
            this.getContacts();
        },
        getContacts() {
            window.myAjax('GET', '/api/contacts', {
                search: this.contactsFilter,
                page: this.contactsPage,
                limit: this.contactsLimit,
            }, (error, response) => {
                if (error) {
                    window.myAlert(response.message || 'Ошибка в запросе.');
                } else {
                    this.contacts = this.contacts.concat(
                        (response.contacts || []).filter(c => c.id != this.contactCard.id)
                    );
                    this.contactsTotal = response.contacts[0] ? response.contacts[0].total : this.contactsTotal;
                }
            });
        },
        getNextContactsPage() {
            this.contactsPage += 1;
            this.getContacts();
        },
        contactSelected(copyContactId) {
            this.selectedContact = this.contacts.find( c => c.id === copyContactId);
        },
        copyContact() {
            console.log(this.selectedContact);

            this.copySaving = true;
            this.getCopyContactWatchers();
        },
        getCopyContactWatchers() {
            window.myAjax('GET', '/api/digression-watchers', {
                contact_id: this.selectedContact.id
            }, (error, response) => {
                if (error) {
                    window.myAlert(response.message || 'Ошибка в запросе.');
                } else {
                    if (!(response || []).length) {
                        window.myAlert('У выбранного пользователя нет настроенных уведомлений');
                        this.selectedContact = null;
                        this.copySaving = false;
                        return;
                    }
                    this.copyContactWatchers = (response || []);
                    this.getCurrentContactWatchers();
                }
            });
        },
        getCurrentContactWatchers() {
            window.myAjax('GET', '/api/digression-watchers', {
                contact_id: this.contactCard.id
            }, (error, response) => {
                if (error) {
                    window.myAlert(response.message || 'Ошибка в запросе.');
                    this.selectedContact = null;
                    this.copySaving = false;
                    return;
                } else {
                    this.currentContactWatchers = (response || []);
                    if (this.currentContactWatchers.length) {
                        this.removeCurrentContactWatchers();
                    } else {
                        this.saveCopyContactWatchers();
                    }
                }
            });
        },

        removeCurrentContactWatchers() {
            window.myAjax('DELETE', '/api/digression-watchers', {
                watchers: JSON.stringify(this.currentContactWatchers)
            }, (error, response) => {
                if (error) {
                    window.myAlert(response.message || 'Ошибка в запросе.');
                    this.selectedContact = null;
                    this.copySaving = false;
                    return;
                } else {
                    this.saveCopyContactWatchers();
                }
            });
        },
        saveCopyContactWatchers() {
            const toSave = [];
            (this.copyContactWatchers || []).forEach( relation => {
                toSave.push({
                    contact_id: this.contactCard.id,
                    object_id: relation.object_id,
                    digression_id: relation.digression_id,
                })
            });
            window.myAjax('POST', '/api/digression-watchers', {
                watchers: JSON.stringify(toSave)
            }, (error, response) => {
                if (error) {
                    window.myAlert(response.message || 'Ошибка в запросе.');
                    this.selectedContact = null;
                    this.copySaving = false;
                    return;
                } else {
                    window.myAlert('Сохранено');

                    this.cancelObjectDigressions();
                    this.resetUserModal();
                }
            });
        },
        resetUserModal() {
            this.contacts = [];
            this.contactsFilter = '';
            this.contactsPage = 0;
            this.contactsTotal = 0;
            this.copySaving = false;
            this.currentContactWatchers = [];
            this.copyContactWatchers = [];
            this.selectedContact = null;
            this.showUsersModal = false;
        },
        getContactCallback(error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                this.contactCard = Object.assign({}, {}, response.contact);
                this.emails = [...new Set(this.contactCard.emails)];
                if (this.emails[0] !== null) {
                    for (let i = 0; i < this.emails.length; i++) {
                        const email = this.emails[i];
                        if (!email) {
                            this.emails[i] = {};
                            delete this.emails[i];
                        } else if (email.is_default) {
                            this.defaultEmail = email;
                            this.defaultEmailId = email.id;
                        }
                    }
                } else {
                    this.emails = []
                }
                if (this.contactCard.phones[0] !== null) {
                    for (let i = 0; i < this.contactCard.phones.length; i++) {
                        const phone = this.contactCard.phones[i];
                        if (!phone) {
                            this.contactCard.phones[i] = {};
                            delete this.contactCard.phones[i];
                        } else if (phone.is_default) {
                            this.defaultPhone = phone;
                            this.defaultPhoneId = phone.id;
                        }
                    }
                } else {
                    this.contactCard.phones = []
                }
            }
        },
        // работа с емейлами
        toggleShowEmailes() {
            this.showEmailes = !this.showEmailes;
        },
        addNewEmail() {
            if (!this.editMode) {
                return;
            }
            this.emails.push({
                id: 'new-e-' + (this.emails.length ? (this.emails[this.emails.length - 1].id + 1) : 1),
                email: '',
                is_default: 0,
                notify: false,
            })
            if (this.emails.length === 1) {
                this.setDefaultEmail(this.emails[0]);
            }
            setTimeout( () => {
                window.$(`#new-e-${(this.emails.length ? (this.emails[this.emails.length - 1].id + 1) : 1)}`).focus()
            }, 50)
        },
        setDefaultEmail(email) {
            if (!this.editMode) {
                return;
            }
            for (let i = 0; i < this.emails.length; i++) {
                this.emails[i].is_default = 0;
            }
            const index = this.emails.findIndex(e => e.id == email.id);
            this.emails[index].is_default = 1;
            this.defaultEmail = this.emails[index];
            this.defaultEmailId = email.id;
        },
        toggleEmailNotify(email) {
            if (!this.editMode) {
                return;
            }
            const index = this.emails.findIndex(e => e.id == email.id);
            const curState = this.emails[index].notify;
            for (let i = 0; i < this.emails.length; i++) {
                this.emails[i].notify = false;
            }
            this.emails[index].notify = !curState;
        },
        toggleTelegramPhone(phone) {
            if (!this.editMode) {
                return;
            }
            const index = this.contactCard.phones.findIndex(p => p.id == phone.id);
            const curState = this.contactCard.phones[index].notify;
            for (let i = 0; i < this.contactCard.phones.length; i++) {
                this.contactCard.phones[i].notify = false;
            }
            this.contactCard.phones[index].notify = !curState;
        },
        removeEmail(email) {
            if (!this.editMode) {
                return;
            }
            const index = this.emails.findIndex(e => e.id == email.id);
            this.emails.splice(index, 1);
        },

        // работа с телефонами
        toggleShowPhones() {
            this.showPhones = !this.showPhones;
        },
        addNewPhone() {
            if (!this.editMode) {
                return;
            }
            this.contactCard.phones.push({
                id: 'new-' + (this.contactCard.phones.length ? (this.contactCard.phones[this.contactCard.phones.length - 1].id + 1) : 1),
                phone: '',
                is_default: 0,
                notify: false,
                notify_channel: null,
            })
            if (this.contactCard.phones.length === 1) {
                this.setDefaultPhone(this.contactCard.phones[0]);
            }
            setTimeout( () => {
                window.$(`#new-${(this.contactCard.phones.length ? (this.contactCard.phones[this.contactCard.phones.length - 1].id + 1) : 1)}`).focus()
            }, 50)
        },
        setDefaultPhone(phone) {
            if (!this.editMode) {
                return;
            }
            for (let i = 0; i < this.contactCard.phones.length; i++) {
                this.contactCard.phones[i].is_default = 0;
            }
            const index = this.contactCard.phones.findIndex(e => e.id == phone.id);
            this.contactCard.phones[index].is_default = 1;
            this.defaultPhone = this.contactCard.phones[index];
            this.defaultPhoneId = phone.id;
        },
        removePhone(phone) {
            if (!this.editMode) {
                return;
            }
            const index = this.contactCard.phones.findIndex(e => e.id == phone.id);
            this.contactCard.phones.splice(index, 1);
        },

        cancel() {
            this.$emit('cancel');
        },
        remove() {
            window.myConfirm('Внимание!', 'Вы уверены, что хотите удалить контакт?', () => {
                deleteContact({
                    id: this.contactCard.id
                }, this.cancel);
            });
        },
        phoneBlur(phone) {
            if (!phone.notify) {
                return;
            } else {
                this.validateTelegramPhone(phone);
            }
        },
        validateTelegramPhone(validatedPhone) {
            let telegramPhone;
            if (validatedPhone) {
                telegramPhone = validatedPhone;
            } else {
                for (let i = 0; i < this.contactCard.phones.length; i++) {
                    const phone = this.contactCard.phones[i];
                    if (phone.notify) {
                        telegramPhone = phone;
                    }
                }
            }
            if (!telegramPhone) {
                this.validTelegramError = false;
                return true;
            }
            if (
                telegramPhone.phone.startsWith('+7') &&
                telegramPhone.phone.length === 12
            ) {
                this.validTelegramError = false;
                return true;
            } else {
                this.validTelegramError = telegramPhone.id;
                return false;
            }
        },
        save() {
            if (!this.validateFields()) {
                window.$('#dsv-app').animate({
                    scrollTop: 0
                }, 100);
                return;
            }
            if (!this.validateTelegramPhone()) {
                return;
            }
            const data = Object.assign({}, this.contactCard, {});
            for (let i = 0; i < data.emails.length; i++) {
                const email = data.emails[i];
                if ((email.id + '').indexOf('new') >= 0) {
                    delete email['id'];
                }
            }
            for (let i = 0; i < data.phones.length; i++) {
                const phone = data.phones[i];
                if ((phone.id+ '').indexOf('new') >= 0) {
                    delete phone['id'];
                }
            }
            data.phones = JSON.stringify(data.phones);
            data.emails = JSON.stringify(data.emails);
            if (this.contact.id) {
                updateContact({data: data}, this.cancel);
            } else {
                createContact({data: data}, this.cancel);
            }
        },

        openObjectDigressions(object) {
            this.modalDigressions = [];
            (this.availablesDigressionsForObjects[object.id] || []).forEach( item => this.modalDigressions.push(item) );
            if (this.modalDigressions.length === 0) {
                window.myAlert('К объекту не привязаны отклонения.');
                return;
            }
            window.myDevs.forEach( dev => {
                if (!dev.priority_id) {
                    this.modalDigressions.push(dev);
                }
            });
            this.modalObject = Object.assign({}, object);
            this.selectedDigressions = [];
            this.oldRelations = [];

            window.myAjax('GET', '/api/digression-watchers', {
                contact_id: this.contactCard.id,
                object_id: this.modalObject.id
            }, (error, response) => {
                if (!error) {
                    this.oldRelations = response;
                    this.oldRelations.forEach( relation => {
                        this.selectedDigressions.push(relation.digression_id);
                    })
                    this.showObjectDigressions = true;

                    // if ((this.availablesDigressionsForObjects[this.modalObject.id] || []).length === this.selectedDigressions.length) {
                    //     this.selectedAllDigressions = true;
                    // }
                }
            });
        },

        cancelObjectDigressions() {
            this.showObjectDigressions = false;
            this.modalObject = null;
            this.modalDigressions = [];
            this.selectedDigressions = [];
            this.selectedAllDigressions = false;
            // this.modalGroups = [];
            this.oldRelations = [];
            this.updateObjectCounts();
            
        },
        updateObjectCounts() {
            window.myAjax('GET', '/api/digression-watchers', {
                contact_id: this.contactCard.id
            }, (error, response) => {
                if (!error) {
                    this.objectsCounts = {};
                    (response || []).forEach( relation => {
                        const objectId = relation.object_id;
                        if (!this.objectsCounts[objectId]) {
                            this.objectsCounts[objectId] = 1;
                        } else {
                            this.objectsCounts[objectId] = this.objectsCounts[objectId] + 1;
                        }
                    });
                }
            });
        },

        saveObjectDigressions() {
            const toAdd = [];
            const toDelete = [];

            this.oldRelations.forEach( relation => {
                if (this.selectedDigressions.findIndex( id => id === relation.digression_id) < 0) {
                    toDelete.push({
                        contact_id: this.contactCard.id,
                        object_id: this.modalObject.id,
                        digression_id: relation.digression_id,
                    })
                }
            })

            this.selectedDigressions.forEach( id => {
                if (this.oldRelations.findIndex( relation => id === relation.digression_id) < 0) {
                    if (toAdd.findIndex(rel =>
                            rel.contact_id == this.contactCard.id &&
                            rel.object_id == this.modalObject.id &&
                            rel.digression_id == id
                        ) === -1
                    ) {
                        toAdd.push({
                            contact_id: this.contactCard.id,
                            object_id: this.modalObject.id,
                            digression_id: id,
                        })
                    }
                }
            })

            if (toAdd.length) {
                window.myAjax('POST', '/api/digression-watchers', { watchers: JSON.stringify(toAdd)}, () => {
                    if (toDelete.length) {
                        window.myAjax('DELETE', '/api/digression-watchers', { watchers: JSON.stringify(toDelete)}, () => {
                            this.cancelObjectDigressions();
                        });
                    } else {
                        this.cancelObjectDigressions();
                    }
                });
            } if (toDelete.length) {
                window.myAjax('DELETE', '/api/digression-watchers', { watchers: JSON.stringify(toDelete)}, () => {
                    this.cancelObjectDigressions();
                });
            } else {
                this.cancelObjectDigressions();
            }
        },

        toggleSelectedAll() {
            // this.selectedAllDigressions = !this.selectedAllDigressions;
            // this.selectedDigressions = [];
            // if (this.selectedAllDigressions) {
            //     if (this.availablesDigressionsForObjects[this.modalObject.id]) {
            //         this.availablesDigressionsForObjects[this.modalObject.id].forEach( digression => {
            //             this.selectedDigressions.push(digression.id);
            //         });
            //     }
            // } else {
            //     this.selectedDigressions = [];
            // }
        },

        updateSelected(data) {
            this.selectedDigressions = data;

            // this.selectedAllDigressions = false;
            // if ((this.availablesDigressionsForObjects[this.modalObject.id] || []).length === this.selectedDigressions.length) {
            //     this.selectedAllDigressions = true;
            // }
        },

        validateFields() {
            const errors = [];
            if (!this.contactCard.last_name) {
                errors.push('фамилию')
            }
            if (!this.contactCard.first_name) {
                errors.push('имя')
            }
            if (!this.contactCard.position) {
                errors.push('должность')
            }
            if (!this.contactCard.company_name) {
                errors.push('название компании')
            }
            for (let i = 0; i < this.emails.length; i++) {
                if (!window.isEmail(this.emails[i].email)) {
                    errors.push('корректные email`ы')
                    break
                }
            }
            if (errors.length) {
                this.errorMessage = 'Необходимо указать: ' + errors.join(', ');
                return false;
            }
            return true;
        }
    }
}
