<style lang="scss" src="./_contacts/contacts.scss" scoped></style>

<template>
    <div>
        <div class="dsv-flexbox mobile-hide" style="margin: 12px 16px 24px;font-weight: 400;font-size: 32px;line-height: 150%;color: rgb(23, 30, 30);align-items: center;">
            <div class="mobile-hide">Контакты</div>
            <input class="dsv-userFilter__input ml-4"
                v-model="search_str"
                placeholder="Поиск по имени">
            <div v-if="!showContact && editMode" class='controls add_node' title='создать новый контакт' @click="showContactCard(null)"></div>
        </div>
        <ModalNew v-if="showContact" @close="close(false)" :width="632">
            <div slot="body" class="">
                <ContactCard v-if="showContact"
                    :contact="contact"
                    @cancel="getContacts"/>
            </div>
        </ModalNew>
        <div class="px-4">
            <div class="devCard mb-8 mx-0 mx-lg-0 px-0 px-lg-4 py-1 py-lg-4">
                <div class="row dsv-contacts__header">
                    <div class="list-row-fio col-3">{{ $t('ФИО') }}</div>
                    <div class="list-row-pos col-3">{{ $t('Должность') }}</div>
                    <div class="list-row-phone col-3">{{ $t('Телефон') }}</div>
                    <div class="list-row-email col">{{ $t('Почта') }}</div>
                </div>
                <div class="izk_list">
                    <div v-if="!contacts.length">
                        <div class="dsv-list__empty-message">
                            {{ $t('Сотрудники не найдены') }}.
                        </div>
                    </div>
                    <div v-for="contact in contacts"
                         v-bind:key="contact.id">
                        <div v-if="!contact.is_ai"
                            class="cnt-list-row row the_link border-top py-3 list-row"
                            @click="showContactCard(contact)">
                            <div class="list-row-fio col-lg-3 my-auto dsv-contacts__item-name">{{ contact.contact_fio }}</div>
                            <div class="list-row-pos col-lg-3 my-auto">{{ contact.position }}</div>
                            <div class="list-row-phone col-lg-3 my-auto">
                                <a :href="[ 'tel:' + contact.phone ]">{{ contact.phone }}</a>
                            </div>
                            <div class="list-row-email col my-auto">
                                <a :href="[ 'mailto:' + contact.email ]">{{ contact.email }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Pagination :pages="pages"
                        :request_page="page+1"
                        @getPage="getUsers"/>
        </div>
    </div>
</template>

<script>
    import Pagination from '../../components/Pagination';
    
    import ModalNew from './../base/modalNew';
    import ContactCard from './Card';

    export default {
        name: 'Contacts',
        components: {
            ContactCard,
            Pagination,
            ModalNew
        },
        props: [],
    data: () => ({
        isMobile: false,
        total: null,
        pages: [],
        all_pages: null,
        limit: 15,
        search_str: '',
        request: {
            page: 0,
            limit: 25,
            user_id: 0,
            start_date: null,
            end_date: null,
            order_by: 'created_at', //(‘user_id’ | ‘created_at’),
            order: 'desc' // (‘asc’ | ‘desc’)
        },

        showContact: false,
        contacts: [],
        contact: null,
        editMode: true,
    }),
    watch: {
        search_str: {
            deep: true,
            handler() {
                this.getUsers(this.page);
            }
        },
    },
    mounted() {
        window.resizeScrollingContent();
        this.getUsers(0)
        if (window.$('#dsv-app').hasClass('dsv-mobile')) {
            this.isMobile = true;
        }
        const userId = window.getCookie('userId');
        const permissions = ((window.myUsers || []).find( (user) => user.id == userId) || {}).permissions;
        this.editMode = (permissions || []).findIndex( (permission) => permission == 1004) > -1;
    },
    methods: {
        fio(contact) {
            let res = contact.profile.last_name + ' ' +
                contact.profile.first_name
            if(contact.profile.middle_name)
                res += ' ' + contact.profile.middle_name
            return res
        },
        getContacts() {
            this.getUsers(this.page);
            this.showContact = false;
            window.$("#modalContact").modal('hide');
        },
        close() {
            this.showContact = false;
            window.$("#modalContact").modal('hide');
        },
        getUsers(page) {
            this.page = page;
            let url = '/api/contacts?page='+this.page+'&limit='+this.limit;
            if ((this.search_str || '').length) {
                url += '&search='+this.search_str
            }
            window.myAjax('GET', url, {}, this.myCallbackGetStartU)
        },
        myCallbackGetStartU (error, response) {
            if(error) {
                window.myAlert(response.message)
            } else {
                this.contacts = response.contacts
                // console.log(this.contacts)
                let all_pages = 2 + parseInt(response.contacts[0].total / this.limit)
                if(response.contacts[0].total % this.limit==0)
                    all_pages--
                this.pages = []
                for(let i=1; i<all_pages; i++)
                    this.pages.push(i)
                window.$('.scrolling_content').animate({
                    scrollTop: 0
                }, 1000);
            }
        },
        showContactCard(contact) {
            this.contact = contact;
            if (this.isMobile) {
                this.showContact = false;
                return;
            }
            if (contact == null) {
                this.contact = {
                    first_name: '',
                    middle_name: null,
                    last_name: '',
                    emails: [
                        {
                            email: '',
                            is_default: 1
                        }
                    ],
                    phones: [
                        {
                            phone: '',
                            is_default: 1
                        }
                    ],
                    position: null,
                    observer_id: parseInt(window.getCookie('userId')),
                    comments: null,
                    company_name: null,
                    object_name: null,
                    object_address: null,
                    digressions: []
                }
                this.showContact = true;
            }
            this.showContact = true;
            window.$("#modalContact").modal('show');
        }
    }
  }
</script>
