import CheckboxListDropdown from './../../base/checkboxListDropdown'; 

const MODULE_NAMES = {
    'smoker_detection': 'Курильщики',
    'queue_detection': 'Наличие очереди',
    'absence_and_queue': 'Очередь и отсутствие продавца',
    'uniform_detection': 'Контроль шапок',
    'gloves_detection': 'Контроль перчаток',
    'cleaning_detection': 'Уборка помещений',
    'human_count': 'Подсчёт посетителей',
    'absence_in_zone': 'Отсутствие продавца/кассира',
    'license_plate_detection': 'Распознование автономеров',
    'first_in_detection': 'Контроль времени открытия',
    'last_out_detection': 'Контроль времени закрытия',
    'bac_treatment': 'Бакобработка',
    'equipment_detection': 'Распознавание инвентаря',
}

export default {
    name: 'NeuralResults',
    components: {
        CheckboxListDropdown,
    },
    props: {
        //todo
    },
    data: () => ({
        results: [],
        request: {
            page: 0,
            limit: 100,
            task_types: [],
            object_ids: [],
            camera_ids: [],
            start_date: null,
            end_date: null,
        },

        objects: [],
        cameras: [],
        filteredCameras: [],
        types: [],

        filterLoading: false,
        loading: false,
        //todo
    }),
    watch: {
        request: {
            deep: true,
            handler() {
                this.getResults();
            }
        }
    },
    mounted() {
        this.resetRequest();
        this.getObjects();
        this.types = Object.keys(MODULE_NAMES).map(key => ({id: key, name: MODULE_NAMES[key]}));
    },
    beforeDestroy() {
        //todo
    },
    methods: {
        resetRequest() {
            this.request = {
                page: 0,
                limit: 100,
                task_types: [],
                object_ids: [],
                camera_ids: [],
                start_date: null,
                end_date: null,
            }
        },
        getObjects() {
            this.filterLoading = true;
            window.myAjax('GET', '/api/objects', {}, (error, response) => {
                if (!error) {
                    this.objects = (response.objects || []).filter(object => object.object_type === 'object');
                    this.getCameras();
                }
            });
        },
        getCameras() {
            window.myAjax('GET', '/api/client-cameras', {}, (error, response) => {
                if (!error) {
                    this.filterLoading = false;
                    this.cameras = (response.client_cameras || []);
                    this.getResults();
                }
            });
        },
        getResults() {
            this.loading = true;
            window.myAjax('GET', '/ml/module-results', {
                page: this.request.page,
                limit: this.request.limit,
                task_types: JSON.stringify(this.request.task_types),
                object_ids: JSON.stringify(this.request.object_ids),
                camera_ids: JSON.stringify(this.request.camera_ids),
                start_date: this.request.start_date,
                end_date: this.request.end_date,
            }, (error, response) => {
                if(error) {
                    window.myAlert(response.message || 'Ошибка получения результатов. Попробуйте позже.');
                } else {
                    this.results = this.parseResults(response || []);
                }
                this.loading = false;
            })
        },
        selectedObjectsHandler(data) {
            this.request.object_ids = data;
            if (this.request.object_ids.length) {
                this.filteredCameras = this.cameras.filter(cam => this.request.object_ids.includes(cam.object_id));
            } else {
                this.filteredCameras = [];
            }
        },

        parseResults(results) {
            const items = [];
            results.forEach( result => {
                const item = Object.assign({}, result);
                item.date = this.getDate(result.created_at);
                item.time = this.getTime(result.created_at);
                item.objectName = (this.objects.find( o => o.id === result.object_id ) || {}).name || '-';
                item.cameraName = (this.cameras.find( c => c.id === result.camera_id ) || {}).name || '-';
                item.title = this.getTitle(result);
                item.value = this.getValue(result);
                items.push(item);
            });
            return items;
        },
        getDate(datetime) {
            let date = this.getZoneDate(datetime);
            return date.toISOString().slice(0, 10).replace(/-/g,'.');
        },
        getTime(datetime) {
            let date = this.getZoneDate(datetime);
            return date.toLocaleTimeString();
        },
        getZoneDate(dateStr) {
            let date = new Date(dateStr);
            let diff = date.getTimezoneOffset() / -60;
            date.setHours(date.getHours() + diff);
            return date;
        },
        getTitle(result) {
            let name = 'Неизвестно';
            if (result.task_type === 'human_count') {
                name = (result.data || {}).unique ? 'Подсчёт уникальных посетителей' : MODULE_NAMES[result.task_type];
            } else {
                name = MODULE_NAMES[result.task_type] || 'Неизвестный модуль';
            }
            return name;
        },
        getValue(result) {
            let str = '-';
            switch (result.task_type) {
                case 'human_count':
                    str = `${((result.data || {}).detected_count || 0)} человек`;
                    break;
                default:
                    break;
            }
            return str;
        },
    }
}
